var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.parentType !== "form"
    ? _c(
        "div",
        { staticClass: "native-field-warp", style: _vm.styles },
        [
          _c("div", { staticClass: "field-label" }, [
            _vm._v(_vm._s(_vm.data.field.name)),
          ]),
          _vm._v(" "),
          _c("el-time-picker", {
            class: _vm.fieldProps.className,
            attrs: {
              readonly: _vm.fieldProps.readonly,
              editable: _vm.fieldProps.editable,
              clearable: _vm.fieldProps.clearable,
              placeholder: _vm.fieldProps.placeholder,
            },
            model: {
              value: _vm.data.field.value,
              callback: function ($$v) {
                _vm.$set(_vm.data.field, "value", $$v)
              },
              expression: "data.field.value",
            },
          }),
        ],
        1
      )
    : _c(
        "el-form-item",
        { style: _vm.styles, attrs: { label: _vm.data.field.name } },
        [
          _c("el-time-picker", {
            class: _vm.fieldProps.className,
            attrs: {
              readonly: _vm.fieldProps.readonly,
              editable: _vm.fieldProps.editable,
              clearable: _vm.fieldProps.clearable,
              placeholder: _vm.fieldProps.placeholder,
            },
            model: {
              value: _vm.data.field.value,
              callback: function ($$v) {
                _vm.$set(_vm.data.field, "value", $$v)
              },
              expression: "data.field.value",
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }