//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {};
  }
};