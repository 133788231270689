//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      value: []
    };
  },
  computed: {
    fieldProps: function fieldProps() {
      return this.data.field.props;
    }
  },
  methods: {
    setValue: function setValue(value) {
      this.value = value;
      this.onChange();
    },
    onChange: function onChange() {
      this.$emit('input', {
        key: this.data.field.paramsKey,
        value: this.value
      });
    }
  }
};