var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yl-choose-warp" },
    [
      _vm.moreVisible
        ? [
            _vm._l(_vm.headOptions, function (item) {
              return _c(
                "div",
                {
                  key: item.value,
                  staticClass: "choose-item",
                  class: _vm.multiple
                    ? _vm.current.includes(item.value)
                      ? "current " + _vm.getClass
                      : _vm.getClass
                    : _vm.current === item.value
                    ? "current " + _vm.getClass
                    : "" + _vm.getClass,
                  on: {
                    click: function ($event) {
                      return _vm.handleClick(item.value)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "span-name" }, [
                    _vm._v(_vm._s(item.label)),
                  ]),
                ]
              )
            }),
            _vm._v(" "),
            _vm.overVisible
              ? _vm._l(_vm.overOptions, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.value,
                      staticClass: "choose-item",
                      class: _vm.multiple
                        ? _vm.current.includes(item.value)
                          ? "current " + _vm.getClass
                          : _vm.getClass
                        : _vm.current === item.value
                        ? "current " + _vm.getClass
                        : "" + _vm.getClass,
                      on: {
                        click: function ($event) {
                          return _vm.handleClick(item.value)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "span-name" }, [
                        _vm._v(_vm._s(item.label)),
                      ]),
                    ]
                  )
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.moreBtnVisible
              ? _c("div", { on: { click: _vm.handleToggleAllVisible } }, [
                  _c("span", { staticClass: "link-span" }, [
                    _vm._v(_vm._s(_vm.toggleBtnText) + " "),
                    _c("i", { staticClass: "ml-5", class: _vm.toggleBtnIcon }),
                  ]),
                ])
              : _vm._e(),
          ]
        : _vm._l(_vm.options, function (item) {
            return _c(
              "div",
              {
                key: item.value,
                staticClass: "choose-item",
                class: _vm.multiple
                  ? _vm.current.includes(item.value)
                    ? "current " + _vm.getClass
                    : _vm.getClass
                  : _vm.current === item.value
                  ? "current " + _vm.getClass
                  : "" + _vm.getClass,
                on: {
                  click: function ($event) {
                    return _vm.handleClick(item.value)
                  },
                },
              },
              [
                _c("span", { staticClass: "span-name" }, [
                  _vm._v(_vm._s(item.label)),
                ]),
              ]
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }