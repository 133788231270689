// import Vue from 'vue'
// import VueI18n from 'vue-i18n'
// import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang
// import zhLocale from './zh'

// Vue.use(VueI18n)
// const keys = Object.keys(zhLocale)
// const filterUnique = arr => arr.filter(i => arr.indexOf(i) !== arr.lastIndexOf(i))
// console.log(filterUnique([2, 4, 5, 3, 2, 4, 3]))
// const messages = {
//   zh: {
//     ...zhLocale,
//     ...elementZhLocale
//   }
// }

// const i18n = new VueI18n({
//   messages,
//   locale: 'zh',
//   silentTranslationWarn: true
// })

export default $ylI18n.vueI18n;