export default {
  A4Paper: 'A4 纸张',
  A5Paper: 'A5 纸张',
  abbreviation: '我的简称',
  accountInfo: '账号信息',
  accountType: '账号类型',
  accountTypes: '账户类型',
  actions: '操作',
  addAddressBook: '新增通讯录地址',
  addMultiple: '是否可以添加多个收寄件地址吗？',
  addMultiples: '可以的，您可以根据你的需要随意新增多个收/寄件人的地址。',
  addressBook: '从通讯录选择',
  afterCost: '税后金额',
  able: '开启',
  add: '新增',
  addAndSaveToAddressBook: '新增并保存到地址簿',
  addNewOrder: '新增一单',
  agreeTerms: '我同意',
  author: '作者',
  awaitGet: '坐等业务员取件',
  accountStatus: '账号状态',
  addFile: '添加文件',
  addErr: '新增失败',
  allColumn: '全部字段',
  account: '主账号',
  allOrder: '全部下单',
  announcements: '注意事项',
  senderInfo: '寄件人信息',
  batch: '如何批量下单？',
  howBatchs: '您可以点击前往《我的订单 — 订单录入/订单导入》界面。根据您的实际需求选择批量下单的方式，您可以在“订单录入”界面通过新增“收件人信息”进行批量下单；也可在“订单导入”界面通过从EXCEL导入订单信息进行下单。导入后可能会有错误提示根据提示进行批量设置修改后进行提交订单，系统分为您调度业务员上门取件。',
  buyInsurance: '如何购买物品保险？',
  howBuyInsurance: '如果您是选择急速下单或订单录入进行下单，您只需在填写货物信息时勾选购买保险按钮即可；如果您是选择订单导入进行下单，则需在Excel列表填写物品价值，填写物品价值后，系统会默认您已购买了保险，且还会为您自动计算保价费。',
  orderDetail: '订单详情',
  cancel: '取消',
  changeName: '是否可以修改我的手机号和名字？',
  changeNames: '可以的，您可以点击个人头像前往（我的资料）界面，点击“编辑信息”按钮然后在（编辑我的信息）界面重新输入“新名字/电话号码”即可完成修改。',
  changePwd: '修改密码',
  changePwds: '是否可以修改密码',
  changePwdss: '可以的，您可以点击个人头像前往（我的资料）界面，点击“修改密码”按钮然后在（修改密码）界面输入“旧密码”然后再输入两次新密码即可完成修改。',
  changes: '如何修改订单信息？',
  changess: '您可以点击前往《我的订单 — 订单管理》界面中找到您需要更改的运单进行修改即可。温馨提示~ 只有PENDING状态的订单才可修改订单信息哟！',
  chooseExcel: '选择Excel',
  citys: '省份/城市/区域',
  clearE: '清空',
  clientName: '委托人姓名',
  codFee: 'COD手续费',
  codManagement: 'COD管理',
  commonProblems: '常见问题',
  complaints: '我要投诉',
  complaintsInput: '请输入投诉原因',
  complaintReason: '投诉原因',
  confirm: '确定',
  confirmations: '下单成功',
  confirmPassword: '请再次输入密码',
  contacts: '联系人',
  cost: '费用',
  customerTitle: '尊敬的客户 ：您的运单编号',
  customExport: '自定义导出',
  editOrder: '编辑订单',
  Dashboard: '首页',
  myData: '我的资料',
  date: '日期',
  dateRreceipt: '签收日期',
  dateRreceipts: 'COD',
  Delete: '删除',
  df: '到付',
  dfyj: '寄付月结',
  DotDistribution: '网点分布',
  dqxz: '地区下载',
  dragTips1: '默认顺序',
  dragTips2: '拖拽后顺序',
  dynamicTips1: '固定表头, 按照表头顺序排序',
  dynamicTips2: '不固定表头, 按照点击顺序排序',
  editOpen: '启用编辑',
  edit: '编辑',
  editErr: '修改失败',
  EditMessage: '编辑我的信息',
  editorInfo: '编辑信息',
  editSec: '修改成功',
  endDates: '结束日期',
  enterAddressBook: '请输入姓名后回车搜索',
  enterName: '请输入我的名字',
  entrustedState: '委托状态',
  errorReason0: '导入不成功可能原因如下:',
  errorReason3: '3、模板表头带*号的字段为必填字段，否则将会导入失败',
  errorReason4: '4、Excel模板单次导入一次最多支持1000条数据',
  errorReason5: '5、当填写物品价值后系统会默认您已购买了保险;未填写物品价值,系统则默认为你尚未购买保险',
  excelIndex: '次序',
  export: '导出',
  exportSelectedData: '导出选中',
  expressTrack: '快件跟踪',
  expressType: '快件类型',
  expressYT: '已退件',
  forbidEdit: '禁止编辑',
  fixedHeader: '固定 Header',
  forget: '忘记旧密码就无法重置新密码，请联系(***)',
  forgetPwd: '忘记旧密码后如何修改密码？',
  freightRec: '应收运费',
  fullName: '我的全称',
  getPackagePhone: '收件电话',
  goodsName: '物品名称',
  receiverInfo: '收件人信息',
  here: '这里',
  howCheck: '如何查询运费？',
  howChecks: '您可以点击前往《服务管理 — 运费查询》界面进行查询；您也可以点击',
  checkFreight: '查询运费。',
  sendName: '寄件人姓名',
  importItemERR: '有订单格式不正确,下单失败',
  importItemSec: '订单导入成功',
  InstructionsForUse: '使用说明',
  insuranceCosts: '物品保价费怎么收费',
  insuranceCostss: '根据您所购买物品价值的1%来收取保价费，即物品价值*0.01 = 保价费;',
  insuranceCostsss: '例如：物品价值是10,000VND；则保价费是100VND。',
  insuranceEffect: '购买物品保险有什么用？',
  insuranceEffects: '购买保险后，当你的快件物品有损失或丢失后，可以获得更高额的补偿。',
  isConfirmCancel: '确认取消订单吗',
  isConfirmDelete: '确认删除记录吗',
  orderCancel: '取消订单',
  'CANCEL_ORDER': '取消订单',
  limitImportSize: '上传文件不能大于1M',
  loginAccount: '登录账号',
  selectSenderAdd: '请选择寄件人地址',
  manageReceaddress: '管理我的收货地址',
  managementSendaddress: '管理我的寄货地址',
  managementss: '管理',
  managementWaybill: '管理运单',
  ManageShippingCosts: '管理运费',
  mudi: '目的地',
  myInfo: '我的信息',
  myNetwork: '我的网点',
  selectReceiverAdd: '请选择收件人地址',
  noRecord: '该运单编号暂无记录',
  oldPwds: '如果您忘记了旧密码，建议你去寻求主账号人员为你修改密码即可',
  orderNo: '订单编号',
  ordercodeDS: '电商订单号',
  billCode: '运单编号',
  orderStatus: '订单状态',
  otherInfo: '其他信息',
  confirmed: '确认',
  package: '包裹',
  payForGoods: '代收货款',
  payTypePP_CASH: '到付月结',
  personal: '个人管理',
  phone: '手机号',
  phoneValidHead: '请手机号首位必须以0或+84开头',
  phoneValidLength: '请输入10-12位数的手机号码',
  pickDate: '取件日期',
  placeholder: '请输入文件名(默认excel-list)',
  placeOrder: '极速下单',
  pleaseChooseBuy: '请选购',
  pleaseClientName: '请输入委托人姓名',
  pleaseEnterBillCode: '请输入运单编号',
  pleaseEnterGetCity: '请输入收件城市',
  pleaseEnterSendCity: '请输入寄件城市',
  pleaseInputProvinces: '请选择所在地区，例如：广东省-深圳市-福田区',
  selectPrintDate: '请选择您要打印的数据',
  prepaid: '已付给客户',
  prepare: '准备付给客户',
  PrintSimpleSheet: '简易纸张',
  query: '查询',
  rapid: '如何急速下单？',
  howRapids: '您可以点击前往《我的订单 — 订单录入》界面。通过从“地址薄导入”或者通过“智能地址填写”快速导入收/寄件人信息后，填完预约信息后即可提交急速订单，在“订单成功”界面你可继续完善货物信息，也可待业务员上门取件时让我们的业务员帮助你填写。',
  receiptInfo: '收件信息',
  receiverAreaNotFind: '收件区域未找到',
  receiverCity: '收件城市',
  receiverCityNotFind: '收件城市未找到',
  receiverName: '收件人',
  receiverNameL: '收件人姓名',
  receiverProvNotFind: '收件省份未找到',
  reset: '重置',
  searchs: '查询快件情况',
  senderArea: '寄件区域',
  senderName: '寄件人',
  sendInfo: '寄件信息',
  sendPhone: '寄件电话',
  myNumber: '我的编号',
  serialNumber: '序号',
  setDefaults: '是否可以将收/寄件地址设置为默认地址？',
  setDefaultss: '可以的，设置成功后系统将会为您保存到下单界面，下单时无需再次填写收寄人信息。',
  setMultiple: '是否可以设置多个收/寄件默认地址？',
  setMultiples: '不可以，收/寄件地址目前只允许各设置一个默认地址。',
  sidebarLogo: '侧边栏 Logo',
  smartAddressPlace: '例如：Ego小哥 +6281912345678 湖北省黄石市下陆区某某大厦666号',
  smartAddressTip: '你也可以贴整段地址，自动识别姓名、电话和地址',
  srwp: '请输入物品价值',
  startDates: '开始日期',
  surfaceSinglesPrinting: 'J&T打印',
  tagsView: '开启 Tags-View',
  textareaPlaceholder: '请输入订单编号/运单编号进行查询',
  theAwb: '请输入运单号,多单号用英文逗号或回车隔开,单次最多输入10个运单编号',
  totalItem: '物品价值',
  toWhich: '快件到哪里了，什么时候可以到？',
  toWhichs: '您可以点击前往《我的订单 — 订单管理》界面查看快件情况；您也可以点击',
  tx: '如果您很忙，以下信息，您可等待我们的业务员为您填写！',
  txys: '请输入应收运费',
  unpaid: '未付给客户',
  username: '用户名',
  waybill: '运单查询或电话查询或单号查询',
  weights: '货物计费重量怎么计算',
  weightss: '计费重量，取实际重量和体积重量的最大值，即：货物实际重量大于体积重量，则按实际重量计费；货物实际重量小于体积重量，则按体积重量计费',
  wpjs: '物品件数',
  xxdz: '请输入详细地址',
  zl: '物品重量',
  bulkDelete: '批量删除',
  bulkImport: '批量导入',
  bulkUpload: '批量上传',
  bx: '是否想要保险',
  cargoInformation: '货物信息',
  changeInformation: '修改信息',
  city: '城市',
  ckdd: '查看订单',
  clientCall: '委托人手机号码',
  close: '关闭',
  closeAll: '关闭所有',
  closeOthers: '关闭其它',
  codeQuery: '请输入订单编号运单编号进行查询',
  complaintSuggest: '投诉建议',
  contact: '联系方式',
  contactPhone: '联系电话',
  defaultAddress: '设为默认地址',
  destinations: '请输入目的地',
  detailAddress: '详细地址',
  dizhi: '地址',
  draft: '草稿',
  editAddress: '修改寄件地址',
  electricitySingleNumber: '电商单号',
  enable: '禁用',
  endDate: '结束时间',
  endWeight: '结算重量',
  entrust: '委托',
  entrustedN: '未委托',
  entrustedY: '已委托',
  errorReason1: '1、旧模板和其他类型的模板将不再适用',
  errorReason2: '2、您所导入内容必须严格按照模板字段顺序正确填写',
  expressCancel: '已取消',
  expressDDD: '待调度到网点',
  expressDF: '待发件',
  expressDQ: '待取件',
  expressDToMan: '待调度到业务员',
  expressEZ: '正常运输',
  expressJND: '次日送达',
  expressJSD: '即日送达',
  expressPJCG: '派件成功',
  expressPJZ: '派件中',
  expressQJCG: '取件成功',
  expressAll: '全部',
  expressR: '已签收',
  expressUR: '未签收',
  expressYQ: '已取件',
  expressYS: '运输中',
  filelimitMin: '上传文件不能大于1',
  fileName: '文件名',
  financeManage: '财务管理',
  Fixed: '固定',
  payMethod: '支付方式',
  freightQuery: '运费查询',
  totalDelivery: '总运费',
  hasInsurance: '是否需要保险',
  houseNumber: '请输入详细地址(精确到门牌号)',
  importance: '重要性',
  importNow: '马上导入',
  information: '基本信息',
  insuranceprice: '保价费',
  isDelete: '确认删除吗',
  jf: '寄付现结',
  jijian: '寄件方式',
  jijiandizhis: '请输入寄件地址',
  jixu: '继续下单',
  jump: '保存并查看订单',
  latelyOneMon: '最近一个月',
  latelyThreeMon: '最近三个月',
  latelyWeek: '最近一周',
  limitImportLength: '上传文件数据不能超过1000条',
  loansFee: '应收货款',
  logIn: '登  录',
  loginError: '登录失败',
  logOut: '退出登录',
  matchLess2: '3、模板表头带*号的字段为必填字段否则将会导入失败',
  matchLess3: '4、模板单次导入一次最多支持1000条数据',
  matchLess4: '5、当填写物品价值后系统会默认您已购买了保险;未填写物品价值系统则默认为你尚未购买保险',
  matchLessR: '导入不成功可能原因如下',
  mr: '设置为默认',
  myPackage: '我的包裹',
  name: '姓名',
  nameEnpty: '用户名不能为空',
  newsAdress: '新增寄件地址',
  nextStep: '下一步',
  no: '否',
  noticeCenter: '其中有',
  noticeFoot: '数据的格式不符合模板规则',
  noticeHead: '你总共导入了',
  noticeSuccess: '成功导入',
  noticeUint: '条',
  numbers: '件数',
  phoneNum: '手机号码',
  nxWait: '请耐心等待快递小哥前来取件',
  oneDay: '一天',
  oneMonth: '一个月',
  order: '我的订单',
  orderCodeP: '请输入订单编号',
  orderDate: '订单日期',
  orderError: '下单失败',
  orderImport: '订单导入',
  orderManage: '订单管理',
  orderQuery: '订单查询',
  problemPiece: '问题件统计',
  orderRules: '《电子运单契约条款》',
  orderType: '订单号类型',
  orderWaybill: '我的运单',
  otherFees: '其他费用',
  password: '密码',
  permissionManage: '权限管理',
  personalSetting: '个人设置',
  phones: '电话',
  pleaseClientCall: '请输入委托人手机号码',
  pleaseEnterCod: '请输入代收货款金额',
  pleaseEnterContactNumber: '请输入联系电话',
  pleaseEnterGoodsWeight: '请输入物品重量',
  pleaseFillInTheAddressee: '请填写收件人',
  pleaseFillInTheSender: '请填写寄件人',
  pleaseInputPhoneNumber: '请输入手机号码',
  pleaseInputSenderName: '请输入寄件人姓名',
  pleaseReadTermsAndCheck: '请仔细阅读条款并勾选',
  pleaseSelectPayMethod: '请选择付款方式',
  pleaseSelectDeliveryType: '请选择快件类型',
  prevStep: '上一步',
  pswEmpty: '密码不能为空',
  publish: '发布',
  quickOrder: '订单录入',
  quyu: '区域',
  readDone: '我已阅读并同意',
  readings: '阅读数',
  receiptDestination: '请输入收件目的地',
  receiptNumber: '请输入收件手机号码',
  receiveAddress: '我的收件地址',
  receiverAddress: '收件地址',
  receiverArea: '收件区域',
  receiverProvince: '收件省份',
  receiverTel: '收件人电话',
  recipientCity: '请输入收件人城市或区域',
  recipientPhone: '请输入收件人电话',
  recipientRegion: '收件人地区',
  refresh: '刷新',
  remark: '备注',
  reminder: '提示',
  reviewer: '审核人',
  roleManage: '角色管理',
  save: '保存',
  search: '搜索',
  selectDate: '选择日期时间',
  select: '选择',
  selectedExport: '导出已选择项',
  selectP: '请选择',
  sendAddress: '我的寄件地址',
  sendCity: '请输入寄件人城市或区域',
  sendCustomer: '寄件客户',
  sendeDestination: '请输入寄件人目的地',
  senderAddress: '寄件地址',
  senderCity: '寄件城市',
  senderProvince: '寄件省份',
  senderTel: '寄件人电话',
  sendOrder: '下单',
  sendPhones: '请输入寄件人电话',
  SentItemNumber: '请输入寄件手机号码',
  service: '服务管理',
  setDefault: '是否默认',
  sevenDay: '七天',
  sf: '省份',
  shippingPlace: '寄件地',
  shoujian: '新增收件地址',
  Sidebar: '侧边栏',
  sm: '上门取件',
  smartAddress: '智能地址填写',
  srxm: '请输入姓名',
  srdhs: '请输入电话',
  srds: '请输入货款金额',
  srdss: '请输入电商订单号',
  srdz: '请输入地址',
  srphone: '请输入寄件人手机号',
  startDate: '开始时间',
  status: '状态',
  submit: '提交',
  submitOrder: '提交订单',
  submitTime: '提交时间',
  systemName: '系统登录',
  tableLoadingText: '加载中...',
  tableLoadingRead: '文件读取中...',
  templateDownload: '模板下载',
  theDoorOfTime: '预约取件时间',
  theDoorOfTimes: '请输入预约取件时间',
  theme: '主题色',
  totalAddress: '总收件人',
  totalCollectionOfPayment: '总代收货款',
  totalPackage: '总包裹',
  totalWeight: '总重量',
  trAdress: '请输入收件地址',
  trName: '请输入收件人姓名',
  trPhone: '请输入收件人手机号码',
  txbj: '请输入保价费',
  txjs: '请输入物品件数',
  txwp: '请输入物品名称',
  type: '类型',
  unit: '单位',
  userManage: '用户管理',
  waybillDetails: '运单详情',
  wc: '填写完成',
  weight: '重量',
  editReceiveAdd: '修改收件地址',
  xiadanNow: '立即下单',
  yes: '是',
  zj: '自寄',
  mange: '管理',
  mangeWaybill: '运单管理',
  packUp: '收起',
  unfold: '展开',
  allPick: '全选',
  errorTip1: '信息填写错误，请检查后重新填写~',
  errorTip2: '未找到对应的省，请您手动选择哦~',
  errorTip3: '未找到对应的城市，请您手动选择哦~',
  errorTip4: '未找到对应的区域，请您手动选择哦~',
  verifyRec: '请输入收件人',
  selectTip: '您还未勾选任何订单！',
  packageIndex: '包裹序号',
  jtPaper: 'J&T纸张',
  errorTip6: '该地区暂无数据',
  errorTip5: '信息填写错误，请修改后下单~',
  cancelOrder: '订单取消成功',
  enterNum: '请输入运单编号',
  enterNums: '单次最多查询10个运单编号',
  complaintsSuccess: '投诉成功',
  complaintsinfo: '运单编号错误,请重新输入',
  setSuccess: '设置成功!',
  goodsGrow: '货量增长',
  yunfei: '运费',
  cityTop5: '城市运单 top5',
  pieTotal: '总和',
  oldPwd: '旧密码',
  newPwd: '新密码',
  confirmPwd: '确认密码',
  format: '请检查输入格式，首位由字母组成、其他由数字或特殊符号组成',
  lengthFormats: '长度在6-16个字符',
  enterPwd: '请输入密码',
  setDefaul: '你确定要设置为默认地址吗',
  cancelDefaul: '你确定要取消默认地址吗',
  del: '你确定要删除选中项吗？',
  delSuccess: '删除成功!',
  HasCancelled: '已取消',
  delsend: '你确定要删除该寄件地址吗？',
  delreceived: '你确定要删除该收件地址吗？',
  newSuccess: '新增成功!',
  qsrssq: '请输入省份/城市/区域',
  orderTime: '订单时间',
  myNames: '我的名字',
  myPhones: '我的电话',
  changeErro: '修改失败',
  consistent: '你两次输入的密码不一致，请重新输入',
  infoErros: '账号或密码错误',
  enterNames: '请输入我的名字',
  errorTip7: '不能包含已取消的订单，请重新选择',
  errorTip8: '打印订单状态只可为PENDING、总部调度给网点、订单调派给业务员等状态 ，请重新选择',
  editData: '编辑资料',
  customerName: '客户名称',
  permissions: '权限设置',
  totalError: '物品价值类型必须为数字',
  codError: 'COD数值类型必须为数字',
  payMethodError: '未找到该付款方式',
  noEmpty: '该栏位必填',
  noOver: '不能超过',
  noless: '不能少于',
  needEnter: '必须输入',
  zString: '字符',
  geshiError: '格式错误(例:0或者+84开头)',
  onlyNumber: '只能包含数字(例:0.2)',
  onlyInt: '只能是整数',
  weightOver: '重量不能超过200KG',
  weightLower: '重量不能低于0.1KG',
  numberLower: '件数不能超过6位整数',
  numberOver: '件数不能低于',
  expressTypeErr: '快件类型错误',
  moneyLength: '不能超过8位整数',
  payMethodErr: '支付方式类型错误',
  payForGoodsOver: '代收货款金额超出',
  totalItemOver: '物品价值不能超过99999999',
  otherFeesOver: '其他费用不能超过99999999',
  netTimeout: '可能由于网络状态不佳,请求超时了',
  serverErr: '服务器内部错误',
  requesErr: '请求失败!',
  loginAgain: '重新登录',
  confirmLogOut: '确定登出',
  sessionTimeout: 'session过期，可以取消继续留在该页面，或者重新登录',
  lengthAs: '长度为',
  customerNames: '客户名称',
  orderLibrary: '订单库',
  saveOrder: '保存订单',
  shenhe: '审核日期',
  enterNamess: '请输入客户名称',
  enterConfirms: '请输入确认密码',
  enterRemarks: '请输入备注',
  'PENDING': '客户已下单',
  'PUSAT_DISPATCH': '总部调度给网点',
  'DISPATCH': '订单调派给业务员',
  'GOT': '收件扫描',
  'Pie scanning': '派件扫描',
  'received scan': '签收扫描',
  'abnormal parcel': '疑难件以及留仓件',
  'Returned': '退件签收',
  'ABNORMAL_PARCEL': '问题件',
  xb: '新版',
  jb: '旧版',
  noOrder: '暂无订单',
  orderClass: '订单类型',
  filter: '筛选',
  filterPack: '筛选器',
  totalWaybill: '总运单',
  totalCod: '总COD',
  totalOrder: '总订单',
  editOrderRow: '修改订单列',
  getterAddress: '收件人地址',
  importGoodsValue: '物品价值(保价)',
  importCod: '代收货款(VND)',
  formatError: '格式错误',
  importing: '导入中',
  imported: '导入完成',
  readingNow: '正在读取',
  importError: '文件格式错误，无法导入！',
  importStep1: '准备订单模板',
  downTemplate: '下载模板',
  importStep2: '请选择寄件人',
  importStep3: '上传数据文件',
  importTip1: '目前支持的文件类型为*.xls，*.xlsx。',
  moreImportRule: '更多字段导入规则',
  importTip2: '导入的订单个数最多',
  importTip3: '所有允许导入的订单字段请参考模版',
  importTip4: '订单字段如不符合规则，整条订单不予以导入',
  orderEditSuccess: '订单编辑成功！',
  payFun: '付款方式',
  searchFunModel: '搜索功能模块',
  removePack: '是否删除该包裹',
  waitUpload: '待上传',
  orderSaveSuccess: '订单保存成功',
  nameExist: '账号已存在,请重新输入',
  nameNoChange: '账号不可更改',
  enabled: '是否启用',
  qiyong: '启用',
  jinyong: '禁用',
  newCustomer: '新增用户',
  resetPsw: '重置密码',
  resetPswC: '确定密码重置吗',
  resetPswS: '重置成功',
  resetPswE: '重置失败',
  delErr: '删除失败',
  isJinyong: '确定禁用该账号吗',
  jinyongS: '禁用成功！',
  qiyongS: '启用成功！',
  qiyongErr: '启用失败！',
  enterLoginAccount: '请输入登录账号',
  showColumn: '显示字段',
  columnSetting: '字段设置',
  time: '时间',
  templatedColumn: '自定义显示',
  isPrint: '是否需要批量打印',
  goodsWeightError: '物品重量不可大于200kg',
  queryProv: '请输入您要找的省份',
  queryCity: '请输入您要找的城市',
  queryArea: '请输入您要找的区域',
  totalfreightPPCASH: '寄件人付款',
  totalfreightCCCASH: '收件人付款',
  totalfreightPPPM: '月结付款',
  codYF: '已付',
  codWF: '未付',
  codZBF: '待付',
  printErr: '打印失败',
  filterAdd: '过滤器配置',
  tableIndex: '列表',
  enterCode: '请输入编号',
  codeType: '编号类型',
  codes: '编号',
  totalTaxFee: '总税后金额',
  payForGoodsOverNumber: '代收货款金额不可超过99999999',
  ddtime: '请选择订单时间',
  ddtype: '选择订单状态',
  changeSuccess: '切换成功！',
  'PICKUP FAILED': '取件失败',
  growthRate: '增长率',
  shuliang: '数量',
  TOTAL: '全部',
  quote: '保价',
  Reserved: '保留',
  PPCASH: '寄件人付款',
  PPPM: '月结',
  CCCASH: '收件人付款',
  columnOption: '列配置',
  searchTip1: '请输入单号或电话号码来查询',
  orderHandle: '订单操作',
  cods: 'COD(VND)',
  freight: '运费(VND)',
  packUps: '收起',
  websiteName: '网点名称',
  expressInfo: '运输信息',
  reOrder: '重新下单',
  queryJudge: '查询条件',
  printFail: '打印失败',
  queryConditions: '查询条件',
  loadText: '拼命加载中',
  childerAccount: '子账号',
  pickAllColumn: '选择所有字段',
  exportSort: '导出排序',
  nodatas: '暂无数据',
  paramsMax: '最多只能查询1000个,已自动截掉超出1000的部分！',
  params10Max: '最多只能输入10个运单号,已自动截掉超出10的部分！',
  fpptype: '订单状态非PENDING、PUSAT_DISPATCH状态不能取消',
  fppdtype: '订单状态非PENDING、PUSAT_DISPATCH、DISPATCH状态不能修改',
  oldPswErr: '原始密码不正确',
  setting: '设置',
  tableSet: '列表配置',
  i18nManage: '国际化',
  tableName: '列表名称',
  handleSuccess: '操作成功',
  handleFailure: '操作失败',
  entryDate: '录入日期',
  deleteSelected: '删除选中',
  deleteAll: '删除全部',
  checkOrder: '选中下单',
  pleaseInput: '请输入',
  networkError: '网络错误',
  throwErr: '报错了哦!',
  importErr: '订单导入失败',
  importLoadingText: '请稍候...',
  printLoadingText: '打印中,请稍候...',
  table: '列表',
  tableAdd: '列表配置',
  systemUpgrade: '系统升级完毕!',
  thenDo: '请处理完事务，然后',
  refreshInterface: '刷新界面',
  signing: '签收',
  notsigning: '未签收',
  bounce: '退件',
  goodsNum: '物品件数不能超过999999',
  inputOtherFee: '请输入其他费用',
  problemOrder: '问题件',
  errConfirm: '验证失败,请重新登录',
  editDelErr: '该订单还在编辑中,不能删除!',
  totalProblems: '总数量',
  justNum: '只能输入数字',
  weightLimit: '物品重量不可小于0.1kg',
  numsLimit: '物品件数不能为负',
  shipType: '运输方式',
  shipTypeErr: '运输方式不能为空，请检查',
  proCityAreaErr: '省市区不能为空',
  proCityAreaNo: '省市区不完整,请重新选择',
  str50Len: '不能超过50字符',
  str250Len: '不能超过250字符',
  example: 'demo',
  tableEdit: '表格增删改查',
  B2BorderEntry: 'B2B订单录入',
  Comdemo: '公共组件示例',
  CommonCli: '公共框架示例',
  FormArray: '表单数组嵌套',
  company: '公司',
  printStatus: '打印状态',
  thirdPar: '第三方',
  tariffPay: '关税到付',
  tariffPrepay: '关税预付',
  tariffPayType: '关税支付方式',
  logoName: 'Logo名称'
};