//
//
//
//

import dateItem from "./date";
import inputItem from "./input";
import chooseItem from "./choose";
import textareaItem from "./textarea";
import selectItem from "./select";
import groupItem from "./group";
import radioGroupItem from "./radioGroup";
import checkboxItem from "./checkbox";
import timeItem from "./time";
import gridItem from "./grid";
import dateAreaItem from "./dateArea";
import buttonItem from "./button";
import addressBookItem from "./addressBook";
import configTableItem from "./configTable";
import buttonDrawerItem from "./buttonDrawer";
import richTextItem from "./richText";
import containerItem from "./container";
import carouselItem from "./carousel";
import scmNavItem from "./scmNav";
import slideDisplayItem from "./slideDisplay";
export default {
  components: {
    scmNavItem: scmNavItem,
    carouselItem: carouselItem,
    containerItem: containerItem,
    buttonDrawerItem: buttonDrawerItem,
    richTextItem: richTextItem,
    dateAreaItem: dateAreaItem,
    buttonItem: buttonItem,
    addressBookItem: addressBookItem,
    configTableItem: configTableItem,
    textareaItem: textareaItem,
    timeItem: timeItem,
    gridItem: gridItem,
    radioGroupItem: radioGroupItem,
    groupItem: groupItem,
    selectItem: selectItem,
    checkboxItem: checkboxItem,
    chooseItem: chooseItem,
    inputItem: inputItem,
    dateItem: dateItem,
    slideDisplayItem: slideDisplayItem
  },
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    context: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {};
  },
  created: function created() {}
};