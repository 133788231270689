import "core-js/modules/es6.array.find";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';
import { parseStyles } from "../../utils/style";
import { mapGetters, mapMutations } from 'vuex';
import { formatFormItem, removeAllNonItem } from "../../utils/global";
import _ from 'loadsh';
export default {
  components: {
    Swiper: Swiper,
    SwiperSlide: SwiperSlide
  },
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      visible: true,
      options: {
        slidesPerView: 4,
        spaceBetween: 20,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        scrollbar: {
          el: '.swiper-scrollbar',
          hide: true
        }
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['collection'])), {}, {
    styles: function styles() {
      return parseStyles(this.data.field.styles, this.data.field.props);
    },
    slideOptions: function slideOptions() {
      var _this$data$field$prop = this.data.field.props,
        slidesPerView = _this$data$field$prop.slidesPerView,
        spaceBetween = _this$data$field$prop.spaceBetween;
      return {
        slidesPerView: slidesPerView,
        spaceBetween: spaceBetween,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        scrollbar: {
          el: '.swiper-scrollbar',
          hide: true
        }
      };
    }
  }),
  watch: {
    slideOptions: function slideOptions() {
      this.update();
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: _objectSpread(_objectSpread({}, mapMutations({
    addFormItemToChildren: 'ADD_FORM_ITEM_TO_CHILDREN'
  })), {}, {
    handlePrev: function handlePrev() {
      this.$refs.slide.$swiper.slidePrev();
      console.log(this.$refs.slide);
    },
    handleNext: function handleNext() {
      this.$refs.slide.$swiper.slideNext();
    },
    update: function update() {
      var _this = this;
      this.visible = false;
      this.$nextTick(function () {
        _this.visible = true;
      });
    },
    onAdd: function onAdd(data) {
      removeAllNonItem();
      var isSort = data.item.className.includes('form-drag-warp');
      if (isSort) return;
      var fieldId = data.item.getAttribute('field-id');
      var parentId = data.to.getAttribute('data-parent');
      var elementId = data.to.getAttribute('data-id');
      var field = formatFormItem(_.cloneDeep(this.collection.find(function (item) {
        return item.id === fieldId;
      })));
      field.parentId = parentId;
      field.parentType = 'slideDisplay';
      var obj = {
        parentId: parentId,
        elementId: elementId,
        field: field,
        index: data.newIndex
      };
      this.addFormItemToChildren(obj);
    }
  })
};