var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "ck-content",
    class: _vm.data.field.paramsKey,
    style: _vm.styles,
    domProps: { innerHTML: _vm._s(_vm.sanitizeValue(_vm.data.field.value)) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }