import "core-js/modules/es6.array.find";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//

import { on, off } from '@/ylCommon/utils/dom';
import { debounce } from 'throttle-debounce';
import { mapGetters } from 'vuex';
export default {
  name: 'Drag',
  props: {
    componentObject: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isInstance: {
      type: Boolean,
      default: false
    },
    aimId: {
      type: String,
      default: ''
    },
    defaultX: {
      type: Number,
      default: 0
    },
    defaultY: {
      type: Number,
      default: 0
    },
    updateId: {
      type: String,
      default: ''
    },
    default: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      x: '',
      y: '',
      downX: '',
      downY: '',
      resizeDownX: '',
      resizeDownY: '',
      downWidth: '',
      downHeight: '',
      offsetLeft: '',
      offsetTop: '',
      resizeOffsetRight: '',
      resizeOffsetBottom: '',
      board: {},
      active: false,
      defaultWidth: '',
      defaultHeight: '',
      width: '',
      height: '',
      debounceUpdateComponent: Function
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['activeComponent'])), {}, {
    dragStyle: function dragStyle() {
      var width = this.width,
        height = this.height,
        y = this.y,
        x = this.x,
        isLine = this.isLine;
      return {
        width: "".concat(width, "px"),
        height: "".concat(height, "px"),
        top: "".concat(y, "px"),
        left: "".concat(x, "px"),
        padding: isLine ? '0' : '0 10px 0 0',
        overflow: isLine ? 'unset' : 'hidden'
      };
    },
    activeClass: function activeClass() {
      var result = [];
      var _this$activeComponent = this.activeComponent.id,
        id = _this$activeComponent === void 0 ? '' : _this$activeComponent;
      if (id === this.componentObject.id) {
        result.push('is-active');
      }
      if (this.componentObject.type === 'BarcodeUi') {
        result.push('barcode-ui');
      }
      if (this.isLine) {
        result.push('line-ui');
      }
      return result;
    },
    isLine: function isLine() {
      return this.componentObject.type === 'XLineUi' || this.componentObject.type === 'YLineUi' || this.componentObject.type === 'RectangleUi';
    },
    resizeDisabledY: function resizeDisabledY() {
      return this.componentObject.type === 'XLineUi';
    },
    resizeDisabledX: function resizeDisabledX() {
      return this.componentObject.type === 'YLineUi';
    },
    resizeVisible: function resizeVisible() {
      return this.activeClass.includes('is-active');
    }
  }),
  mounted: function mounted() {
    this.debounceUpdateComponent = debounce(200, this.moveEnd);
  },
  destroyed: function destroyed() {
    this.clearAllListener();
  },
  methods: {
    init: function init() {
      this.initLayoutScheme();
    },
    initLayoutScheme: function initLayoutScheme() {
      var _this = this;
      var $drag = this.$refs.drag;
      var isInstance = this.isInstance;
      var element = $drag.firstElementChild;
      var defaultData = this.default;
      var canvas = document.querySelector('.drag-canvas-warp.board-canvas');
      var _$drag$getBoundingCli = $drag.getBoundingClientRect(),
        width = _$drag$getBoundingCli.width,
        height = _$drag$getBoundingCli.height;
      var defaultX = this.defaultX,
        defaultY = this.defaultY;
      var _element$getBoundingC = element.getBoundingClientRect(),
        top = _element$getBoundingC.top,
        left = _element$getBoundingC.left;
      this.board = canvas.getBoundingClientRect();
      this.offsetLeft = left;
      this.offsetTop = top;
      this.defaultHeight = height;
      this.defaultWidth = width;
      this.width = width;
      if (isInstance) {
        this.x = defaultData.x;
        this.y = defaultData.y;
        this.width = defaultData.width;
        this.height = defaultData.height || '';
      } else {
        this.x = defaultX - left;
        this.y = defaultY - top;
      }
      this.$nextTick(function () {
        _this.debounceUpdateComponent();
      });
    },
    clearAllListener: function clearAllListener() {
      off(document, 'mousemove', this.handleResizeMove);
      off(document, 'mouseup', this.handleResizeUp);
      off(document, 'mousemove', this.handleMouseMove);
      off(document, 'mouseup', this.handleMouseUp);
    },
    handleMouseDown: function handleMouseDown(e) {
      var $drag = e.path.find(function (item) {
        return item.className.includes('drag-warp');
      });
      var _$drag$getBoundingCli2 = $drag.getBoundingClientRect(),
        top = _$drag$getBoundingCli2.top,
        left = _$drag$getBoundingCli2.left;
      this.downX = e.clientX - left;
      this.downY = e.clientY - top;
      on(document, 'mousemove', this.handleMouseMove);
      on(document, 'mouseup', this.handleMouseUp);
      this.handleSetCurrent();
    },
    handleContextMenu: function handleContextMenu() {
      this.clearAllListener();
    },
    handleSetCurrent: function handleSetCurrent() {
      var _this$componentObject = this.componentObject.id,
        id = _this$componentObject === void 0 ? '' : _this$componentObject;
      this.$store.dispatch('components/setActive', id);
    },
    handleMouseMove: function handleMouseMove(e) {
      var aim = this.aimId;
      var clientX = e.clientX;
      var clientY = e.clientY;
      var boardHeight = this.board.height;
      var boardWidth = this.board.width;
      var x = clientX - this.offsetLeft - this.downX;
      var y = clientY - this.offsetTop - this.downY;
      var $element = document.getElementById(aim);
      var _$element$getBounding = $element.getBoundingClientRect(),
        width = _$element$getBounding.width,
        height = _$element$getBounding.height;
      if (x <= 0) {
        this.x = 0;
      } else if (width + x >= boardWidth) {
        this.x = boardWidth - width;
      } else {
        this.x = x;
      }
      if (y <= 0) {
        this.y = 0;
      } else if (height + y >= boardHeight) {
        this.y = boardHeight - height;
      } else {
        this.y = y;
      }
      this.debounceUpdateComponent();
    },
    handleMouseUp: function handleMouseUp() {
      off(document, 'mousemove', this.handleMouseMove);
      off(document, 'mouseup', this.handleMouseUp);
    },
    moveEnd: function moveEnd() {
      var _this2 = this;
      setTimeout(function () {
        var x = _this2.x,
          y = _this2.y;
        var dragData = {
          id: _this2.aimId,
          x: x,
          y: y,
          instance: true,
          width: _this2.width || 0,
          height: _this2.height || 0,
          position: {
            clientX: x,
            clientY: y
          }
        };
        _this2.$emit('move-end', dragData);
      });
    },
    handleResizeUp: function handleResizeUp() {
      off(document, 'mousemove', this.handleResizeMove);
      off(document, 'mouseup', this.handleResizeUp);
    },
    handleResizeMove: function handleResizeMove(e) {
      var clientX = e.clientX,
        clientY = e.clientY;
      var defaultWidth = this.defaultWidth;
      var defaultHeight = this.defaultHeight;
      var downWidth = this.downWidth;
      var downHeight = this.downHeight;
      var moveX = clientX - this.resizeDownX;
      var moveY = clientY - this.resizeDownY;
      var offsetRight = this.resizeOffsetRight;
      var offsetBottom = this.resizeOffsetBottom;
      var width = downWidth + moveX;
      var height = downHeight + moveY;
      var heightLimit = height <= defaultHeight;
      var widthLimit = width <= defaultWidth;
      var xEdge = moveX >= offsetRight;
      var yEdge = moveY >= offsetBottom;
      if (!this.resizeDisabledX) {
        if (widthLimit) {
          this.width = defaultWidth;
        } else if (xEdge) {
          this.width = downWidth + offsetRight;
        } else {
          this.width = width;
        }
      }
      if (!this.resizeDisabledY) {
        if (heightLimit) {
          this.height = defaultHeight;
        } else if (yEdge) {
          this.height = downHeight + offsetBottom;
        } else {
          this.height = height;
        }
      }
      this.debounceUpdateComponent();
    },
    handleResizeDown: function handleResizeDown(e) {
      var $drag = this.$refs.drag;
      var _$drag$getBoundingCli3 = $drag.getBoundingClientRect(),
        width = _$drag$getBoundingCli3.width,
        height = _$drag$getBoundingCli3.height;
      this.resizeOffsetRight = this.board.width - $drag.offsetLeft - width;
      this.resizeOffsetBottom = this.board.height - $drag.offsetTop - height;
      this.resizeDownX = e.clientX;
      this.resizeDownY = e.clientY;
      this.downWidth = width;
      this.downHeight = height;
      on(document, 'mousemove', this.handleResizeMove);
      on(document, 'mouseup', this.handleResizeUp);
      e.stopPropagation();
    }
  }
};