//
//
//
//

import { parseStyles } from "../../../views/setting/design/form/utils/style";
export default {
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    styles: function styles() {
      return parseStyles(this.data.field.styles, this.data.field.props);
    }
  },
  methods: {
    sanitizeValue: function sanitizeValue(v) {
      return this.$XSS.sanitize(v);
    }
  }
};