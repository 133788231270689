//
//
//
//
//
//
//
//

import { parseStyles } from "../../../views/setting/design/form/utils/style";
export default {
  components: {},
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      gridList: []
    };
  },
  computed: {
    styles: function styles() {
      return parseStyles(this.data.field.styles, this.data.field.props);
    }
  },
  methods: {
    onInput: function onInput(update) {
      this.$emit('input', update);
    },
    onRequestAfter: function onRequestAfter(data) {
      this.$emit('request-after', data);
    }
  }
};