var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "toggle-collapse-btn",
      on: { click: _vm.handleToggleCollapse },
    },
    [
      _c("div", { staticClass: "btn-area" }, [
        _c(
          "div",
          { staticClass: "box" },
          [
            !_vm.collapse
              ? _c("svg-icon", {
                  staticClass: "ss-icon",
                  attrs: { "icon-class": "ss" },
                })
              : _c("svg-icon", {
                  staticClass: "ss-icon",
                  attrs: { "icon-class": "icon-r" },
                }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }