import "core-js/modules/es6.function.name";
import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
export default {
  data: function data() {
    return {
      searchKey: ''
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    userMenu: function userMenu(state) {
      return state.user.userMenu;
    },
    activeModule: function activeModule(state) {
      return state.user.activeModule;
    }
  })), {}, {
    menuOptions: function menuOptions() {
      var result = [];
      var getMenu = function getMenu(children) {
        children.map(function (item) {
          if (!item.children || item.children.length < 1) {
            var menu = {
              label: item.meta.title,
              value: item.name
            };
            !item.hidden && result.push(menu);
          }
          if (item.children && item.children.length > 0) getMenu(item.children);
        });
      };
      var active = this.activeModule ? this.activeModule.name : '';
      this.userMenu.map(function (item, index) {
        if (item.name === active) {
          item.children && getMenu(item.children);
        }
      });
      return result;
    }
  }),
  methods: {
    onChange: function onChange(name) {
      this.$router.push({
        name: name
      });
      this.searchKey = '';
    }
  }
};