import _defineProperty from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
var _ecommerce;
import fetch from '@/ylCommon/utils/request';
var ecommerce = (_ecommerce = {
  createShop: function createShop(options) {
    // 新建店铺
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/createShop.do',
      data: params
    });
  },
  getAuthUrl: function getAuthUrl(options) {
    // 获取店铺授权链接
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/shopee/getAuthUrl.do',
      data: params
    });
  },
  getStoreAuth: function getStoreAuth(options) {
    // Thisshop
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/auth/storeAuth.do',
      data: params
    });
  },
  getAuthUrlFortiktok: function getAuthUrlFortiktok(options) {
    // tiktok
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/tiktok/getAuthUrl.do',
      data: params
    });
  },
  getCarriers: function getCarriers(options) {
    // Thisshop 获取承运商
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/auth/getCarriers.do',
      data: params
    });
  },
  distribution: function distribution(options) {
    // Thisshop 配货
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/auth/distribution.do',
      data: params
    });
  },
  bindShop: function bindShop(options) {
    // 绑定店铺
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/shopee/bindShop.do',
      data: params
    });
  },
  tiktokbindShop: function tiktokbindShop(options) {
    // TIKTOK绑定店铺
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/tiktok/bindShop.do',
      data: params
    });
  },
  shopLazzaBindShop: function shopLazzaBindShop(options) {
    // shopLazza绑定店铺
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/shopLazza/bindShop.do',
      data: params
    });
  },
  shopLazzaGetAuthUrl: function shopLazzaGetAuthUrl(options) {
    // shopLazza获取授权
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/shopLazza/getAuthUrl.do',
      data: params
    });
  },
  deleteShop: function deleteShop(options) {
    // 删除店铺
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/deleteShop.do',
      data: params
    });
  },
  editShop: function editShop(options) {
    // 编辑店铺
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/editShop.do',
      data: params
    });
  },
  setRoute: function setRoute(options) {
    // 设置店铺指定线路
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/setRoute.do',
      data: params
    });
  },
  queryRoute: function queryRoute(params) {
    // 查询指定线路
    var param = {
      parameter: params
    };
    return fetch({
      url: '/baseinfo/router/queryRoute.do',
      data: param
    });
  },
  createSku: function createSku(options) {
    // 新建SKU
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/createSku.do',
      data: params
    });
  },
  deleteSku: function deleteSku(options) {
    // 删除SKU
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/deleteSku.do',
      data: params
    });
  },
  editSku: function editSku(options) {
    // 编辑SKU
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/editSku.do',
      data: params
    });
  },
  createSkuMapping: function createSkuMapping(options) {
    // 新建SKU映射
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/createSkuMapping.do',
      data: params
    });
  },
  deleteSkuMapping: function deleteSkuMapping(options) {
    // 删除SKU映射
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/deleteSkuMapping.do',
      data: params
    });
  },
  editSkuMapping: function editSkuMapping(options) {
    // 编辑SKU映射
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/editSkuMapping.do',
      data: params
    });
  },
  createB2CSkuMapping: function createB2CSkuMapping(options) {
    // 新增B2C-SKU映射
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/createB2CSkuMapping.do',
      data: params
    });
  },
  auditOrder: function auditOrder(options) {
    // 审核
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/auditOrder.do',
      data: params
    });
  },
  shipOrder: function shipOrder(options) {
    // 配货
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/shipOrder.do',
      data: params
    });
  },
  pushOrder: function pushOrder(options) {
    // 推送
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/pushOrder.do',
      data: params
    });
  },
  syncOrder: function syncOrder(options) {
    // 同步订单
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/syncOrder.do',
      data: params
    });
  },
  syncSku: function syncSku(options) {
    // 同步SKU
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/syncSku.do',
      data: params
    });
  },
  queryShop: function queryShop(options) {
    // 查询店铺
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/queryShop.do',
      data: params
    });
  },
  setOrderShipInfo: function setOrderShipInfo(options) {
    // 新增设置渠道产品
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/setOrderShipInfo.do',
      data: params
    });
  },
  transformOrder: function transformOrder(options) {
    // 发货预报
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/transformOrder.do',
      data: params
    });
  },
  createOrder: function createOrder(options) {
    // 新增订单
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/createOrder.do',
      data: params
    });
  },
  updateOrder: function updateOrder(options) {
    // 编辑订单
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/updateOrder.do',
      data: params
    });
  },
  queryOrder: function queryOrder(options) {
    // 查询店铺
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/queryOrder.do',
      data: params
    });
  },
  queryOrderItems: function queryOrderItems(options) {
    // 查询订单明细
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/queryOrderItems.do',
      data: params
    });
  },
  fetchLabel: function fetchLabel(options) {
    // 获取面单接口
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/fetchLabel.do',
      data: params
    });
  },
  changeSkuMappingStatus: function changeSkuMappingStatus(options) {
    // 设置有效无效
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/changeSkuMappingStatus.do',
      data: params
    });
  },
  batchEditSkuMapping: function batchEditSkuMapping(options) {
    // SKU导入
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/batchEditSkuMapping.do',
      data: params
    });
  },
  ycSkuAssociation: function ycSkuAssociation(options) {
    // 云仓sku映射编辑
    var params = {
      parameter: options
    };
    return fetch({
      url: '/ecommerce/common/ycSkuAssociation.do',
      data: params
    });
  },
  // 新订单管理
  newQueryOrder: function newQueryOrder(options) {
    // 查询店铺
    var params = {
      parameter: options
    };
    return fetch({
      url: '/new/ecommerce/common/queryOrder.do',
      data: params
    });
  },
  productEditSku: function productEditSku(options) {
    // 卖家助手-产品管理-产品编辑
    var params = {
      parameter: options
    };
    return fetch({
      url: '/new/ecommerce/common/editSku.do',
      data: params
    });
  },
  newOrderDetail: function newOrderDetail(options) {
    // 卖家助手-订单管理-订单详情
    var params = {
      parameter: options
    };
    return fetch({
      url: '/new/ecommerce/common/queryOrderDetails.do',
      data: params
    });
  },
  productAudit: function productAudit(options) {
    // 卖家助手-订单管理-审核订单
    var params = {
      parameter: options
    };
    return fetch({
      url: '/new/ecommerce/common/audit.do',
      data: params
    });
  },
  orderBatchForecast: function orderBatchForecast(options) {
    // 卖家助手-订单管理-批量订单预报
    var params = {
      parameter: options
    };
    return fetch({
      url: '/new/ecommerce/common/orderBatchForecast.do',
      data: params
    });
  },
  orderBatchSyncCRW: function orderBatchSyncCRW(options) {
    // 卖家助手-订单管理-批量订单同步云仓发货
    var params = {
      parameter: options
    };
    return fetch({
      url: '/new/ecommerce/common/orderBatchSyncCRW.do',
      data: params
    });
  },
  saveForecastOrder: function saveForecastOrder(options) {
    // 卖家助手V2-订单管理-预报单保存
    var params = {
      parameter: options
    };
    return fetch({
      url: '/new/ecommerce/common/saveForecastOrder.do',
      data: params
    });
  },
  saveOutboundOrder: function saveOutboundOrder(options) {
    // 卖家助手V2-订单管理-预报单保存
    var params = {
      parameter: options
    };
    return fetch({
      url: '/new/ecommerce/common/saveOutboundOrder.do',
      data: params
    });
  },
  editOrderByNew: function editOrderByNew(options) {
    // 卖家助手V2-订单管理-预报单保存
    var params = {
      parameter: options
    };
    return fetch({
      url: '/new/ecommerce/common/editOrder.do',
      data: params
    });
  },
  markShipment: function markShipment(options) {
    // 卖家助手-订单管理-批量标记发货
    var params = {
      parameter: options
    };
    return fetch({
      url: '/new/ecommerce/common/markShipment.do',
      data: params
    });
  },
  calOutWeight: function calOutWeight(params) {
    // 卖家助手V2-订单管理-预报单算重量和体积接口
    return fetch({
      url: '/new/ecommerce/common/calOutWeight.do',
      data: {
        parameter: params
      }
    });
  }
}, _defineProperty(_ecommerce, "orderBatchForecast", function orderBatchForecast(params) {
  // 卖家助手V2-订单管理-预报单预报接口
  return fetch({
    url: '/new/ecommerce/common/orderBatchForecast.do',
    data: {
      parameter: params
    }
  });
}), _defineProperty(_ecommerce, "orderBatchSyncCRW", function orderBatchSyncCRW(params) {
  // 卖家助手V2-订单管理-出库单同步云仓
  return fetch({
    url: '/new/ecommerce/common/orderBatchSyncCRW.do',
    data: {
      parameter: params
    }
  });
}), _defineProperty(_ecommerce, "batchUpdateEndTrackingNo", function batchUpdateEndTrackingNo(options) {
  // 卖家助手-订单管理-批量获取物流单号
  var params = {
    parameter: options
  };
  return fetch({
    url: '/new/ecommerce/common/batchUpdateEndTrackingNo.do',
    data: params
  });
}), _ecommerce);
export default ecommerce;