var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "button-drawer-warp" },
    [
      _c(
        "el-button",
        {
          staticClass: "toggle-btn",
          class: _vm.data.field.props.className,
          style: _vm.styles,
          attrs: {
            size: _vm.data.field.props.size,
            plain: _vm.data.field.props.plain,
            disabled: _vm.data.field.props.disabled,
            round: _vm.data.field.props.round,
            type: _vm.data.field.props.type,
            circle: _vm.data.field.props.circle,
          },
          on: { click: _vm.handleToggleDrawer },
        },
        [
          _vm._v("\n    " + _vm._s(_vm.data.field.name) + " "),
          _c("i", {
            staticClass: "el-icon-d-arrow-left fs-14",
            class: _vm.iconClass,
          }),
        ]
      ),
      _vm._v(" "),
      _c("el-collapse-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.drawerVisible,
                expression: "drawerVisible",
              },
            ],
            staticClass: "drawer-area",
            class: _vm.drawerClass,
          },
          [
            _c("div", { staticClass: "place-box" }),
            _vm._v(" "),
            _c(
              "draggable",
              _vm._b(
                {
                  staticClass: "field-box",
                  style: _vm.drawerStyle,
                  attrs: { "data-parent": _vm.data.elementId },
                  on: { add: _vm.onFieldAdd },
                  model: {
                    value: _vm.data.field.children,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.field, "children", $$v)
                    },
                    expression: "data.field.children",
                  },
                },
                "draggable",
                _vm.dragOptions,
                false
              ),
              _vm._l(_vm.data.field.children, function (item) {
                return _c("drag-component", {
                  key: item.elementId,
                  attrs: { field: item, "element-id": item.elementId },
                })
              }),
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }