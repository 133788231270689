import "core-js/modules/es6.array.find";
import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
export default {
  data: function data() {
    return {
      tab: 'style',
      form: {
        borderWidth: ''
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['activeComponent', 'storeList'])), {}, {
    currentComponent: function currentComponent() {
      var _this$activeComponent = this.activeComponent.id,
        id = _this$activeComponent === void 0 ? '' : _this$activeComponent;
      return this.storeList.find(function (item) {
        return item.id === id;
      });
    }
  }),
  methods: {
    init: function init() {
      // doing
    }
  }
};