var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "column-setting-wrap" } },
    [
      _c("el-button", { attrs: { size: "mini" }, on: { click: _vm.init } }, [
        _c("i", { staticClass: "iconfont iconshezhi11 handle-icon" }),
        _vm._v(_vm._s(_vm.$t("列表配置")) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            { name: "el-full-dialog", rawName: "v-el-full-dialog" },
            { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
          ],
          staticClass: "column-setting-dialog",
          attrs: {
            title: _vm.$t("列配置"),
            width: "700px",
            visible: _vm.dialogVisible,
            "modal-append-to-body": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            closed: _vm.handleCancel,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "setting-area",
            },
            [
              _c(
                "el-scrollbar",
                { staticClass: "left-box" },
                [
                  _c("div", { staticClass: "title-top" }, [
                    _vm._v(_vm._s(_vm.$t("全部字段"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.onColumnChange },
                      model: {
                        value: _vm.checkList,
                        callback: function ($$v) {
                          _vm.checkList = $$v
                        },
                        expression: "checkList",
                      },
                    },
                    _vm._l(_vm.columnList, function (item, index) {
                      return _c(
                        "el-checkbox",
                        {
                          key: index,
                          attrs: {
                            title: _vm.$t(item.label),
                            disabled: item.default,
                            checked: item.show,
                            label: item.label,
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t(item.label)))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right-box" },
                [
                  _c("div", { staticClass: "title-top" }, [
                    _vm._v(_vm._s(_vm.$t("显示字段"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-scrollbar",
                    { staticClass: "scroll-area" },
                    [
                      _c(
                        "draggable",
                        _vm._b(
                          {
                            staticClass: "column-drag",
                            model: {
                              value: _vm.checkList,
                              callback: function ($$v) {
                                _vm.checkList = $$v
                              },
                              expression: "checkList",
                            },
                          },
                          "draggable",
                          _vm.dragOptions,
                          false
                        ),
                        _vm._l(_vm.checkList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "column-item" },
                            [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(_vm._s(_vm.$t(item))),
                              ]),
                              _vm.isShow(item)
                                ? _c("i", {
                                    staticClass: "el-icon-close",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleHideColumn(index)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", round: "" },
                  on: { click: _vm.handleCancel },
                },
                [_vm._v(_vm._s(_vm.$t("取消")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", round: "", type: "danger" },
                  on: { click: _vm.handleSaveColumn },
                },
                [_vm._v(_vm._s(_vm.$t("保存")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }