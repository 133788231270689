import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper.scss';
import { mapGetters } from 'vuex';
import { parseStyles } from "../../../views/setting/design/form/utils/style";
export default {
  components: {
    Swiper: Swiper,
    SwiperSlide: SwiperSlide
  },
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 20,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['collection'])), {}, {
    styles: function styles() {
      return parseStyles(this.data.field.styles, this.data.field.props);
    }
  }),
  created: function created() {},
  methods: {
    handlePrev: function handlePrev() {
      this.$refs.slide.$swiper.slidePrev();
    },
    handleNext: function handleNext() {
      this.$refs.slide.$swiper.slideNext();
    }
  }
};