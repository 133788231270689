exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".yl-hint-wrap{padding:12px 16px;border-radius:4px;margin:20px 0}.yl-hint-wrap>p{font-size:12px;color:#5e6d82;margin:0}.yl-hint-wrap.primary-hint{background-color:#ecf8ff;border-left:5px solid #50bfff}.yl-hint-wrap.danger-hint{background-color:#ED9393;border-left:5px solid #F56C6C}.yl-hint-wrap.danger-hint p{color:white}.yl-hint-wrap.warning-hint{background-color:#F5B24B;border-left:5px solid #E6A23C}.yl-hint-wrap.warning-hint p{color:white}.yl-hint-wrap.success-hint{background-color:#8BE55F;border-left:5px solid #67C23A}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#E60000",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#fff",
	"menuHover": "#2d325f",
	"subMenuBg": "#222750",
	"subMenuHover": "#1b1e3e",
	"sideBarWidth": "240px"
};