var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      staticClass: "rectangle-menu-warp",
      attrs: { mode: _vm.form, "label-position": "top" },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "样式", name: "style" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "线条宽度" } },
                [
                  _c("el-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      min: 1,
                      size: "small",
                      max: 10,
                      label: "描述文字",
                    },
                    model: {
                      value: _vm.currentComponent.props.borderWidth,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentComponent.props, "borderWidth", $$v)
                      },
                      expression: "currentComponent.props.borderWidth",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }