exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".address-book-item{position:relative}.address-book-item .el-icon-notebook-1{position:absolute;font-size:16px;top:7px;right:10px;color:#909399;cursor:pointer}.address-book-item .el-icon-notebook-1:hover{color:#2568d6 !important}.address-book-item .el-input__inner{padding-right:40px}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#E60000",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#fff",
	"menuHover": "#2d325f",
	"subMenuBg": "#222750",
	"subMenuHover": "#1b1e3e",
	"sideBarWidth": "240px"
};