exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".toggle-collapse-btn{position:absolute;top:0;right:-15px;height:100%;width:15px;background:#f4f5f7 !important;cursor:pointer}.toggle-collapse-btn .btn-area{position:absolute;top:0;left:0;height:100%;width:3px;-webkit-transition:.3s opacity ease;transition:.3s opacity ease;cursor:pointer}.toggle-collapse-btn .btn-area .box{padding-right:3px;color:white;position:absolute;top:50%;font-size:12px;border-radius:2px}.toggle-collapse-btn .btn-area .box .ss-icon{width:13px;height:92px}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#E60000",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#fff",
	"menuHover": "#2d325f",
	"subMenuBg": "#222750",
	"subMenuHover": "#1b1e3e",
	"sideBarWidth": "240px"
};