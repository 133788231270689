//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { parseStyles } from "../utils/style";
export default {
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      value: []
    };
  },
  computed: {
    fieldProps: function fieldProps() {
      return this.data.field.props;
    },
    styles: function styles() {
      return parseStyles(this.data.field.styles, this.data.field.props);
    }
  }
};