exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".resize-area .icontuozhuaidaxiao{font-size:12px}.resize-area .resize-bar{position:absolute;height:100%;width:16px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:#198fff}.resize-area .resize-bar:after{content:'';width:10px;height:10px;background-color:#198fff;border-radius:100%;position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%)}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#E60000",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#fff",
	"menuHover": "#2d325f",
	"subMenuBg": "#222750",
	"subMenuHover": "#1b1e3e",
	"sideBarWidth": "240px"
};