var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "config-table-warp", style: _vm.styles },
    [_c("yl-table", { attrs: { "table-key": _vm.data.field.props.tableKey } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }