var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "login-wrap" } },
    [
      _c("div", { staticClass: "lg-top" }, [
        _c("div", { staticClass: "logo_introduce" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "lg-right" },
          [
            _c(
              "el-select",
              {
                staticClass: "lm-i18n",
                attrs: {
                  "popper-class": "login-i18n",
                  "popper-append-to-body": false,
                },
                on: { change: _vm.i18nChange },
                model: {
                  value: _vm.i18nValue,
                  callback: function ($$v) {
                    _vm.i18nValue = $$v
                  },
                  expression: "i18nValue",
                },
              },
              _vm._l(_vm.langList, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { value: item.value, label: _vm.$t(item.label) },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "login-box" },
              [
                _c("img", {
                  staticClass: "title",
                  attrs: { src: require("@/assets/images/jtLogo.png") },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "login-type-list" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("欢迎您使用VIP平台")) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _vm.privacyTip
                  ? _c(
                      "div",
                      { staticClass: "tips" },
                      [
                        _c("el-collapse-transition", [
                          _c(
                            "div",
                            { staticClass: "tips-pri" },
                            [
                              _c("svg-icon", {
                                staticClass: "tip-icon",
                                attrs: { "icon-class": "tip-privacy" },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "transition-box" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("请阅读并同意《极兔国际隐私政策》")
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "loginForm",
                    staticClass: "login-area",
                    attrs: {
                      model: _vm.loginForm,
                      rules: _vm.loginRules,
                      "label-position": "left",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "loginCode" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: _vm.$t("请输入用户名称"),
                              type: "text",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.loginForm.loginCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.loginForm,
                                  "loginCode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "loginForm.loginCode",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "icon el-icon-user",
                              attrs: { slot: "prefix" },
                              slot: "prefix",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "loginPassword" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: _vm.$t("请输入用户密码"),
                              type: _vm.isShowPassword ? "text" : "password",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.loginForm.loginPassword,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.loginForm,
                                  "loginPassword",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "loginForm.loginPassword",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "icon el-icon-lock",
                              attrs: { slot: "prefix" },
                              slot: "prefix",
                            }),
                            _vm._v(" "),
                            _c("template", { slot: "suffix" }, [
                              _c(
                                "div",
                                { staticStyle: { position: "relative" } },
                                [
                                  _c("svg-icon", {
                                    staticClass: "icon-password",
                                    attrs: {
                                      "icon-class": _vm.isShowPassword
                                        ? "eye-open"
                                        : "eye",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.toggleIcon($event)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "code-block" },
                      [
                        _c(
                          "code-thumb",
                          { ref: "codeImg", on: { reset: _vm.onImgCodeReset } },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "reset" }, slot: "reset" },
                              [
                                _c("i", {
                                  staticClass: "el-icon-refresh-left fs-16",
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("img", {
                              attrs: { src: _vm.imgData, alt: "v-code" },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "img-tip" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("请依次按顺序点击"))
                          ),
                          _c("span", { staticClass: "code-tip" }, [
                            _vm._v(_vm._s(_vm.imgTip)),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "check-area" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: { indeterminate: false },
                            on: { change: _vm.changePrivacy },
                            model: {
                              value: _vm.loginForm.privacyFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginForm, "privacyFlag", $$v)
                              },
                              expression: "loginForm.privacyFlag",
                            },
                          },
                          [
                            _vm._v(_vm._s(_vm.$t("阅读并同意")) + " "),
                            _c(
                              "span",
                              {
                                staticClass: "privacy",
                                on: { click: _vm.goPrivacy },
                              },
                              [_vm._v(_vm._s(_vm.$t("《极兔国际隐私政策》")))]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-checkbox",
                          {
                            attrs: { indeterminate: false },
                            model: {
                              value: _vm.loginForm.rememberPsw,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginForm, "rememberPsw", $$v)
                              },
                              expression: "loginForm.rememberPsw",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("记住密码")))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "submit-btn",
                        staticStyle: { "line-height": "initial" },
                        attrs: { loading: _vm.loading },
                        on: { click: _vm.handleLogin },
                      },
                      [_vm._v(_vm._s(_vm.$t("登录")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-carousel",
        {
          attrs: {
            height: "100%",
            "initial-index": _vm.carouselActive,
            trigger: "click",
            interval: 6000,
          },
          on: {
            change: function (e) {
              return (_vm.carouselActive = e)
            },
          },
        },
        _vm._l(_vm.carouselList, function (item) {
          return _c("el-carousel-item", { key: item.key }, [
            _c("img", {
              key: item.key,
              staticStyle: { "object-fit": "cover" },
              attrs: { src: item.src, alt: "", width: "100%", height: "100%" },
            }),
          ])
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _vm._v(
          "Copyright © 2019 - " +
            _vm._s(_vm.tomorrowYear) +
            " 云路科技.All Rights Reserved"
        ),
      ]),
      _vm._v(" "),
      _c("login-dialog", {
        ref: "login-dialog",
        attrs: { data: _vm.loginData },
      }),
      _vm._v(" "),
      _c("check-code-dialog", {
        ref: "check",
        on: { success: _vm.loginSuccess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }