var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.parentType !== "form"
    ? _c(
        "div",
        { staticClass: "native-field-warp", style: _vm.styles },
        [
          _c("div", { staticClass: "field-label" }, [
            _vm._v("\n    " + _vm._s(_vm.data.field.name) + "\n  "),
          ]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              class: _vm.data.field.props.className,
              attrs: {
                placeholder: _vm.data.field.props.placeholder,
                clearable: _vm.data.field.props.clearable,
                filterable: _vm.data.field.props.filterable,
              },
              model: {
                value: _vm.data.field.value,
                callback: function ($$v) {
                  _vm.$set(_vm.data.field, "value", $$v)
                },
                expression: "data.field.value",
              },
            },
            _vm._l(_vm.data.field.options, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      )
    : _c(
        "el-form-item",
        { style: _vm.styles, attrs: { label: _vm.data.field.name } },
        [
          _c(
            "el-select",
            {
              class: _vm.data.field.props.className,
              attrs: {
                placeholder: _vm.data.field.props.placeholder,
                clearable: _vm.data.field.props.clearable,
                filterable: _vm.data.field.props.filterable,
              },
              model: {
                value: _vm.data.field.value,
                callback: function ($$v) {
                  _vm.$set(_vm.data.field, "value", $$v)
                },
                expression: "data.field.value",
              },
            },
            _vm._l(_vm.data.field.options, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }