import "core-js/modules/es6.array.find";
import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { debounce } from 'throttle-debounce';
export default {
  data: function data() {
    return {
      tab: 'content',
      form: {},
      debounceUpdate: Function,
      formatOptions: [{
        label: 'CODE128',
        value: 'CODE128'
      }, {
        label: 'CODE128A',
        value: 'CODE128A'
      }, {
        label: 'CODE128B',
        value: 'CODE128B'
      }, {
        label: 'CODE128C',
        value: 'CODE128C'
      }, {
        label: 'Pharmacode',
        value: 'pharmacode'
      }],
      selectOptions: [{
        label: '是',
        value: '1'
      }, {
        label: '否',
        value: '0'
      }],
      fontSizeOptions: [{
        label: '12px',
        value: 12
      }, {
        label: '14px',
        value: 14
      }, {
        label: '16px',
        value: 16
      }, {
        label: '18px',
        value: 18
      }, {
        label: '24px',
        value: 24
      }, {
        label: '30px',
        value: 30
      }, {
        label: '36px',
        value: 36
      }]
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['activeComponent', 'storeList'])), {}, {
    currentComponent: function currentComponent() {
      var _this$activeComponent = this.activeComponent.id,
        id = _this$activeComponent === void 0 ? '' : _this$activeComponent;
      return this.storeList.find(function (item) {
        return item.id === id;
      });
    }
  }),
  mounted: function mounted() {
    this.debounceUpdate = debounce(300, this.handleUpdateBarcode);
  },
  methods: {
    init: function init() {
      // doing
    },
    handleUpdateBarcode: function handleUpdateBarcode() {
      this.$store.dispatch('components/updateBarcode');
    }
  }
};