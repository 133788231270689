import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layout';
import settings from '@/settings';
Vue.use(Router);
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  name: 'login',
  component: settings.loginCom,
  hidden: true,
  meta: {
    title: '登录'
  }
}, {
  path: '/privacyPolicy',
  component: function component() {
    return import('@/views/login/privacyPolicy');
  },
  hidden: true,
  meta: {
    title: '隐私政策'
  }
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/authredirect');
  },
  hidden: true
}, {
  path: '/error',
  name: 'Error',
  component: Layout,
  hidden: true,
  children: [{
    path: '404',
    name: 'Error404',
    component: function component() {
      return import('@/views/errorPage/404');
    },
    meta: {
      title: '页面未找到'
    }
  }, {
    path: '401',
    component: function component() {
      return import('@/views/errorPage/401');
    },
    name: 'Error401',
    meta: {
      title: '无权限访问'
    }
  }]
}, {
  path: '/test',
  name: 'Test',
  component: function component() {
    return import('@/views/test/index.vue');
  },
  hidden: true,
  meta: {
    title: '草稿纸'
  }
}, {
  path: '/preview',
  name: 'Preview',
  component: function component() {
    return import('@/views/setting/design/preview/index.vue');
  },
  hidden: true,
  meta: {
    title: '设计预览'
  }
}, {
  path: '/design',
  name: 'Design',
  component: function component() {
    return import('@/views/setting/design/home/index.vue');
  },
  hidden: true,
  meta: {
    title: '云路设计'
  },
  children: [{
    path: 'edit',
    name: 'DesignEdit',
    component: function component() {
      return import('@/views/setting/design/lowCode/index.vue');
    },
    meta: {
      title: '页面设计'
    }
  }, {
    path: 'template',
    name: 'DesignTemplate',
    component: function component() {
      return import('@/views/setting/design/template/index.vue');
    },
    meta: {
      title: '设计模板'
    }
  }]
}, {
  path: '/customerInquiry',
  name: 'CustomerInquiry',
  component: function component() {
    return import('@/views/CustomerInfo/CustomerInquiry/index');
  },
  meta: {
    title: '客户信息查询'
  }
}, {
  path: '/customerInfoInquiry',
  name: 'CustomerInfoInquiry',
  component: function component() {
    return import('@/views/CustomerInfo/CustomerInfoInquiry/index');
  },
  meta: {
    title: '客户信息查询'
  }
}];
var createRouter = function createRouter() {
  return new Router({
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher;
}
// function formatRoutes(routes) {
//   const fmRoutes = []
//   routes.forEach(router => {
//     if (router.component) {
//       const component = router.component
//       router.component = resolve => {
//         require(['@/' + component + '.vue'], resolve)
//       }
//     } else if (router.template) {
//       router.component = resolve => {
//         resolve({
//           template: router.template
//         })
//       }
//     } else {
// 如果没有指定component路径并且没有设置template，尝试加载默认路径位置组件或用默认页面
//   const component = `${router.name}/index`
//   router.component = async resolve => {
//     try {
//       // 尝试加载模块
//       await require(['@/views/' + component + '.vue'], resolve)
//     } catch {
//       // 加载失败，不存在此模块，使用默认模板
//       console.log('@/views/' + component + '.vue不存在，加载默认模板')
//       resolve({
//         template: `<table-base table-name="${router.name}" />`
//       })
//     }
//   }
// }

//     let children = router.children
//     if (children && children instanceof Array) {
//       children = formatRoutes(children)
//     }
//     router.children = children
//     fmRoutes.push(router)
//   })
//   return fmRoutes
// }
export default router;