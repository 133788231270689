var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tags-view-container",
      attrs: { id: "tags-view-container" },
    },
    [
      _c(
        "yl-scroll-pane",
        {
          ref: "scrollPane",
          staticClass: "tags-view-wrapper",
          attrs: { "active-class": "active" },
        },
        _vm._l(_vm.visitedViews, function (tag, index) {
          return _c(
            "el-popover",
            {
              key: index,
              attrs: {
                "popper-class": "tags-view-popper",
                transition: "none",
                "close-delay": 0,
                trigger: "hover",
              },
            },
            [
              _c(
                "div",
                _vm._l(tag.matched, function (item, itemIndex) {
                  return _c(
                    "span",
                    { key: item.name, staticClass: "info-color fs-12" },
                    [
                      itemIndex === 0
                        ? _c("i", { staticClass: "el-icon-location" })
                        : _vm._e(),
                      _vm._v(
                        " " + _vm._s(_vm.$t(item.meta.title + "_TITLE")) + " "
                      ),
                      itemIndex + 1 !== tag.matched.length
                        ? _c("i", { staticClass: "el-icon-right" })
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  key: index,
                  ref: "tag",
                  refInFor: true,
                  staticClass: "tags-view-item",
                  class: _vm.isActive(tag) ? "active" : "",
                  attrs: {
                    slot: "reference",
                    to: {
                      path: tag.path,
                      query: tag.query,
                      fullPath: tag.fullPath,
                    },
                    tag: "div",
                  },
                  nativeOn: {
                    mouseup: function ($event) {
                      if ("button" in $event && $event.button !== 1) {
                        return null
                      }
                      return _vm.closeSelectedTag(tag)
                    },
                    contextmenu: function ($event) {
                      $event.preventDefault()
                      return _vm.openMenu(tag, $event)
                    },
                  },
                  slot: "reference",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t(tag.title + "_TITLE")) +
                      "\n          "
                  ),
                  tag.meta.title !== "首页看板"
                    ? _c("img", {
                        staticClass: "el-icon-close",
                        attrs: {
                          src: require("@/assets/images/view-close.png"),
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.closeSelectedTag(tag)
                          },
                        },
                      })
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          staticClass: "contextmenu",
          style: { left: _vm.left + "px", top: _vm.top + "px" },
        },
        [
          _c(
            "li",
            {
              on: {
                click: function ($event) {
                  return _vm.refreshSelectedTag(_vm.selectedTag)
                },
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("刷新")) + "\n      ")]
          ),
          _vm._v(" "),
          !(_vm.selectedTag.meta && _vm.selectedTag.meta.affix)
            ? _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeSelectedTag(_vm.selectedTag)
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("关闭当前")) + "\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("li", { on: { click: _vm.closeOthersTags } }, [
            _vm._v("\n        " + _vm._s(_vm.$t("关闭其他")) + "\n      "),
          ]),
          _vm._v(" "),
          _c(
            "li",
            {
              on: {
                click: function ($event) {
                  return _vm.closeAllTags(_vm.selectedTag)
                },
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("关闭全部")) + "\n      ")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }