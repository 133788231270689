import "core-js/modules/es6.array.find";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import draggable from 'vuedraggable';
import { mapGetters, mapMutations } from 'vuex';
import { formatFormItem, removeAllNonItem } from "../utils/global";
import _ from 'loadsh';
import { parseStyles } from "../utils/style";
export default {
  components: {
    draggable: draggable
  },
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['collection'])), {}, {
    dragOptions: function dragOptions() {
      return {
        animation: 0,
        group: 'component',
        handle: '.move-bar'
      };
    },
    styles: function styles() {
      return parseStyles(this.data.field.styles, this.data.field.props);
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapMutations({
    addFormItemToGrid: 'ADD_FORM_ITEM_TO_GRID',
    setCurrentFormItem: 'SET_CURRENT_FORM_ITEM'
  })), {}, {
    getColComponentData: function getColComponentData(data) {
      return {
        props: {
          span: data.span
        }
      };
    },
    onGridAddField: function onGridAddField(data) {
      removeAllNonItem();
      var isSort = data.item.className.includes('form-drag-warp');
      if (isSort) return;
      var id = data.item.getAttribute('field-id');
      var gridId = data.to.getAttribute('data-id');
      var parentId = data.to.getAttribute('data-parent');
      var field = formatFormItem(_.cloneDeep(this.collection.find(function (item) {
        return item.id === id;
      })));
      field.parentId = gridId;
      field.parentType = 'grid';
      var obj = {
        parentId: parentId,
        gridId: gridId,
        field: field,
        index: data.newIndex
      };
      this.addFormItemToGrid(obj);
    },
    handleFieldClick: function handleFieldClick(current) {
      this.setCurrentFormItem(current);
    },
    onClick: function onClick() {
      this.setCurrentFormItem(this.data);
    }
  })
};