import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
export default {
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapState({
    collapse: function collapse(state) {
      return state.settings.collapse;
    }
  })),
  methods: {
    handleToggleCollapse: function handleToggleCollapse() {
      this.$store.dispatch('settings/changeSetting', {
        key: 'collapse',
        value: !this.collapse
      });
    }
  }
};