//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    styles: function styles() {
      var _this$data$field$styl = this.data.field.styles,
        marginTop = _this$data$field$styl.marginTop,
        marginBottom = _this$data$field$styl.marginBottom,
        marginLeft = _this$data$field$styl.marginLeft,
        marginRight = _this$data$field$styl.marginRight,
        paddingTop = _this$data$field$styl.paddingTop,
        paddingBottom = _this$data$field$styl.paddingBottom,
        paddingLeft = _this$data$field$styl.paddingLeft,
        paddingRight = _this$data$field$styl.paddingRight,
        display = _this$data$field$styl.display,
        flexWrap = _this$data$field$styl.flexWrap,
        flexDirection = _this$data$field$styl.flexDirection,
        justifyContent = _this$data$field$styl.justifyContent,
        alignItems = _this$data$field$styl.alignItems;
      return {
        marginTop: "".concat(marginTop, "px"),
        marginBottom: "".concat(marginBottom, "px"),
        marginLeft: "".concat(marginLeft, "px"),
        marginRight: "".concat(marginRight, "px"),
        paddingTop: "".concat(paddingTop, "px"),
        paddingBottom: "".concat(paddingBottom, "px"),
        paddingLeft: "".concat(paddingLeft, "px"),
        paddingRight: "".concat(paddingRight, "px"),
        flexWrap: flexWrap,
        flexDirection: flexDirection,
        justifyContent: justifyContent,
        alignItems: alignItems,
        display: display
      };
    }
  }
};