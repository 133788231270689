import fetch from '@/ylCommon/utils/request';
var statistical = {
  getCountGoodsState: function getCountGoodsState(options) {
    var params = {
      parameter: options
    };
    return fetch({
      url: '/b2c/getCountGoodsState.do',
      data: params,
      method: 'post'
    });
  }
};
export default statistical;