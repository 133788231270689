import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { HeaderNav, AsideMenu, AppMain, TagsView } from "./components";
import { mapState } from 'vuex';
import searchModule from '@/ylCommon/components/SearchModule/index.vue';
import collapseBtn from '@/layout/components/CollapseBtn/index.vue';
export default {
  name: 'Layout',
  components: {
    HeaderNav: HeaderNav,
    AsideMenu: AsideMenu,
    AppMain: AppMain,
    TagsView: TagsView,
    searchModule: searchModule,
    collapseBtn: collapseBtn
  },
  data: function data() {
    return {
      asideMenuVisible: true,
      isOverflowVisible: true
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    userMenu: function userMenu(state) {
      return state.user.userMenu;
    },
    activeModule: function activeModule(state) {
      return state.user.activeModule;
    },
    collapse: function collapse(state) {
      return state.settings.collapse;
    }
  })), {}, {
    isHome: function isHome() {
      return this.activeModule && this.activeModule.path === '';
    },
    collapseBtnVisible: function collapseBtnVisible() {
      return this.activeModule && !this.isHome;
    },
    searchModuleVisible: function searchModuleVisible() {
      return !this.collapse && this.activeModule && !this.isHome;
    },
    asideWidth: function asideWidth() {
      return this.activeModule ? this.isHome ? '0' : this.collapse ? '0' : '280px' : '240px';
    },
    getAsideStyle: function getAsideStyle() {
      return {
        overflow: this.isOverflowVisible ? 'visible' : 'hidden'
      };
    }
  }),
  watch: {
    // 优化菜单渐入渐出效果
    // collapse(value) {
    //   if (!value) {
    //     setTimeout(() => {
    //       this.asideMenuVisible = true
    //     }, 200)
    //   } else {
    //     this.asideMenuVisible = false
    //     this.isOverflowVisible = false
    //     setTimeout(() => {
    //       this.isOverflowVisible = true
    //     }, 250)
    //   }
    // }
  },
  mounted: function mounted() {},
  methods: {}
};