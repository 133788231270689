import fetch from "../ylCommon/utils/request";
var permission = {
  queryRoute: function queryRoute(params) {
    return fetch({
      url: '/setting/getRouteSetting.do',
      data: params
    });
  },
  addRoute: function addRoute(params) {
    return fetch({
      url: '/setting/saveRouteSetting.do',
      data: params
    });
  },
  removeRoute: function removeRoute(params) {
    return fetch({
      url: '/setting/deleteRouteSetting.do',
      data: params
    });
  },
  updateRoute: function updateRoute(params) {
    return fetch({
      url: '/setting/updateRouteSetting.do',
      data: params
    });
  },
  queryFuncMenu: function queryFuncMenu(params) {
    return fetch({
      url: '/setting/getFunc.do',
      data: params
    });
  },
  removeFuncMenu: function removeFuncMenu(params) {
    return fetch({
      url: '/setting/deleteFunc.do',
      data: params
    });
  },
  addFuncMenu: function addFuncMenu(params) {
    return fetch({
      url: '/setting/saveFunc.do',
      data: params
    });
  },
  updateFuncMenu: function updateFuncMenu(params) {
    return fetch({
      url: '/setting/updateFunc.do',
      data: params
    });
  },
  // 创建默认角色
  createDefaultRoles: function createDefaultRoles() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return fetch({
      url: '/setting/createDefaultRoles.do',
      data: params
    });
  },
  addRole: function addRole(params) {
    return fetch({
      url: '/setting/saveRoles.do',
      data: params
    });
  },
  removeRole: function removeRole(params) {
    return fetch({
      url: '/setting/deleteRoles.do',
      data: params
    });
  },
  getRoleMenu: function getRoleMenu(params) {
    return fetch({
      url: '/setting/getRoleFuncs.do',
      data: params
    });
  },
  saveRoleMenu: function saveRoleMenu(params) {
    return fetch({
      url: '/setting/saveRoleFuncs.do',
      data: params
    });
  },
  queryRoles: function queryRoles(params) {
    return fetch({
      url: '/setting/getRoles.do',
      data: params
    });
  },
  addUser: function addUser(params) {
    return fetch({
      url: '/common/saveUser.do',
      data: params
    });
  },
  /* 查询个人权限 */queryRolesPersonal: function queryRolesPersonal(params) {
    return fetch({
      url: '/setting/getDisplayPage.do',
      data: params
    });
  },
  /* 保存个人权限 */saveRolesPersonal: function saveRolesPersonal(params) {
    return fetch({
      url: '/setting/updateDisplayPage.do',
      data: params
    });
  },
  updateUser: function updateUser(params) {
    return fetch({
      url: '/common/updateUser.do',
      data: params
    });
  },
  editUserPassword: function editUserPassword(params) {
    return fetch({
      url: '/common/updateUserPwd.do',
      data: params
    });
  },
  updateUserPwd: function updateUserPwd(params) {
    return fetch({
      url: '/common/updateUserPwd.do',
      data: params
    });
  },
  getNextCode: function getNextCode(params) {
    return fetch({
      url: '/common/getNextCode.do',
      data: params
    });
  },
  removeUser: function removeUser(params) {
    return fetch({
      url: '/common/deleteUser.do',
      data: params
    });
  },
  updateFunSort: function updateFunSort(params) {
    return fetch({
      url: '/setting/updateFuncSort.do',
      data: params
    });
  },
  getRegular: function getRegular(params) {
    return fetch({
      url: '/getRegular.do',
      data: {
        parameter: params
      }
    });
  },
  saveRegular: function saveRegular(params) {
    return fetch({
      url: '/setting/saveRegular.do',
      data: {
        parameter: params
      }
    });
  },
  deleteRegular: function deleteRegular(params) {
    return fetch({
      url: '/setting/deleteRegular.do',
      data: {
        parameter: params
      }
    });
  },
  updateRegular: function updateRegular(params) {
    return fetch({
      url: '/setting/updateRegular.do',
      data: {
        parameter: params
      }
    });
  },
  getImgCode: function getImgCode(params) {
    return fetch({
      url: '/getImgCode.do',
      data: {
        parameter: params
      }
    });
  }
};
export default permission;