/**
 * 网页加水印
 *
 * @export
 * @param {*} [{
 *   container = document.body,
 *   width = '400px',
 *   height = '300px',
 *   textAlign = 'center',
 *   textBaseline = 'middle',
 *   font = "20px Microsoft Yahei",
 *   fillStyle = 'rgba(230, 230, 230, 0.8)',
 *   content = '保密水印',
 *   rotate = '10',
 *   zIndex = -1000
 * }={}]
 * @returns
 */
import store from '@/store';
export function waterDocument() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$container = _ref.container,
    container = _ref$container === void 0 ? document.body : _ref$container,
    _ref$width = _ref.width,
    width = _ref$width === void 0 ? '200px' : _ref$width,
    _ref$height = _ref.height,
    height = _ref$height === void 0 ? '150px' : _ref$height,
    _ref$textAlign = _ref.textAlign,
    textAlign = _ref$textAlign === void 0 ? 'center' : _ref$textAlign,
    _ref$textBaseline = _ref.textBaseline,
    textBaseline = _ref$textBaseline === void 0 ? 'middle' : _ref$textBaseline,
    _ref$font = _ref.font,
    font = _ref$font === void 0 ? '14px Microsoft Yahei' : _ref$font,
    _ref$fillStyle = _ref.fillStyle,
    fillStyle = _ref$fillStyle === void 0 ? 'rgba(230, 230, 230, 0.6)' : _ref$fillStyle,
    _ref$content = _ref.content,
    content = _ref$content === void 0 ? store.state.user.cusName : _ref$content,
    _ref$rotate = _ref.rotate,
    rotate = _ref$rotate === void 0 ? '30' : _ref$rotate,
    _ref$zIndex = _ref.zIndex,
    zIndex = _ref$zIndex === void 0 ? 1000 : _ref$zIndex;
  // const args = arguments[0]
  var canvas = document.createElement('canvas');
  canvas.setAttribute('width', width);
  canvas.setAttribute('height', height);
  var ctx = canvas.getContext('2d');
  if (ctx === null) {
    console.error('this browser is not support canvas.');
    return;
  }
  ctx.textAlign = textAlign;
  ctx.textBaseline = textBaseline;
  ctx.font = font;
  ctx.fillStyle = fillStyle;
  ctx.rotate(Math.PI / 180 * rotate);
  ctx.fillText(content, parseFloat(width) / 2, parseFloat(height) / 2);
  var base64Url = canvas.toDataURL();
  var __wm = document.querySelector('.__wm');
  var watermarkDiv = __wm || document.createElement('div');
  var styleStr = "\n      position:absolute;\n      top:0;\n      left:0;\n      width:100%;\n      height:100%;\n      z-index:".concat(zIndex, ";\n      pointer-events:none;\n      background-repeat:repeat;\n      background-image:url('").concat(base64Url, "')");
  watermarkDiv.setAttribute('style', styleStr);
  watermarkDiv.classList.add('__wm');
  if (!__wm) {
    container.style.position = 'relative';
    container.insertBefore(watermarkDiv, container.firstChild);
  }
  var MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
  if (MutationObserver) {
    var mo = new MutationObserver(function () {
      var __wm = document.querySelector('.__wm');
      // 只在__wm元素变动才重新调用 waterDocument
      if (__wm && __wm.getAttribute('style') !== styleStr || !__wm) {
        // 避免一直触发
        mo.disconnect();
        mo = null;
        // waterDocument(JSON.parse(JSON.stringify(args)))
        // waterDocument(JSON.parse(args))
      }
    });

    mo.observe(container, {
      attributes: true,
      subtree: true,
      childList: true
    });
  }
}
export function clearWater() {
  var watermarkDiv = document.querySelector('.__wm');
  document.body.removeChild(watermarkDiv);
}