export function parseStyles(styles, props) {
  var marginTop = styles.marginTop,
    marginLeft = styles.marginLeft,
    marginBottom = styles.marginBottom,
    marginRight = styles.marginRight,
    paddingTop = styles.paddingTop,
    paddingBottom = styles.paddingBottom,
    paddingLeft = styles.paddingLeft,
    paddingRight = styles.paddingRight,
    display = styles.display,
    flexDirection = styles.flexDirection,
    alignItems = styles.alignItems,
    justifyContent = styles.justifyContent,
    flexWrap = styles.flexWrap,
    fontSize = styles.fontSize,
    fontWeight = styles.fontWeight,
    lineHeight = styles.lineHeight,
    color = styles.color,
    textAlign = styles.textAlign,
    opacity = styles.opacity,
    position = styles.position,
    top = styles.top,
    bottom = styles.bottom,
    left = styles.left,
    right = styles.right,
    zIndex = styles.zIndex,
    clear = styles.clear,
    backgroundColor = styles.backgroundColor,
    backgroundImage = styles.backgroundImage,
    backgroundSize = styles.backgroundSize,
    backgroundPosition = styles.backgroundPosition,
    backgroundRepeat = styles.backgroundRepeat,
    backgroundPositionX = styles.backgroundPositionX,
    backgroundPositionY = styles.backgroundPositionY,
    borderRadius = styles.borderRadius,
    borderWidth = styles.borderWidth,
    borderStyle = styles.borderStyle,
    borderColor = styles.borderColor,
    borderTopLeftRadius = styles.borderTopLeftRadius,
    borderTopRightRadius = styles.borderTopRightRadius,
    borderBottomLeftRadius = styles.borderBottomLeftRadius,
    borderBottomRightRadius = styles.borderBottomRightRadius,
    boxShadowColor = styles.boxShadowColor,
    boxShadowX = styles.boxShadowX,
    boxShadowY = styles.boxShadowY,
    boxShadowBlur = styles.boxShadowBlur,
    boxShadowExtend = styles.boxShadowExtend,
    width = styles.width,
    height = styles.height;
  var backgroundType = props.backgroundType,
    borderRadiusType = props.borderRadiusType,
    borderType = props.borderType,
    boxShadowType = props.boxShadowType;
  var result = {
    marginTop: isNaN(marginTop) ? marginTop : "".concat(marginTop, "px"),
    marginBottom: isNaN(marginBottom) ? marginBottom : "".concat(marginBottom, "px"),
    marginLeft: isNaN(marginLeft) ? marginLeft : "".concat(marginLeft, "px"),
    marginRight: isNaN(marginRight) ? marginRight : "".concat(marginRight, "px"),
    paddingTop: "".concat(paddingTop, "px"),
    paddingBottom: "".concat(paddingBottom, "px"),
    paddingLeft: "".concat(paddingLeft, "px"),
    paddingRight: "".concat(paddingRight, "px"),
    display: display,
    flexDirection: flexDirection,
    alignItems: alignItems,
    justifyContent: justifyContent,
    flexWrap: flexWrap,
    fontSize: "".concat(fontSize, "px"),
    opacity: opacity ? opacity / 100 : '',
    fontWeight: fontWeight,
    lineHeight: lineHeight,
    color: color,
    textAlign: textAlign,
    position: position,
    top: "".concat(top, "px"),
    bottom: "".concat(bottom, "px"),
    left: "".concat(left, "px"),
    right: "".concat(right, "px"),
    zIndex: zIndex,
    clear: clear,
    backgroundSize: backgroundSize,
    backgroundPosition: backgroundPosition,
    backgroundRepeat: backgroundRepeat,
    backgroundPositionX: backgroundPositionX,
    backgroundPositionY: backgroundPositionY,
    width: width,
    height: height
  };
  var boxShadow = "".concat(boxShadowColor, " ").concat(boxShadowX, "px ").concat(boxShadowY, "px ").concat(boxShadowBlur, "px ").concat(boxShadowExtend, "px ").concat(boxShadowType === 'inset' ? 'inset' : '');
  if (backgroundType === 'color') result.backgroundColor = backgroundColor;else if (backgroundType === 'img') result.backgroundImage = "url(".concat(backgroundImage, ")");
  if (borderType) result[borderType] = "".concat(borderWidth, "px ").concat(borderStyle, " ").concat(borderColor);
  if (borderRadiusType === 'custom') {
    result.borderTopLeftRadius = "".concat(borderTopLeftRadius, "px");
    result.borderTopRightRadius = "".concat(borderTopRightRadius, "px");
    result.borderBottomRightRadius = "".concat(borderBottomRightRadius, "px");
    result.borderBottomLeftRadius = "".concat(borderBottomLeftRadius, "px");
  } else if (borderRadiusType === 'fixed') {
    result.borderRadius = "".concat(borderRadius, "px");
  }
  if (boxShadowType) {
    result.boxShadow = boxShadow;
  }
  return result;
}