//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { parseStyles } from "../../../views/setting/design/form/utils/style";
import { generateEvents, transformFun } from "../../../views/setting/design/form/utils/event";
export default {
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      value: '',
      events: {
        onChange: '',
        onBlur: '',
        onFocus: ''
      }
    };
  },
  computed: {
    styles: function styles() {
      return parseStyles(this.data.field.styles, this.data.field.props);
    }
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      this.events = generateEvents(this.data.field.events);
    },
    onChange: function onChange() {
      var _this = this;
      var onChange = this.events.onChange;
      if (onChange) {
        transformFun(onChange.event, onChange.eventName, null, this.context, function (result) {
          result && _this.$emit('input', {
            key: _this.data.field.paramsKey,
            value: result || _this.value
          });
        });
      }
    },
    onBlur: function onBlur() {
      var onBlur = this.events.onBlur;
      if (onBlur) {
        transformFun(onBlur.event, onBlur.eventName, null, this.context);
      }
    },
    onFocus: function onFocus() {
      var onFocus = this.events.onFocus;
      if (onFocus) {
        transformFun(onFocus.event, onFocus.eventName, null, this.context);
      }
    }
  }
};