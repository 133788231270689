import { render, staticRenderFns } from "./textarea.vue?vue&type=template&id=42878dcd&"
import script from "./textarea.vue?vue&type=script&lang=js&"
export * from "./textarea.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('42878dcd')) {
      api.createRecord('42878dcd', component.options)
    } else {
      api.reload('42878dcd', component.options)
    }
    module.hot.accept("./textarea.vue?vue&type=template&id=42878dcd&", function () {
      api.rerender('42878dcd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/setting/design/lowCode/components/textarea.vue"
export default component.exports