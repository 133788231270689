import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/es6.regexp.match";
import { Notification } from 'element-ui';
import * as Babel from '@babel/standalone';
function checkoutFuncName(funcString) {
  var reg = /(?<=(exports))(\.|\w)+/g;
  var arr = funcString.match(reg) || [];
  return arr.map(function (item) {
    return item.substring(1, item.length);
  });
}
function parseFuncBody(funString) {
  var result;
  try {
    result = Babel.transform(funString, {
      presets: ['es2015']
    }).code;
  } catch (e) {
    Notification({
      type: 'warning',
      title: '函数解析失败',
      message: e,
      duration: 1000
    });
  }
  return result;
}
export function transformFun(funString, trigger, value, context, callback) {
  var result;
  var args = 'context, value';
  var body = "".concat(funString, " return ").concat(trigger, "()");
  var create = new Function(args, body);
  if (funString) result = create(context, value);
  callback && callback(result);
  return result;
}
export function getTransformFun(funString, trigger, value, context, callback) {
  var result;
  var args = 'context, value';
  var body = "".concat(funString, " return ").concat(trigger);
  var create = new Function(args, body);
  if (funString) result = create(context, value);
  callback && callback(result);
  return result;
}
export function parseFuncInstance(funString) {
  var result = [];
  var translateCode = parseFuncBody(funString);
  var funcName = checkoutFuncName(translateCode);
  var funcBody = funcName.map(function (item, index) {
    var startFlag = "function ".concat(item, "() {");
    var endFlag = "function ".concat(funcName[index + 1], "() {");
    var start = translateCode.indexOf(startFlag);
    var end = translateCode.indexOf(endFlag);
    return translateCode.substring(start, end < 0 ? translateCode.length : end);
  });
  funcName.map(function (item, index) {
    var method = {
      funcName: item,
      funcBody: funcBody[index],
      type: 'function'
    };
    result.push(method);
  });
  return result;
}
export function generateOpenUrlFunc(trigger, url, target) {
  return "function ".concat(trigger, "() {\n    window.open(\"").concat(url, "\", \"").concat(target, "\")\n  }");
}
export function generateEvents(events) {
  if (!events) return;
  var result = {};
  events.map(function (item) {
    result[item.trigger] = _objectSpread({}, item);
  });
  return result;
}