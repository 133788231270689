import "core-js/modules/es6.regexp.split";
import zhData from '@/lang/zh.js';
import i18n from '@/lang/index.js';
export default {
  install: function install(Vue, options) {
    Vue.prototype.$Tools = {
      $t: function $t(field) {
        var keys = null;
        var parentKey = null;
        var result = null;
        for (var key in zhData) {
          var childData = zhData[key];
          if (zhData[key] === field) {
            result = key;
          } else {
            for (var childKey in childData) {
              if (childData[childKey] === field) {
                parentKey = key;
                keys = childKey;
                result = "".concat(parentKey, ".").concat(keys);
              }
            }
          }
        }
        return i18n.t("".concat(result));
      },
      getLangKey: function getLangKey(field) {
        var keys = null;
        var parentKey = null;
        var result = null;
        for (var key in zhData) {
          var childData = zhData[key];
          if (zhData[key] === field) {
            result = key;
          } else {
            for (var childKey in childData) {
              if (childData[childKey] === field) {
                parentKey = key;
                keys = childKey;
                result = "".concat(parentKey, ".").concat(keys);
              }
            }
          }
        }
        return result;
      },
      dateFormat: function dateFormat(params) {
        var addZero = function addZero(obj) {
          return obj < 10 ? '0' + obj : obj;
        };
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var second = date.getSeconds();
        var arr = params.split('');
        var result = '';
        for (var i = 0; i < arr.length; i += 2) {
          var tem = arr[i + 1] === undefined ? '' : arr[i + 1];
          switch (arr[i]) {
            case 'y':
              result += addZero(year) + tem;
              break;
            case 'M':
              result += addZero(month) + tem;
              break;
            case 'd':
              result += addZero(day) + tem;
              break;
            case 'h':
              result += addZero(hour) + tem;
              break;
            case 'm':
              result += addZero(minute) + tem;
              break;
            case 's':
              result += addZero(second);
              break;
          }
        }
        return result;
      }
    };
  }
};