exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".update-dialog .content-wrap .update-text{font-size:12px;color:#909399}.update-dialog .content-wrap .update-log{padding:5px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;font-size:14px;color:#909399;background-color:#F4F4F5;border-radius:4px;margin-top:10px}.update-dialog .content-wrap .update-log .title{line-height:24px}.update-dialog .content-wrap .update-log .content-tip{line-height:24px;font-size:13px}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#E60000",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#fff",
	"menuHover": "#2d325f",
	"subMenuBg": "#222750",
	"subMenuHover": "#1b1e3e",
	"sideBarWidth": "240px"
};