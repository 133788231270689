exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".grid-warp .filed-box{min-height:60px;position:relative;border:1px dashed #E4E7ED}.grid-warp .filed-box .form-drag-warp{margin-bottom:0}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#E60000",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#fff",
	"menuHover": "#2d325f",
	"subMenuBg": "#222750",
	"subMenuHover": "#1b1e3e",
	"sideBarWidth": "240px"
};