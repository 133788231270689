import "core-js/modules/es6.array.find";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { generateEvents, transformFun } from "../../../views/setting/design/form/utils/event";
import { parseStyles } from "../../../views/setting/design/form/utils/style";
export default {
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    context: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      value: '',
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: []
      },
      quickOptions: [{
        prop: 'today',
        text: this.$t('今天'),
        onClick: function onClick(picker) {
          picker.$emit('pick', new Date());
        }
      }, {
        prop: 'yesterday',
        text: this.$t('昨天'),
        onClick: function onClick(picker) {
          var date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          picker.$emit('pick', date);
        }
      }, {
        prop: 'aWeekAgo',
        text: this.$t('一周前'),
        onClick: function onClick(picker) {
          var date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
          picker.$emit('pick', date);
        }
      }],
      events: {
        onChange: '',
        onBlur: '',
        onFocus: ''
      }
    };
  },
  computed: {
    styles: function styles() {
      return parseStyles(this.data.field.styles, this.data.field.props);
    }
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;
      this.data.field.quick.map(function (item) {
        var quick = _this.quickOptions.find(function (data) {
          return data.prop === item;
        });
        quick && _this.pickerOptions.shortcuts.push(quick);
      });
      if (this.data.field.quick.length < 1) {
        this.pickerOptions = {};
      }
      this.events = generateEvents(this.data.field.events);
    },
    onChange: function onChange(value) {
      var _this2 = this;
      var onChange = this.events.onChange;
      if (onChange.event) {
        transformFun(onChange.event, onChange.eventName, value, this.context, function (result) {
          _this2.$emit('input', {
            key: _this2.data.field.paramsKey,
            value: result || value
          });
        });
      } else {
        this.$emit('input', {
          key: this.data.field.paramsKey,
          value: this.value
        });
      }
    },
    onBlur: function onBlur() {
      var onBlur = this.events.onBlur;
      if (onBlur) {
        transformFun(onBlur.event, onBlur.eventName, this.value, this.context, function (result) {});
      }
    },
    onFocus: function onFocus() {
      var onFocus = this.events.onFocus;
      if (onFocus) {
        transformFun(onFocus.event, onFocus.eventName, this.value, this.context, function (result) {});
      }
    }
  }
};