//
//
//
//
//

export default {
  data: function data() {
    return {};
  },
  methods: {
    init: function init() {
      //
    }
  }
};