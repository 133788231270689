var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    ref: "img",
    staticClass: "qr-code-warp",
    style: _vm.getStyle,
    attrs: { alt: "qr", draggable: "false", src: "" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }