var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.data.field.visible,
          expression: "data.field.visible",
        },
      ],
      class: _vm.data.elementId,
      attrs: {
        label: _vm.$t(_vm.data.field.name),
        prop: _vm.data.field.paramsKey,
      },
    },
    [
      _vm.loading
        ? _c("yl-skeleton", { attrs: { active: "", height: "32px", rows: 1 } })
        : _c(
            "el-select",
            {
              class: _vm.data.field.props.className,
              attrs: {
                placeholder: _vm.data.field.props.placeholder,
                clearable: _vm.data.field.props.clearable,
                disabled: _vm.data.field.props.disabled,
                filterable: _vm.data.field.props.filterable,
              },
              on: { change: _vm.onChange, focus: _vm.onFocus },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: _vm.$t(item.name), value: item.id },
              })
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }