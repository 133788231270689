import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import toolMenu from '@/public/tool-menu/index.vue';
import VueDragResize from 'vue-drag-resize';
export default {
  name: 'AppMain',
  components: {
    toolMenu: toolMenu,
    VueDragResize: VueDragResize
  },
  computed: {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.fullPath;
    },
    getStyle: function getStyle() {
      var router = this.$route.name;
      var isPd0 = ['systemSettingDesignFaceSheet', 'SystemSettingDesignForm'].includes(router);
      var style = {};
      if (router === 'quickOrder') {
        style = {
          padding: '74px 0 0 0'
        };
      } else if (router === 'Dashboard') {
        style = {
          padding: '15px 0'
        };
      } else if (isPd0) {
        style = {
          padding: '0'
        };
      }
      return style;
    }
  },
  data: function data() {
    return {
      width: 0,
      height: 0,
      top: 0,
      left: 0
    };
  },
  methods: {
    resize: function resize(newRect) {
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
    }
  }
};