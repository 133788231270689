import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import logoPng from '@/assets/images/logo.png';
import userPhoto from '@/assets/images/userPhoto.gif';
// import userDetail from '@/public/UserDetail.vue'
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: 'Vip',
      // logo: 'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png'
      logo: logoPng,
      showUserDetail: false,
      userPhoto: userPhoto
    };
  },
  computed: _objectSpread({}, mapGetters(['name', 'avatar', 'logoName'])),
  methods: {
    handleShowUserDetail: function handleShowUserDetail() {
      this.showUserDetail = true;
    },
    onCloseUserDetail: function onCloseUserDetail() {
      this.showUserDetail = false;
    }
  }
};