import "core-js/modules/es6.function.name";
import _ from 'loadsh';
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  language: function language(state) {
    return state.app.language;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  addressList: function addressList(state) {
    return state.app.addressList;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  cCountryMap: function cCountryMap(state) {
    return state.user.cCountryMap;
  },
  orderMap: function orderMap(state) {
    return state.user.orderMap;
  },
  outTypeMap: function outTypeMap(state) {
    return state.user.outTypeMap;
  },
  orderStatusMap: function orderStatusMap(state) {
    return state.user.orderStatusMap;
  },
  trackStatusMap: function trackStatusMap(state) {
    return state.user.trackStatusMap;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  pay: function pay(state) {
    return state.user.pay;
  },
  userKey: function userKey(state) {
    return state.userKey;
  },
  MenuList: function MenuList(state) {
    return state.user.MenuList;
  },
  codLimit: function codLimit(state) {
    return state.user.codLimit;
  },
  loginName: function loginName(state) {
    return state.user.loginName;
  },
  photo: function photo(state) {
    return state.user.photo;
  },
  userAdmin: function userAdmin(state) {
    return state.user.userAdmin;
  },
  cusCode: function cusCode(state) {
    return state.user.cusCode;
  },
  cusName: function cusName(state) {
    return state.user.cusName;
  },
  logoName: function logoName(state) {
    return state.user.logoName;
  },
  siteName: function siteName(state) {
    return state.user.siteName;
  },
  dirtPrintFlag: function dirtPrintFlag(state) {
    return state.user.dirtPrintFlag;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  addRoutes: function addRoutes(state) {
    return state.permission.addRoutes;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  rule: function rule(state) {
    return state.validate.rule;
  },
  userMenu: function userMenu(state) {
    return state.user.userMenu;
  },
  collapse: function collapse(state) {
    return state.settings.collapse;
  },
  collection: function collection(state) {
    return state.form.collection;
  },
  designForm: function designForm(state) {
    return state.form.form;
  },
  pageProps: function pageProps(state) {
    return state.lowCode.page.props;
  },
  pageStyles: function pageStyles(state) {
    return state.lowCode.page.styles;
  },
  currentFormItem: function currentFormItem(state) {
    return state.form.current;
  },
  formOptions: function formOptions(state) {
    return state.form.options;
  },
  componentList: function componentList(state) {
    return state.components.componentList;
  },
  storeList: function storeList(state) {
    return state.components.storeList;
  },
  activeComponent: function activeComponent(state) {
    return state.components.activeComponent;
  },
  templateList: function templateList(state) {
    return state.components.templateList;
  },
  styleActives: function styleActives(state) {
    return state.lowCode.styleActives;
  },
  pageMethods: function pageMethods(state) {
    return state.lowCode.page.methods;
  },
  methodsList: function methodsList(state) {
    return state.form.methodsList;
  },
  sourceList: function sourceList(state) {
    return state.lowCode.sourceList;
  },
  fetchList: function fetchList(state) {
    return state.lowCode.fetchList;
  },
  pageCss: function pageCss(state) {
    return state.lowCode.page.css;
  },
  designFormTree: function designFormTree(state) {
    var data = _.cloneDeep(state.lowCode.form);
    var set = function set(children) {
      children.map(function (item) {
        item.children = item.field ? item.field.children : item.children;
        if (item.children.length > 0) {
          set(item.field ? item.field.children : item.children);
        }
      });
    };
    data.map(function (item) {
      item.children = item.field.children;
      if (item.field.children.length > 0) {
        set(item.field ? item.field.children : item.children);
      }
    });
    return data;
  }
};
export default getters;