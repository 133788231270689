var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    ref: "img",
    staticClass: "barcode",
    class: _vm.elementId,
    style: _vm.getStyle,
    attrs: { alt: "barcode", src: "", draggable: "false" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }