/*
 * @Description:
 * @Author: muqingkun
 * @Date: 2023-01-11 15:46:33
 * @LastEditTime: 2023-02-10 15:51:43
 * @LastEditors: muqingkun
 * @Reference:
 */

import fetch from '@/ylCommon/utils/request';
var goods = {
  /**
   * @description: 商品列表
   * @param {*} params
   */
  getSkus: function getSkus(params) {
    return fetch({
      url: '/goods/getSkus.do',
      data: {
        parameter: params
      }
    });
  },
  /**
   * @description: 商品状态数量
   * @param {*} params
   */
  getSkusCount: function getSkusCount(params) {
    return fetch({
      url: '/goods/getSkusCount.do',
      data: {
        parameter: params
      }
    });
  },
  /**
   * @description: 新增商品
   * @param {*} params
   */
  addSku: function addSku(params) {
    return fetch({
      url: '/goods/addSku.do',
      data: {
        parameter: params
      }
    });
  },
  /**
   * @description: 修改商品
   * @param {*} params
   */
  updateSku: function updateSku(params) {
    return fetch({
      url: '/goods/updateSku.do',
      data: {
        parameter: params
      }
    });
  },
  /**
   * @description: 批量新增商品
   * @param {*} params
   */
  saveSkuBatch: function saveSkuBatch(params) {
    return fetch({
      url: '/goods/saveSkuBatch.do',
      data: {
        parameter: params
      }
    });
  },
  /**
   * @description: 批量导入打印
   * @param {*} params
   */
  importSkuPrint: function importSkuPrint(params) {
    return fetch({
      url: '/importSkuPrint.do',
      data: {
        parameter: params
      }
    });
  },
  /**
   * @description: 商品详情
   * @param {*} params
   */
  getSkusDetail: function getSkusDetail(params) {
    return fetch({
      url: '/goods/getSkusDetail.do',
      data: {
        parameter: params
      }
    });
  },
  /**
   * @description: 商品条码打印
   * @param {*} params
   */
  printSkuInfo: function printSkuInfo(params) {
    return fetch({
      url: '/goods/printSkuInfo.do',
      data: {
        parameter: params
      }
    });
  },
  /**
   * @description: 商品启用 禁用
   * @param {*} params
   */
  enableSku: function enableSku(params) {
    return fetch({
      url: '/goods/enableSku.do',
      data: {
        parameter: params
      }
    });
  },
  /**
   * @description: 批量补充商品证书
   * @param {*} params
   */
  updateSkuCertificate: function updateSkuCertificate(params) {
    return fetch({
      url: '/goods/updateSkuCertificate.do',
      data: {
        parameter: params
      }
    });
  },
  /**
   * @description: 批量修改
   * @param {*} params
   */
  updateSkuInfo: function updateSkuInfo(params) {
    return fetch({
      url: '/goods/updateSkuInfo.do',
      data: {
        parameter: params
      }
    });
  },
  /**
   * @description: 提交商品审核
   * @param {*} params
   */
  submitSku: function submitSku(params) {
    return fetch({
      url: '/goods/submitSku.do',
      data: {
        parameter: params
      }
    });
  },
  /**
   * @description: 币种字典
   * @param {*} params
   */
  getCurrencyData: function getCurrencyData(params) {
    return fetch({
      url: '/getDictionaryData.do',
      data: {
        parameter: params
      }
    });
  }
};
export default goods;