var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-menu-warp" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "内容", name: "content" } },
            [
              _c(
                "el-form",
                { ref: "content-form" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "文本内容" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          resize: "none",
                          size: "small",
                        },
                        model: {
                          value: _vm.currentComponent.props.text,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentComponent.props, "text", $$v)
                          },
                          expression: "currentComponent.props.text",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "对齐方式" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w-100",
                          attrs: { size: "small" },
                          model: {
                            value: _vm.currentComponent.props.align,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentComponent.props, "align", $$v)
                            },
                            expression: "currentComponent.props.align",
                          },
                        },
                        _vm._l(_vm.alignOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "字体类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w-100",
                          attrs: { size: "small" },
                          model: {
                            value: _vm.currentComponent.props.fontFamily,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.currentComponent.props,
                                "fontFamily",
                                $$v
                              )
                            },
                            expression: "currentComponent.props.fontFamily",
                          },
                        },
                        _vm._l(_vm.fontFamilyOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "字体尺寸" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w-100",
                          attrs: { size: "small" },
                          model: {
                            value: _vm.currentComponent.props.fontSize,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.currentComponent.props,
                                "fontSize",
                                $$v
                              )
                            },
                            expression: "currentComponent.props.fontSize",
                          },
                        },
                        _vm._l(_vm.fontSizeOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "文本行距" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w-100",
                          attrs: { size: "small" },
                          model: {
                            value: _vm.currentComponent.props.lineHeight,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.currentComponent.props,
                                "lineHeight",
                                $$v
                              )
                            },
                            expression: "currentComponent.props.lineHeight",
                          },
                        },
                        _vm._l(_vm.lineHeightOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.currentComponent.props.isBold,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.currentComponent.props,
                                "isBold",
                                $$v
                              )
                            },
                            expression: "currentComponent.props.isBold",
                          },
                        },
                        [_vm._v("字体加粗")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "边框", name: "board" } },
            [
              _c(
                "el-form",
                { ref: "board-form" },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.currentComponent.props.hasBorder,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.currentComponent.props,
                                "hasBorder",
                                $$v
                              )
                            },
                            expression: "currentComponent.props.hasBorder",
                          },
                        },
                        [_vm._v("显示边框")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }