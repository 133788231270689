import "core-js/modules/es6.array.find";
import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/es6.number.constructor";
//
//
//
//

import barcode from 'jsbarcode';
import { off } from '@/ylCommon/utils/dom';
import { mapGetters } from 'vuex';
export default {
  props: {
    elementId: {
      type: String,
      default: ''
    },
    component: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    bodyHeight: {
      type: Number,
      default: 40
    },
    lineWidth: {
      type: Number,
      default: 2
    },
    format: {
      type: String,
      default: 'CODE128'
    },
    displayValue: {
      type: String,
      default: '1'
    },
    data: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['activeComponent', 'storeList'])), {}, {
    // 获取到当前组件的实例
    currentComponent: function currentComponent() {
      var _this = this;
      return this.storeList.find(function (item) {
        return item.id === _this.activeComponent;
      });
    },
    getStyle: function getStyle() {
      return {
        maxWidth: '100%',
        verticalAlign: 'middle',
        userSelect: 'none'
      };
    }
  }),
  destroyed: function destroyed() {
    // this.clearListener();
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    complete: function complete() {
      this.$emit('complete');
    },
    clearListener: function clearListener() {
      var that = this;
      off(that.$refs.img, 'load', that.complete);
    },
    init: function init() {
      this.complete();
      var elementId = this.elementId,
        bodyHeight = this.bodyHeight,
        lineWidth = this.lineWidth,
        displayValue = this.displayValue,
        format = this.format,
        data = this.data;
      barcode(".".concat(elementId), data, {
        format: format,
        width: lineWidth,
        height: bodyHeight,
        textMargin: 10,
        displayValue: displayValue === '1'
      });
    }
  }
};