import fetch from "../ylCommon/utils/request";
var overseasDeliverys = {
  overseasDelivery: function overseasDelivery(options) {
    // 库存查询销毁
    var params = {
      parameter: options
    };
    return fetch({
      url: '/b2c/destroyWareGoods.do',
      data: params
    });
  },
  countAllowShipData: function countAllowShipData(options) {
    // 库存查询统计可转运库数
    var params = {
      parameter: options
    };
    return fetch({
      url: '/b2c/countAllowShipData.do',
      data: params
    });
  }
};
export default overseasDeliverys;