var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.data.field.visible,
          expression: "data.field.visible",
        },
      ],
      attrs: { label: _vm.$t(_vm.data.field.name) },
    },
    [
      _c("el-date-picker", {
        class: _vm.fieldProps.className,
        attrs: {
          readonly: _vm.fieldProps.readonly,
          editable: _vm.fieldProps.editable,
          clearable: _vm.fieldProps.clearable,
          "start-placeholder": _vm.fieldProps.startPlaceholder,
          "end-placeholder": _vm.fieldProps.endPlaceholder,
          "range-separator": _vm.fieldProps.rangeSeparator,
          "unlink-panels": _vm.fieldProps.unlinkPanels,
          "value-format": "yyyy-MM-dd",
          type: "daterange",
        },
        on: { change: _vm.onChange },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }