var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.hideTrigger
        ? _c("i", {
            staticClass: "iconfont icon-tongxunlu",
            on: { click: _vm.init },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
            { name: "el-full-dialog", rawName: "v-el-full-dialog" },
          ],
          attrs: {
            id: "yl-address-wrap",
            title: _vm.type
              ? _vm.$t("请选择收件地址")
              : _vm.$t("请选择寄件地址"),
            "append-to-body": "",
            visible: _vm.dialogVisible,
            width: "1210px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            open: _vm.getAddressList,
          },
        },
        [
          _c("div", { staticClass: "content-wrap" }, [
            _c(
              "div",
              { staticClass: "header-area" },
              [
                _c("el-input", {
                  staticClass: "search-ipt",
                  attrs: {
                    placeholder: _vm.$t("输入姓名/电话/地址查询"),
                    "suffix-icon": "el-icon-search",
                    size: "mini",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      return _vm.handleSearchAddress($event)
                    },
                  },
                  model: {
                    value: _vm.searchKey,
                    callback: function ($$v) {
                      _vm.searchKey = $$v
                    },
                    expression: "searchKey",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "table-area" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    attrs: {
                      data: _vm.filterTableData,
                      border: "",
                      height: "400px",
                      "tooltip-effect": "dark",
                      "row-class-name": _vm.handleRowClassName,
                    },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "name",
                        label: _vm.$t("姓名"),
                        "show-overflow-tooltip": "",
                        width: "130",
                      },
                    }),
                    _vm._v(" "),
                    !_vm.type
                      ? _c("el-table-column", {
                          key: Math.random(),
                          attrs: {
                            prop: "email",
                            label: _vm.$t("寄件邮箱"),
                            "show-overflow-tooltip": "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.type
                      ? _c("el-table-column", {
                          key: Math.random(),
                          attrs: {
                            prop: "postCode",
                            label: _vm.$t("寄件邮编"),
                            "show-overflow-tooltip": "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "phone",
                        label: _vm.$t("电话号码"),
                        "show-overflow-tooltip": "",
                        width: "120",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "country",
                        label: _vm.$t("国家"),
                        "show-overflow-tooltip": "",
                        width: "80",
                      },
                    }),
                    _vm._v(" "),
                    _vm.type == 1
                      ? _c("el-table-column", {
                          key: Math.random(),
                          attrs: {
                            prop: "company",
                            label: _vm.$t("收件公司"),
                            width: "180",
                            "show-overflow-tooltip": "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type == 1
                      ? _c("el-table-column", {
                          key: Math.random(),
                          attrs: {
                            prop: "postCode",
                            label: _vm.$t("收件邮编"),
                            width: "180",
                            "show-overflow-tooltip": "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type == 1
                      ? _c("el-table-column", {
                          key: Math.random(),
                          attrs: {
                            prop: "certificateCode",
                            label: _vm.$t("证件号码"),
                            width: "150",
                            "show-overflow-tooltip": "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type == 1
                      ? _c("el-table-column", {
                          key: Math.random(),
                          attrs: {
                            prop: "certificateURL",
                            label: _vm.$t("实名认证URL"),
                            width: "230",
                            "show-overflow-tooltip": "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "provname",
                        label: _vm.$t("省份"),
                        "show-overflow-tooltip": "",
                        width: "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.provname
                                ? _c("span", [
                                    _vm._v(_vm._s(scope.row.provname)),
                                  ])
                                : _c("span", [_vm._v(_vm._s(scope.row.prov))]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "cityname",
                        label: _vm.$t("城市"),
                        "show-overflow-tooltip": "",
                        width: "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.cityname
                                ? _c("span", [
                                    _vm._v(_vm._s(scope.row.cityname)),
                                  ])
                                : _c("span", [_vm._v(_vm._s(scope.row.city))]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "areaname",
                        label: _vm.$t("区域"),
                        "show-overflow-tooltip": "",
                        width: "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.areaname
                                ? _c("span", [
                                    _vm._v(_vm._s(scope.row.areaname)),
                                  ])
                                : _c("span", [_vm._v(_vm._s(scope.row.area))]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      key: Math.random(),
                      attrs: {
                        prop: "address",
                        label: _vm.type ? _vm.$t("地址1") : _vm.$t("详细地址"),
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _vm.type
                      ? _c("el-table-column", {
                          key: Math.random(),
                          attrs: {
                            prop: "address2",
                            label: _vm.$t("地址2"),
                            width: "150",
                            "show-overflow-tooltip": "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type
                      ? _c("el-table-column", {
                          key: Math.random(),
                          attrs: {
                            prop: "packageRule",
                            label: _vm.$t("打包要求"),
                            width: "200",
                            "show-overflow-tooltip": "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "handle",
                        width: "120",
                        label: _vm.$t("操作"),
                        fixed: "right",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    size: "mini",
                                    disabled: _vm.handleChooseButton(props.row),
                                    plain: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectFun(props.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("选择")))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }