exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".rectangle-menu-warp{width:300px;height:calc(100% - 53px);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#E60000",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#fff",
	"menuHover": "#2d325f",
	"subMenuBg": "#222750",
	"subMenuHover": "#1b1e3e",
	"sideBarWidth": "240px"
};