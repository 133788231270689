var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("yl-table", {
    staticClass: "config-table-warp",
    attrs: { "table-key": _vm.data.field.props.tableKey },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }