/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-08 10:59:09
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-07-12 11:04:28
 * @FilePath: /yl-vip-customer-front/src/ylCommon/directive/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import elFullDialog from "./el-fullDialog/index.js";
import elDragDialog from "./el-dragDialog/index.js";
import elEntertoNext from "./el-enterto-next/index.js";
import adaptive from "./adaptive";
// import loadmore from './load-more/index.js'
var directive = [elFullDialog, elDragDialog, elEntertoNext, adaptive
// loadmore
];

export default directive;