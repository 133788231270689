import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'loadsh';
export default {
  props: {
    moreVisible: {
      type: Boolean,
      default: false
    },
    moreSliceNumber: {
      type: Number,
      default: 0
    },
    plain: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    context: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      current: [],
      overVisible: false
    };
  },
  computed: {
    getClass: function getClass() {
      return this.plain ? 'is-plain' : '';
    },
    moreBtnVisible: function moreBtnVisible() {
      return this.moreVisible && this.options.length > this.moreSliceNumber;
    },
    toggleBtnIcon: function toggleBtnIcon() {
      return this.overVisible ? 'el-icon-arrow-up' : 'el-icon-arrow-down';
    },
    toggleBtnText: function toggleBtnText() {
      return this.overVisible ? this.$t('收缩') : this.$t('展开');
    },
    headOptions: function headOptions() {
      var options = _.cloneDeep(this.options);
      return options.slice(0, this.moreSliceNumber);
    },
    overOptions: function overOptions() {
      var options = _.cloneDeep(this.options);
      return options.slice(this.moreSliceNumber);
    }
  },
  mounted: function mounted() {},
  methods: {
    handleClick: function handleClick(value) {
      var _this = this;
      var isMultiple = this.multiple;
      var current = this.current;
      if (isMultiple) {
        if (current.includes(value)) {
          current.map(function (item, index) {
            if (item === value) _this.current.splice(index, 1);
          });
        } else this.current.push(value);
      } else {
        this.current = value;
      }
      var result = isMultiple ? _.cloneDeep(this.current) : value;
      this.$emit('change', result);
    },
    handleToggleAllVisible: function handleToggleAllVisible() {
      this.overVisible = !this.overVisible;
    },
    setValue: function setValue(value) {
      this.current = value;
      this.$emit('change', value);
    }
  }
};