import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import { constantRoutes } from '@/router';
import { routerJsonMap } from '@/router/map';
import { deepClone } from '@/ylCommon/utils';
import store from "../index";
var routerArr = [];
var checkoutRouter = function checkoutRouter(permissionRoute) {
  var routerList = [];
  permissionRoute.map(function (item) {
    var newItem = _objectSpread({}, item);
    routerArr.push(newItem.routeId);
    var hasChild = function hasChild(route) {
      return route.children && route.children.length > 0;
    };
    var findRouter = function findRouter(route, name) {
      var result = Object.keys(route).length !== 0;
      if (!result) {
        console.error("".concat(name, " Router is Not Found"));
      }
      return result;
    };
    var id = newItem.routeId;
    var router = _objectSpread({}, routerJsonMap[id]);
    if (findRouter(router, id)) {
      router.meta.title = newItem.funcName;
      router.meta.keepAlive = newItem.cache === '1';
      if (hasChild(newItem)) {
        router.children = [];
        newItem.children.map(function (child) {
          var current = _objectSpread({}, routerJsonMap[child.routeId]);
          var id = child.routeId;
          if (findRouter(current, id)) {
            current.meta.title = child.funcName;
            current.meta.keepAlive = child.cache === '1';
            routerArr.push(child.routeId);
            if (hasChild(child)) {
              current.children = [];
              child.children.map(function (tag) {
                var newTag = _objectSpread({}, tag);
                var newChild = _objectSpread({}, routerJsonMap[tag.routeId]);
                var id = tag.routeId;
                if (findRouter(newChild, id)) {
                  newChild.meta.title = newTag.funcName;
                  newChild.meta.keepAlive = newTag.cache === '1';
                  if (hasChild(tag)) {
                    newChild.children = [];
                    tag.children.map(function (tags) {
                      var newTags = _objectSpread({}, tags);
                      var newChilds = _objectSpread({}, routerJsonMap[tags.routeId]);
                      var ids = tags.routeId;
                      if (findRouter(newChilds, ids)) {
                        newChilds.meta.title = newTags.funcName;
                        newChilds.meta.keepAlive = newTags.cache === '1';
                        newChild.children.push(newChilds);
                        routerArr.push(tags.routeId);
                      }
                    });
                  }
                  current.children.push(newChild);
                  routerArr.push(tag.routeId);
                }
              });
            }
            router.children.push(current);
          }
        });
      }
      routerList.push(router);
    }
  });
  return routerList;
};

// const checkoutRouterForMap = (permissionRoute) => {
//   permissionRoute.map(item => {
//     const newItem = { ...item }
//     const router = routerJsonMap[newItem.routeId]
//     router && routerMap.push(router)
//     if (newItem.children && newItem.children.length > 0) {
//       checkoutRouterForMap(newItem.children)
//     }
//   })
// }

var state = {
  routes: [],
  addRoutes: [],
  routeMap: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  },
  SET_ROUTE_MAP: function SET_ROUTE_MAP(state, routeArr) {
    state.routeMap = routeArr;
  }
};
var checkoutMenu = function checkoutMenu(data) {
  var result = [];
  var deleteComponent = function deleteComponent(menuList) {
    menuList.map(function (item) {
      if (item.component) delete item.component;
      if (item.children && item.children.length > 0) {
        deleteComponent(item.children);
      }
    });
  };
  data.map(function (item) {
    var newItem = _objectSpread({}, item);
    if (newItem.component) delete newItem.component;
    if (newItem.children && newItem.children.length > 0) {
      deleteComponent(newItem.children);
    }
    result.push(newItem);
  });
  return result;
};
var hiddenRoute = function hiddenRoute(routeList, routeName, childName, childrenName) {
  routeList.map(function (item, index) {
    if (childName) {
      if (item.name === routeName) {
        item.children.map(function (e) {
          if (childrenName && e.children && e.children.length > 0) {
            e.children.map(function (i) {
              if (i.name === childrenName) i.hidden = true;
            });
          } else if (e.name === childName) e.hidden = true;
        });
      }
    } else if (item.name === routeName) item.hidden = true;
  });
};
var actions = {
  generateRoutes: function generateRoutes(_ref, list) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      routerArr = [];
      var routeList = checkoutRouter(list);
      // 隐藏路由
      hiddenRoute(routeList, 'BaseData', 'PermissionMange', 'PermissionChangePassword');
      hiddenRoute(routeList, 'BaseData', 'PersonManage', 'SystemSettingButton');
      var _JSON$parse = JSON.parse(localStorage.getItem('userInfo')),
        userAdmin = _JSON$parse.userAdmin;
      if (userAdmin === '0') {
        // 子账号隐藏 库存查询页面
        hiddenRoute(routeList, 'B2COrder', 'B2CShippingAbroad');
        // hiddenRoute(routeList, 'B2COrder', 'B2CShippingAbroad', 'B2CInventoryCheck')
      }

      var userMenu = checkoutMenu(deepClone(routeList));
      store.dispatch('user/setUserMenu', userMenu).then();
      commit('SET_ROUTES', routeList);
      commit('SET_ROUTE_MAP', routerArr);
      resolve(routeList);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};