var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tool-menu", attrs: { id: "tool-menu" } },
    [
      _c("i", {
        class: _vm.isShowTool
          ? "el-icon-remove icon-hide"
          : "el-icon-circle-plus icon-show",
        on: { click: _vm.hideTool },
      }),
      _vm._v(" "),
      _vm.isShowTool
        ? _c(
            "div",
            {
              directives: [{ name: "drag", rawName: "v-drag" }],
              staticClass: "freight-calculation",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleShow($event)
                },
              },
            },
            [
              _c("div", { staticClass: "iconClass" }, [
                _c(
                  "div",
                  { staticClass: "icon-div" },
                  [
                    _c("svg-icon", {
                      staticClass: "jisuanqi-img",
                      attrs: { "icon-class": "yfss" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "tool-des" }, [
                  _vm._v(_vm._s(_vm.$t("运费试算"))),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("费用计算器"),
            visible: _vm.calculationVisable,
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.calculationVisable = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "calcForm",
              staticClass: "demo-form-inline",
              attrs: {
                model: _vm.calcForm,
                rules: _vm.rules,
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("目的地国家"),
                            prop: "country",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w-100",
                              attrs: {
                                size: "small",
                                filterable: "",
                                placeholder: _vm.$t("请选择国家"),
                              },
                              on: { change: _vm.countryChange },
                              model: {
                                value: _vm.calcForm.country,
                                callback: function ($$v) {
                                  _vm.$set(_vm.calcForm, "country", $$v)
                                },
                                expression: "calcForm.country",
                              },
                            },
                            _vm._l(
                              _vm.countriesOptions,
                              function (tag, tagIndex) {
                                return _c("el-option", {
                                  key: tagIndex,
                                  attrs: {
                                    label: _vm.$t(tag.abrv),
                                    value: tag.enName,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("目的地邮编") } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: _vm.$t("请输入目的地邮编") },
                            model: {
                              value: _vm.calcForm.postcode,
                              callback: function ($$v) {
                                _vm.$set(_vm.calcForm, "postcode", $$v)
                              },
                              expression: "calcForm.postcode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("目的地省市区") } },
                        [
                          _c("address-select", {
                            ref: "receiver-cascder",
                            attrs: {
                              country: _vm.calcForm.country,
                              placeholder: _vm.$t("请选择收件地区"),
                            },
                            model: {
                              value: _vm.calcForm.rSsq,
                              callback: function ($$v) {
                                _vm.$set(_vm.calcForm, "rSsq", $$v)
                              },
                              expression: "calcForm.rSsq",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("包裹重(KG)"),
                            prop: "weight",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "small",
                              placeholder: _vm.$t("请输入重量"),
                              oninput: "value = value.replace(/[^\\d.]/g,'')",
                            },
                            model: {
                              value: _vm.calcForm.weight,
                              callback: function ($$v) {
                                _vm.$set(_vm.calcForm, "weight", $$v)
                              },
                              expression: "calcForm.weight",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("选择类型"),
                            prop: "productTypeCode",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w-100",
                              attrs: {
                                size: "small",
                                filterable: "",
                                placeholder: _vm.$t("请选择类型"),
                              },
                              model: {
                                value: _vm.calcForm.productTypeCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.calcForm, "productTypeCode", $$v)
                                },
                                expression: "calcForm.productTypeCode",
                              },
                            },
                            _vm._l(_vm.routeList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  label: _vm.$t(item.label),
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("产品名称"), prop: "lineIds" },
                        },
                        [
                          _vm.calcForm.productTypeCode === 1
                            ? _c(
                                "el-select",
                                {
                                  ref: "prodctName",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "small",
                                    multiple: "",
                                    filterable: "",
                                    placeholder: _vm.$t("请选择产品名称"),
                                  },
                                  on: { change: _vm.getProductName },
                                  model: {
                                    value: _vm.calcForm.lineIds,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.calcForm, "lineIds", $$v)
                                    },
                                    expression: "calcForm.lineIds",
                                  },
                                },
                                _vm._l(
                                  _vm.chanpinOptions,
                                  function (tag, tagIndex) {
                                    return _c("el-option", {
                                      key: tagIndex,
                                      attrs: {
                                        label: _vm.$t(tag.name),
                                        value: tag.id,
                                        title: tag.title,
                                      },
                                    })
                                  }
                                ),
                                1
                              )
                            : _c(
                                "el-select",
                                {
                                  ref: "custprodctName",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "small",
                                    multiple: "",
                                    filterable: "",
                                    placeholder: _vm.$t("请选择客户产品名称"),
                                  },
                                  on: { change: _vm.getPrdtName },
                                  model: {
                                    value: _vm.calcForm.lineIds,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.calcForm, "lineIds", $$v)
                                    },
                                    expression: "calcForm.lineIds",
                                  },
                                },
                                _vm._l(
                                  _vm.cusProductNameOptions,
                                  function (tag, tagIndex) {
                                    return _c("el-option", {
                                      key: tagIndex,
                                      attrs: {
                                        label: tag.cpName,
                                        value: tag.cpId,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "query-button" },
                [
                  _c("el-button", { on: { click: _vm.handleClose } }, [
                    _vm._v(_vm._s(_vm.$t("重 置"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", loading: _vm.queryLoading },
                      on: { click: _vm.submitFreight },
                    },
                    [_vm._v(_vm._s(_vm.$t("查 询")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: _vm.$t("产品名称"), prop: "lineIdName" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("头程运费(币别)"),
                  prop: "headFreightFees",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("尾程运费(币别)"),
                  prop: "tailFreightFees",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: _vm.$t("挂号费"), prop: "ghfFee" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: _vm.$t("费用合计"), prop: "totalCost" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: _vm.$t("备注"), prop: "remark" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { fixed: "right", label: _vm.$t("操作"), width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            class: { createOrder: scope.row.totalCost === 0 },
                            attrs: {
                              disabled: scope.row.totalCost === 0,
                              type: "text",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleClick(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("立即下单")))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("div", { staticClass: "warm-prompt" }, [
                _c("div", { staticClass: "line-msg" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "备注：以上试算仅供参考，实际运费可支持相关品类的路线而有所差别，最终解释权归极兔国际所有"
                        )
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }