exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".app-menu-warp{height:calc(100% - 53px);border-right:0;position:relative;width:100%;background-color:#ffffff !important}.app-menu-warp .el-menu-item{font-size:12px !important;padding:0 !important;padding-left:20px !important}.app-menu-warp .el-menu-item,.app-menu-warp .el-submenu__title{background-color:#ffffff !important;height:40px !important;line-height:40px;font-size:14px;font-family:PingFang SC, PingFang SC-6;color:#45576c !important}.app-menu-warp .el-menu-item i,.app-menu-warp .el-submenu__title i{color:#D0D5D9}.app-menu-warp .el-menu-item .svg-icon,.app-menu-warp .el-submenu__title .svg-icon{margin-right:10px;font-size:14px}.app-menu-warp .el-menu-item.is-active{background-color:#FFF7F7 !important;font-size:14px;font-family:PingFang SC, PingFang SC-6;color:#45576c !important;font-weight:550 !important}.app-menu-warp .el-menu,.app-menu-warp .el-menu--inline{background:#ffffff !important}.app-menu-warp .el-submenu.is-opened{font-size:14px !important;font-family:PingFang SC, PingFang SC-6;color:#e60000 !important}.app-menu-warp .el-submenu.is-opened .el-submenu__title{color:#e60000 !important;font-size:14px}.app-menu-warp.el-menu--collapse span{display:none !important}.kzTitle{overflow:hidden;text-overflow:ellipsis;white-space:normal}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#E60000",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#fff",
	"menuHover": "#2d325f",
	"subMenuBg": "#222750",
	"subMenuHover": "#1b1e3e",
	"sideBarWidth": "240px"
};