var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-main", style: _vm.getStyle },
    [
      _c(
        "div",
        { staticClass: "drag-menu-tool" },
        [
          _c(
            "VueDragResize",
            {
              attrs: {
                isActive: true,
                w: "auto",
                h: "auto",
                isResizable: false,
                parentH: 500,
                parentW: 1000,
                x: 940,
                y: 370,
                parentLimitation: true,
              },
              on: { resizing: _vm.resize, dragging: _vm.resize },
            },
            [_c("toolMenu")],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "keep-alive",
        [_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()],
        1
      ),
      _vm._v(" "),
      !_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }