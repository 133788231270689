import variables from '@/ylCommon/styles/element-variables.scss';
import loginCom from '@/views/login/yunluLogin';
export default {
  theme: variables.theme,
  /**
     * @type {boolean} true | false
     * @description Whether show the settings right-panel
     */
  showSettings: false,
  /**
     * @type {boolean} true | false
     * @description Whether need tagsView
     */
  tagsView: true,
  /**
     * @type {boolean} true | false
     * @description Whether fix the header
     */
  fixedHeader: true,
  /**
     * @type {boolean} true | false
     * @description Whether show the logo in sidebar
     */
  sidebarLogo: true,
  /**
     * @type {string | array} 'production' | ['production','development']
     * @description Need show err logs component.
     * The default is only used in the production env
     * If you want to also use it in dev, you can pass ['production','development']
     */
  errorLog: 'production',
  loginCom: loginCom,
  indexCom: '@/views/dashboard/index',
  showLang: false,
  themeList: [{
    color: '#1890FF',
    text: '拂晓蓝'
  }, {
    color: '#FF69B4',
    text: '薄暮'
  }, {
    color: '#FAAD14',
    text: '日暮'
  }, {
    color: '#13C2C2',
    text: '明清'
  }, {
    color: '#52C41A',
    text: '极光绿'
  }, {
    color: '#5371ec',
    text: '极客蓝'
  }, {
    color: '#a878ea',
    text: '酱紫'
  }],
  ORIGINAL_THEME: '#e60000'
};