var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c("transition", { attrs: { name: "sidebarLogoFade" } }, [
        _vm.collapse
          ? _c("a", { key: "collapse", staticClass: "sidebar-logo-link" }, [
              _vm.logo
                ? _c("img", {
                    staticClass: "sidebar-logo",
                    attrs: { src: _vm.logo },
                  })
                : _vm._e(),
            ])
          : _c("a", { key: "expand", staticClass: "sidebar-logo-link" }, [
              _c("p", { staticClass: "use-name" }, [
                _vm._v(_vm._s(_vm.logoName)),
              ]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }