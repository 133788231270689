import { render, staticRenderFns } from "./addressBook.vue?vue&type=template&id=0efce63a&"
import script from "./addressBook.vue?vue&type=script&lang=js&"
export * from "./addressBook.vue?vue&type=script&lang=js&"
import style0 from "./addressBook.vue?vue&type=style&index=0&id=0efce63a&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0efce63a')) {
      api.createRecord('0efce63a', component.options)
    } else {
      api.reload('0efce63a', component.options)
    }
    module.hot.accept("./addressBook.vue?vue&type=template&id=0efce63a&", function () {
      api.rerender('0efce63a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/setting/design/lowCode/components/addressBook.vue"
export default component.exports