var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.parentType !== "form"
    ? _c("div", { staticClass: "native-field-warp", style: _vm.styles }, [
        _c("div", { staticClass: "field-label" }, [
          _vm._v(_vm._s(_vm.data.field.name)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "address-book-item" },
          [
            _c("el-input", {
              class: _vm.data.field.props.className,
              attrs: {
                placeholder: _vm.data.field.props.placeholder,
                clearable: _vm.data.field.props.clearable,
                readonly: _vm.data.field.props.readonly,
              },
              on: { focus: _vm.onFocus, blur: _vm.onBlur },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            }),
            _vm._v(" "),
            _c("i", {
              staticClass: "el-icon-notebook-1",
              on: { click: _vm.handleClick },
            }),
            _vm._v(" "),
            _c("address-book-dialog", {
              ref: "address-book",
              attrs: { "select-fun": _vm.onSelectAddress },
            }),
          ],
          1
        ),
      ])
    : _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.data.field.visible,
              expression: "data.field.visible",
            },
          ],
          staticClass: "address-book-item",
          style: _vm.styles,
          attrs: { label: _vm.data.field.name, prop: _vm.data.field.paramsKey },
        },
        [
          _c("el-input", {
            attrs: {
              clearable: _vm.data.field.props.clearable,
              readonly: _vm.data.field.props.readonly,
              placeholder: _vm.data.field.props.placeholder,
            },
            on: { input: _vm.onInput, focus: _vm.onFocus, blur: _vm.onBlur },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
          _vm._v(" "),
          _c("i", {
            staticClass: "el-icon-notebook-1",
            on: { click: _vm.handleClick },
          }),
          _vm._v(" "),
          _c("address-book-dialog", {
            ref: "address-book",
            attrs: { "select-fun": _vm.onSelectAddress },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }