import "core-js/modules/es6.regexp.replace";
import _createForOfIteratorHelper from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/createForOfIteratorHelper.js";
import _toConsumableArray from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ylScrollPane from '@/ylCommon/components/ScrollPane/index.vue';
import path from 'path';
export default {
  components: {
    ylScrollPane: ylScrollPane
  },
  data: function data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {},
      affixTags: [],
      dom: null,
      innerHeight: 0,
      innerWidth: 0,
      offsetHeight: 0,
      offsetWidth: 0,
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
      diffX: 0,
      diffY: 0,
      isDrag: false,
      firstTime: '',
      lastTime: ''
    };
  },
  computed: {
    visitedViews: function visitedViews() {
      // console.log(this.$store.state.tagsView.visitedViews)
      return this.$store.state.tagsView.visitedViews;
    },
    routes: function routes() {
      return this.$store.state.permission.routes;
    }
  },
  watch: {
    $route: function $route() {
      this.addTags();
      this.moveToCurrentTag();
    },
    visible: function visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu);
      } else {
        document.body.removeEventListener('click', this.closeMenu);
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.initTags();
    this.addTags();
    // this.dragFunc()
  },

  methods: {
    isActive: function isActive(route) {
      return route.path === this.$route.path;
    },
    filterAffixTags: function filterAffixTags(routes) {
      var _this = this;
      var basePath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
      var tags = [];
      routes.forEach(function (route) {
        if (route.meta && route.meta.affix) {
          var tagPath = path.resolve(basePath, route.path);
          tags.push({
            fullPath: tagPath,
            path: tagPath,
            name: route.name,
            meta: _objectSpread({}, route.meta)
          });
        }
        if (route.children) {
          var tempTags = _this.filterAffixTags(route.children, route.path);
          if (tempTags.length >= 1) {
            tags = [].concat(_toConsumableArray(tags), _toConsumableArray(tempTags));
          }
        }
      });
      return tags;
    },
    initTags: function initTags() {
      var affixTags = this.affixTags = this.filterAffixTags(this.routes);
      var _iterator = _createForOfIteratorHelper(affixTags),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var tag = _step.value;
          if (tag.name) {
            this.$store.dispatch('tagsView/addVisitedView', tag);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    addTags: function addTags() {
      var name = this.$route.name;
      var nonRepeat = this.visitedViews.every(function (item) {
        return item.name !== name;
      });
      if (name && nonRepeat) {
        this.$store.dispatch('tagsView/addView', this.$route);
      }
      return false;
    },
    moveToCurrentTag: function moveToCurrentTag() {
      var _this2 = this;
      var tags = this.$refs.tag;
      this.$nextTick(function () {
        var _iterator2 = _createForOfIteratorHelper(tags),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var tag = _step2.value;
            if (tag.to.path === _this2.$route.path) {
              _this2.$refs.scrollPane.moveActiveTag();
              if (tag.to.fullPath !== _this2.$route.fullPath) {
                _this2.$store.dispatch('tagsView/updateVisitedView', _this2.$route);
              }
              break;
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      });
    },
    refreshSelectedTag: function refreshSelectedTag(view) {
      var _this3 = this;
      this.$store.dispatch('tagsView/delCachedView', view).then(function () {
        var fullPath = view.fullPath;
        _this3.$nextTick(function () {
          _this3.$router.replace({
            path: '/redirect' + fullPath
          });
        });
      });
    },
    closeSelectedTag: function closeSelectedTag(view) {
      var _this4 = this;
      console.log('view', view);
      var $store = this.$store;
      $store.dispatch('tagsView/delView', view).then(function (_ref) {
        var visitedViews = _ref.visitedViews;
        if (_this4.isActive(view)) {
          _this4.toLastView(visitedViews, view);
        }
      });
    },
    closeOthersTags: function closeOthersTags() {
      var _this5 = this;
      this.$router.push(this.selectedTag);
      this.$store.dispatch('tagsView/delOthersViews', this.selectedTag).then(function () {
        _this5.moveToCurrentTag();
      });
    },
    closeAllTags: function closeAllTags(view) {
      var _this6 = this;
      this.$store.dispatch('tagsView/delAllViews').then(function (_ref2) {
        var visitedViews = _ref2.visitedViews;
        if (_this6.affixTags.some(function (tag) {
          return tag.path === view.path;
        })) {
          return;
        }
        _this6.toLastView(visitedViews, view);
      });
    },
    toLastView: function toLastView(visitedViews, view) {
      var latestView = visitedViews.slice(-1)[0];
      if (latestView) {
        this.$router.push(latestView);
      } else {
        if (view.name === 'Dashboard') {
          this.$router.replace({
            path: '/redirect' + view.fullPath
          });
        } else {
          this.$router.push('/');
        }
      }
    },
    openMenu: function openMenu(tag, e) {
      this.left = e.clientX + 15;
      this.top = e.clientY + 5;
      this.visible = true;
      this.selectedTag = tag;
    },
    closeMenu: function closeMenu() {
      this.visible = false;
    }
  }
};