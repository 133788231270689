var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    { attrs: { "hide-on-click": true }, on: { command: _vm.handleCommand } },
    [
      _c(
        "div",
        { staticClass: "user-set" },
        [
          _c("svg-icon", {
            staticClass: "avatar-img",
            attrs: { "icon-class": "avatar" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "dfs-y" }, [
            _c(
              "div",
              [
                _c("span", { staticClass: "use-name ml-10 mr-10 fs-13" }, [
                  _vm._v(_vm._s(_vm.cusName)),
                ]),
                _vm._v(" "),
                _c("svg-icon", {
                  staticClass: "avatar-down",
                  attrs: { "icon-class": "down" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.cusCode))]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c("el-dropdown-item", { attrs: { command: "password" } }, [
            _c("i", { staticClass: "el-icon-unlock fs-16" }),
            _vm._v(_vm._s(_vm.$t("修改密码"))),
          ]),
          _vm._v(" "),
          _c("el-dropdown-item", { attrs: { command: "logout" } }, [
            _c("i", { staticClass: "el-icon-switch-button fs-16" }),
            _vm._v(_vm._s(_vm.$t("退出登录"))),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }