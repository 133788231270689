import TableGetter from '@/plugins/app/index.js';
export default {
  name: 'Hell',
  props: {
    context: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    row: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    label: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    createElement: [Function, String]
  },
  mounted: function mounted() {},
  methods: {},
  render: function render(h) {
    var result = null;
    if (this.createElement) {
      result = TableGetter.renderFun(h, this.row, this.createElement, this.context.$parent || this.context);
    } else {
      result = h('span', {
        domProps: {
          className: 'ellipsis-one',
          innerHTML: this.label
        }
      });
    }
    return result;
  }
};