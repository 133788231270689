var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-drag-warp",
      class: _vm.warpClass,
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.onClick($event)
        },
      },
    },
    [
      _c("div", { staticClass: "right-tools" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "copy-bar item",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleCopyField($event)
              },
            },
          },
          [_c("i", { staticClass: "el-icon-copy-document" })]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "remove-bar item",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleDeleteField($event)
              },
            },
          },
          [_c("i", { staticClass: "el-icon-close" })]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "bottom-tools" }, [
        _c("div", { staticClass: "name item" }, [
          _vm._v(_vm._s(_vm.field.type)),
        ]),
      ]),
      _vm._v(" "),
      _c(_vm.field.type + "Item", {
        ref: "item",
        tag: "component",
        attrs: { data: _vm.field },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "move-bar item" }, [
      _c("i", { staticClass: "el-icon-rank" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }