var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.data.field.visible,
          expression: "data.field.visible",
        },
      ],
      class: _vm.data.elementId,
      attrs: {
        label: _vm.$t(_vm.data.field.name),
        prop: _vm.data.field.paramsKey,
      },
    },
    [
      _vm.loading
        ? _c("yl-skeleton", { attrs: { active: "", rows: 1, height: "32px" } })
        : _c(
            "el-checkbox-group",
            {
              class: _vm.data.field.props.className,
              on: { change: _vm.onChange },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c(
                "el-checkbox",
                { key: item.label, attrs: { label: item.id } },
                [_vm._v(_vm._s(_vm.$t(item.name)))]
              )
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }