var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.parentType !== "form"
    ? _c(
        "div",
        { staticClass: "native-field-warp", style: _vm.styles },
        [
          _c("div", { staticClass: "field-label" }, [
            _vm._v(_vm._s(_vm.data.field.name)),
          ]),
          _vm._v(" "),
          _c("el-input", {
            attrs: {
              type: "textarea",
              maxlength: _vm.data.field.props.maxlength,
              placeholder: _vm.data.field.props.placeholder,
              resize: _vm.data.field.props.resize,
              rows: _vm.data.field.props.rows,
              autosize: _vm.data.field.props.autosize,
              "show-word-limit": _vm.data.field.props.showWordLimit,
              readonly: _vm.data.field.props.readonly,
            },
            model: {
              value: _vm.data.field.value,
              callback: function ($$v) {
                _vm.$set(_vm.data.field, "value", $$v)
              },
              expression: "data.field.value",
            },
          }),
        ],
        1
      )
    : _c(
        "el-form-item",
        { style: _vm.styles, attrs: { label: _vm.data.field.name } },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              maxlength: _vm.data.field.props.maxlength,
              placeholder: _vm.data.field.props.placeholder,
              resize: _vm.data.field.props.resize,
              rows: _vm.data.field.props.rows,
              autosize: _vm.data.field.props.autosize,
              "show-word-limit": _vm.data.field.props.showWordLimit,
              readonly: _vm.data.field.props.readonly,
            },
            model: {
              value: _vm.data.field.value,
              callback: function ($$v) {
                _vm.$set(_vm.data.field, "value", $$v)
              },
              expression: "data.field.value",
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }