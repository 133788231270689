var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.data.field.visible,
          expression: "data.field.visible",
        },
      ],
      staticClass: "button-drawer-warp",
    },
    [
      _c(
        "el-button",
        {
          staticClass: "toggle-btn",
          class: _vm.data.field.props.className,
          attrs: {
            size: _vm.data.field.props.size,
            plain: _vm.data.field.props.plain,
            disabled: _vm.data.field.props.disabled,
            round: _vm.data.field.props.round,
            circle: _vm.data.field.props.circle,
            type: _vm.data.field.props.type,
          },
          on: { click: _vm.handleToggleDrawer },
        },
        [
          _vm._v("\n    " + _vm._s(_vm.data.field.name) + " "),
          _c("i", {
            staticClass: "el-icon-d-arrow-left fs-14",
            class: _vm.iconClass,
          }),
        ]
      ),
      _vm._v(" "),
      _c("el-collapse-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.drawerVisible,
                expression: "drawerVisible",
              },
            ],
            staticClass: "prod-drawer-area",
          },
          _vm._l(_vm.data.field.children, function (item) {
            return _c(item.type + "Item", {
              key: item.elementId,
              tag: "set-component",
              attrs: { data: item },
            })
          }),
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }