import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0696a1a6&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=0696a1a6&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0696a1a6')) {
      api.createRecord('0696a1a6', component.options)
    } else {
      api.reload('0696a1a6', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=0696a1a6&", function () {
      api.rerender('0696a1a6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ylCommon/components/Address/index.vue"
export default component.exports