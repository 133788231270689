import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addResizeListener, removeResizeListener } from '@/ylCommon/utils/resize-event.js';
export default {
  props: {
    mode: {
      type: String,
      default: 'horizontal'
    },
    activeClass: {
      type: String,
      default: 'active'
    }
  },
  data: function data() {
    return {
      offset: 0,
      maxLeft: 0,
      $view: {},
      $scroll: {},
      scrollable: false
    };
  },
  computed: {
    isHorizontal: function isHorizontal() {
      return this.mode === 'horizontal';
    },
    getWarpStyle: function getWarpStyle() {
      var result = {};
      result = {
        display: 'flex',
        flexDirection: this.isHorizontal ? 'row' : 'column'
      };
      return result;
    },
    getScrollStyle: function getScrollStyle() {
      var result = {};
      var isHorizontal = this.isHorizontal;
      var dir = isHorizontal ? 'X' : 'Y';
      result = {
        position: 'absolute',
        whiteSpace: isHorizontal ? 'nowrap' : 'wrap',
        transform: "translate".concat(dir, "(-").concat(this.offset, "px)")
      };
      return result;
    },
    getViewStyle: function getViewStyle() {
      var result = {};
      var isRight = this.isHorizontal && this.scrollable;
      result = {
        margin: isRight ? '0 15px' : '0',
        borderRight: isRight ? '1px solid #f5f7fa' : '0',
        borderLeft: isRight ? '1px solid #f5f7fa' : '0'
      };
      return result;
    },
    sizeName: function sizeName() {
      return this.mode === 'horizontal' ? 'width' : 'height';
    },
    dirAttr: function dirAttr() {
      return "offset".concat(this.firstUpperCase(this.sizeName));
    }
  },
  watch: {
    active: function active() {
      this.activeTag();
    }
  },
  mounted: function mounted() {
    this.init();
  },
  beforeDestroy: function beforeDestroy() {
    if (this.$el) removeResizeListener(this.$refs.scroll, this.update);
  },
  methods: {
    firstUpperCase: function firstUpperCase(str) {
      return str.toLowerCase().replace(/( |^)[a-z]/g, function (L) {
        return L.toUpperCase();
      });
    },
    init: function init() {
      addResizeListener(this.$refs.scroll, this.update);
    },
    update: function update() {
      this.$view = this.$refs['view'];
      this.$scroll = this.$refs['scroll'];
      if (!this.$scroll) return;
      var scrollSize = this.$scroll[this.dirAttr];
      var containerSize = this.$view[this.dirAttr];
      var currentOffset = this.offset;
      if (containerSize < scrollSize) {
        var _currentOffset = this.offset;
        this.scrollable = this.scrollable || {};
        this.scrollable.prev = _currentOffset;
        this.scrollable.next = _currentOffset + containerSize < scrollSize;
        if (scrollSize - _currentOffset < containerSize) {
          this.offset = scrollSize - containerSize;
        }
      } else {
        this.scrollable = false;
        if (currentOffset < 0 || currentOffset === this.offset) {
          this.offset = 0;
        }
      }
    },
    handleNext: function handleNext() {
      var scrollSize = this.$scroll[this.dirAttr];
      var containerSize = this.$view[this.dirAttr];
      var currentOffset = this.offset;
      if (scrollSize - currentOffset <= containerSize) return;
      this.offset = scrollSize - currentOffset > containerSize * 2 ? currentOffset + containerSize : scrollSize - containerSize;
    },
    handlePrev: function handlePrev() {
      var containerSize = this.$view[this.dirAttr];
      var currentOffset = this.offset;
      if (!currentOffset) return;
      this.offset = currentOffset > containerSize ? currentOffset - containerSize : 0;
    },
    moveActiveTag: function moveActiveTag() {
      var _this = this;
      this.$nextTick(function () {
        if (!_this.scrollable) return;
        var isHorizontal = _this.mode === 'horizontal';
        var scroll = _this.$scroll;
        var $current = scroll.querySelector('.active');
        if (!$current) return;
        var viewScroll = _this.$view;
        var activeBounding = $current.getBoundingClientRect();
        var scrollViewBounding = viewScroll.getBoundingClientRect();
        var maxOffset = isHorizontal ? scroll.offsetWidth - scrollViewBounding.width : scroll.offsetHeight - scrollViewBounding.height;
        var currentOffset = _this.offset;
        var computedOffset = currentOffset;
        if (isHorizontal) {
          if (activeBounding.left < scrollViewBounding.left) {
            computedOffset = currentOffset - (scrollViewBounding.left - activeBounding.left);
          }
          if (scrollViewBounding.right < activeBounding.right) {
            computedOffset = currentOffset + activeBounding.right - scrollViewBounding.right;
          }
        } else {
          if (activeBounding.top < scrollViewBounding.top) {
            computedOffset = currentOffset - (scrollViewBounding.top - activeBounding.top);
          }
          if (activeBounding.bottom > scrollViewBounding.bottom) {
            computedOffset = currentOffset + (activeBounding.bottom - scrollViewBounding.bottom);
          }
        }
        computedOffset = Math.max(computedOffset, 0);
        _this.offset = Math.min(computedOffset, maxOffset);
      });
    }
  }
};