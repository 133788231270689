import "core-js/modules/es6.array.find";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import draggable from 'vuedraggable';
import { mapGetters, mapMutations } from 'vuex';
import { formatFormItem, removeAllNonItem } from "../../utils/global";
import _ from 'loadsh';
import { parseStyles } from "../../utils/style";
export default {
  components: {
    draggable: draggable
  },
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      drawerVisible: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['collection'])), {}, {
    drawerClass: function drawerClass() {
      return this.data.field.children.length === 0 ? 'no-field' : '';
    },
    styles: function styles() {
      return parseStyles(this.data.field.styles, this.data.field.props);
    },
    drawerStyle: function drawerStyle() {
      var style = {};
      if (this.drawerVisible) {
        style.height = 'auto';
      }
      return style;
    },
    iconClass: function iconClass() {
      return this.drawerVisible ? 'active' : '';
    },
    dragOptions: function dragOptions() {
      return {
        animation: 200,
        group: 'component',
        handle: '.move-bar'
      };
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapMutations({
    addFormItemToDrawer: 'ADD_DRAWER_FORM_ITEM'
  })), {}, {
    handleToggleDrawer: function handleToggleDrawer() {
      this.drawerVisible = !this.drawerVisible;
    },
    onFieldAdd: function onFieldAdd(data) {
      removeAllNonItem();
      var isSort = data.item.className.includes('form-drag-warp');
      if (isSort) return;
      var id = data.item.getAttribute('field-id');
      var parentId = data.to.getAttribute('data-parent');
      var field = formatFormItem(_.cloneDeep(this.collection.find(function (item) {
        return item.id === id;
      })));
      field.parentId = parentId;
      field.parentType = 'buttonDrawer';
      var obj = {
        parentId: parentId,
        field: field,
        index: data.newIndex
      };
      this.addFormItemToDrawer(obj);
    }
  })
};