import { Drag, TextUi, BarcodeUi, TextMenu, BarcodeMenu, QrCodeUi, QrCodeMenu, XLineUi, YLineUi, DragResize, LineMenu, RectangleUi, RectangleMenu } from "./index";
export default [{
  id: 'Drag',
  component: Drag
}, {
  id: 'DragResize',
  component: DragResize
}, {
  id: 'TextUi',
  component: TextUi
}, {
  id: 'BarcodeUi',
  component: BarcodeUi
}, {
  id: 'TextMenu',
  component: TextMenu
}, {
  id: 'BarcodeMenu',
  component: BarcodeMenu
}, {
  id: 'QrCodeUi',
  component: QrCodeUi
}, {
  id: 'QrCodeMenu',
  component: QrCodeMenu
}, {
  id: 'XLineUi',
  component: XLineUi
}, {
  id: 'YLineUi',
  component: YLineUi
}, {
  id: 'LineMenu',
  component: LineMenu
}, {
  id: 'RectangleUi',
  component: RectangleUi
}, {
  id: 'RectangleMenu',
  component: RectangleMenu
}];