var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container-warp",
      class: _vm.data.field.paramsKey,
      style: _vm.styles,
    },
    _vm._l(_vm.data.field.children, function (item) {
      return _c("set-component", {
        key: item.elementId,
        attrs: { data: item, "element-id": item.elementId },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }