exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".skeleton-row{background:#f2f2f2}.skeleton-row.active{background:-webkit-gradient(linear, left top, right top, color-stop(25%, #f2f2f2), color-stop(37%, #e6e6e6), color-stop(63%, #f2f2f2));background:linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);-webkit-animation:skeleton-loading .3s ease infinite;animation:skeleton-loading .3s ease infinite;background-size:400% 100%}@-webkit-keyframes skeleton-loading{0%{background-position:100% 50%}100%{background-position:0 50%}}@keyframes skeleton-loading{0%{background-position:100% 50%}100%{background-position:0 50%}}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#E60000",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#fff",
	"menuHover": "#2d325f",
	"subMenuBg": "#222750",
	"subMenuHover": "#1b1e3e",
	"sideBarWidth": "240px"
};