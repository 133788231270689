//
//
//
//

import QrCode from 'qrcode';
// import { on, off } from '@/ylCommon/utils/dom.js'
export default {
  props: {
    elementId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    getStyle: function getStyle() {
      return {
        maxWidth: '100%',
        verticalAlign: 'middle',
        userSelect: 'none'
      };
    }
  },
  destroyed: function destroyed() {
    // const that = this;
    // off(that.$refs.img, 'onload', that.complete)
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    complete: function complete() {
      this.$emit('complete');
    },
    init: function init() {
      var that = this;
      var $img = this.$refs.img;
      that.complete();
      QrCode.toDataURL('http://192.168.11.80:8080/', {
        errorCorrectionLevel: 'H',
        type: 'image/jpeg',
        color: {}
      }, function (err, res) {
        if (err) {
          throw err;
        }
        $img.src = res;
      });
    }
  }
};