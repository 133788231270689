var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.data.field.type + "Item", {
    tag: "component",
    attrs: {
      data: _vm.data,
      "element-id": _vm.data.elementId,
      context: _vm.context,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }