// class Verify {
//   static getVerifyByConfig(config) {
//     const requiredMsg = (key) => {
//       return config.requiredMessage ? config.requiredMessage : `请输入${key}`
//     }
//     const trigger = () => {
//       return config.trigger ? config.trigger : 'change'
//     }
//     const validMsgForLength = (min, max) => {
//       return config.validMessage ? config.validMessage : `${config.messageKey ? config.messageKey : ''}${'长度为'}${min} ~ ${max}${'字符'}`
//     }
//     const validator = (config) => {

//     }
//     const validate = {
//       country: [
//         { required: true, message: requiredMsg(config.messageKey), trigger: trigger() }
//       ],
//       routeId: [
//         { required: true, message: requiredMsg(config.messageKey), trigger: trigger() }
//       ],
//       orderType: [
//         { required: true, message: requiredMsg(config.messageKey), trigger: trigger() }
//       ],
//       paymentId: [
//         { required: true, message: requiredMsg(config.messageKey), trigger: trigger() }
//       ],
//       orderNo: [
//         { required: true, message: requiredMsg(config.messageKey), trigger: trigger() }
//       ],
//       billcode: [
//         { required: true, message: requiredMsg(config.messageKey), trigger: trigger() }
//       ],
//       name: [
//         { required: true, message: requiredMsg(config.messageKey), trigger: trigger() },
//         { min: 1, max: 50, message: validMsgForLength(1, 50), trigger: trigger() }
//       ],
//       phone: [
//         { required: true, message: requiredMsg(config.messageKey), trigger: 'change', validator: config.validator }
//       ],
//       address: [
//         { required: true, message: requiredMsg(config.messageKey), trigger: trigger() }
//       ],
//       cod: [
//         { required: true, validator: config.validator ? config.validator : validator(config), trigger: trigger() }
//       ],
//       weight: [
//         { required: true, message: requiredMsg(config.messageKey), trigger: trigger() }
//       ],
//       cname: [
//         { required: true, message: requiredMsg(config.messageKey), trigger: trigger() },
//         { min: 1, max: 50, message: validMsgForLength(1, 50), trigger: trigger() }
//       ],
//       ename: [
//         { required: true, message: requiredMsg(config.messageKey), trigger: trigger() },
//         { min: 1, max: 50, message: validMsgForLength(1, 50), trigger: trigger() }
//       ],
//       expressType: [
//         { required: true, message: requiredMsg(config.messageKey), trigger: trigger() }
//       ],
//       goodsValue: [
//         { required: false, message: requiredMsg(config.messageKey), trigger: trigger() }
//       ],
//       remarks: [
//         { min: 0, max: 250, message: validMsgForLength(0, 250), trigger: trigger() }
//       ],
//       payMethod: [
//         { required: true, message: '请选择付款方式', trigger: trigger() }
//       ]
//     }
//     return validate[config.name]
//   }
// }

// export default Verify