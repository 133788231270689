import fetch from '@/ylCommon/utils/request';
var dashboard = {
  getData: function getData(options) {
    var params = {
      parameter: options
    };
    return fetch({
      url: '/getData.do',
      data: params,
      method: 'post'
    });
  },
  getAllOrderCount: function getAllOrderCount(options) {
    var params = {
      parameter: options
    };
    return fetch({
      url: '/getAllOrderCount.do',
      data: params,
      method: 'post'
    });
  },
  getDatatrends: function getDatatrends(options) {
    var params = {
      parameter: options
    };
    return fetch({
      url: '/getDatatrends.do',
      data: params,
      method: 'post'
    });
  },
  getOrderBatchTrack: function getOrderBatchTrack(options) {
    var params = {
      parameter: options
    };
    return fetch({
      url: '/getOrderBatchTrack.do',
      data: params,
      method: 'post'
    });
  },
  getRejectReason: function getRejectReason(options) {
    var params = {
      parameter: options
    };
    return fetch({
      url: '/getRejectReason.do',
      data: params,
      method: 'post'
    });
  },
  getAreaSignCount: function getAreaSignCount(options) {
    var params = {
      parameter: options
    };
    return fetch({
      url: '/getAreaSignCount.do',
      data: params,
      method: 'post'
    });
  }
};
export default dashboard;