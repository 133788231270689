import "core-js/modules/es6.function.name";
import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import Layout from '@/layout';
var permissionIndex = function permissionIndex() {
  return import('@/views/setting/permission/index.vue');
};
var permissionMenu = function permissionMenu() {
  return import('@/views/setting/permission/menu/index.vue');
};
var permissionRoute = function permissionRoute() {
  return import('@/views/setting/permission/route/index.vue');
};
var permissionRole = function permissionRole() {
  return import('@/views/permission/role/index.vue');
};
var permissionRoleDetail = function permissionRoleDetail() {
  return import('@/views/permission/role/detail/index.vue');
};
var permissionRolePersonal = function permissionRolePersonal() {
  return import('@/views/permission/role/personal/index.vue');
};
var permissionChangePassword = function permissionChangePassword() {
  return import('@/views/permission/role/changePassword/index.vue');
};
var loginIndex = function loginIndex() {
  return import('@/views/login/yunluLogin');
};
var notfoundIndex = function notfoundIndex() {
  return import('@/views/errorPage/404');
};
var errorIndex = function errorIndex() {
  return import('@/views/errorPage/401');
};
var B2BManageIndex = function B2BManageIndex() {
  return import('@/views/B2B/B2Bmanage/index');
};
var B2BEntry = function B2BEntry() {
  return import('@/views/B2B/B2Bmanage/B2BorderEntry');
};
var B2BImport = function B2BImport() {
  return import('@/views/B2B/B2Bmanage/B2BImport');
};
var B2BOrderMange = function B2BOrderMange() {
  return import('@/views/B2B/B2Bmanage/B2BorderManage');
};
var B2BOrderQuery = function B2BOrderQuery() {
  return import('@/views/B2B/B2Bmanage/B2BorderQuery');
};
var B2BOrderEdit = function B2BOrderEdit() {
  return import('@/views/B2B/B2Bmanage/B2BorderEdit');
};
var shippingAbroad = function shippingAbroad() {
  return import('@/views/B2B/shippingAbroad/index');
};
var InventoryCheck = function InventoryCheck() {
  return import('@/views/B2B/shippingAbroad/InventoryCheck');
};
var B2CMange = function B2CMange() {
  return import('@/views/B2C/B2Cmanage/index');
};
var B2COrderEntry = function B2COrderEntry() {
  return import('@/views/B2C/B2Cmanage/B2CorderEntry');
};
var B2COrderEntryUS = function B2COrderEntryUS() {
  return import('@/views/B2C/B2Cmanage/B2CorderEntryUS');
};
var B2COrderMange = function B2COrderMange() {
  return import('@/views/B2C/B2Cmanage/B2CorderManage');
};
var B2CorderForecast = function B2CorderForecast() {
  return import('@/views/B2C/B2Cmanage/B2CorderForecast');
};
var B2COrderEdit = function B2COrderEdit() {
  return import('@/views/B2C/B2Cmanage/B2CorderEdit');
};
var B2COrderEditUS = function B2COrderEditUS() {
  return import('@/views/B2C/B2Cmanage/B2CorderEditUS');
};
var B2COrderQuery = function B2COrderQuery() {
  return import('@/views/B2C/B2Cmanage/B2CorderQuery');
};
var B2COrderReturn = function B2COrderReturn() {
  return import('@/views/B2C/B2Cmanage/B2CorderReturn');
};
var B2CUnusualCOD = function B2CUnusualCOD() {
  return import('@/views/B2C/B2Cmanage/B2CunusualCOD');
};
var B2COverTax = function B2COverTax() {
  return import('@/views/B2C/B2Cmanage/B2CorderOvertax');
};
var B2CImport = function B2CImport() {
  return import('@/views/B2C/B2Cmanage/B2CorderImport/index.vue');
};
var B2CImportUS = function B2CImportUS() {
  return import('@/views/B2C/B2Cmanage/B2CorderImportUS/index.vue');
};
var b2COrderImportForreturn = function b2COrderImportForreturn() {
  return import('@/views/B2C/B2Cmanage/b2COrderImportForreturn/index.vue');
};
var b2COrderForzy = function b2COrderForzy() {
  return import('@/views/B2C/B2Cmanage/b2COrderForzy/index.vue');
};
var RTOrderEntry = function RTOrderEntry() {
  return import('@/views/B2C/B2Cmanage/RTOrderEntry/index.vue');
};
var BulkPackageEntry = function BulkPackageEntry() {
  return import('@/views/B2C/B2Cmanage/BulkPackageEntry/index.vue');
};
var B2CCollectMange = function B2CCollectMange() {
  return import('@/views/B2C/CollectMange/index');
};
var B2CArrivalForecast = function B2CArrivalForecast() {
  return import('@/views/B2C/CollectMange/ArrivalForecast');
};
var B2CArrivalInquiry = function B2CArrivalInquiry() {
  return import('@/views/B2C/CollectMange/ArrivalInquiry');
};
var B2CFastShipping = function B2CFastShipping() {
  return import('@/views/B2C/CollectMange/FastShipping');
};
var B2CFastShippingImport = function B2CFastShippingImport() {
  return import('@/views/B2C/CollectMange/FastShippingImport');
};
var B2CShippingQuery = function B2CShippingQuery() {
  return import('@/views/B2C/CollectMange/ShippingInquiry');
};
var NoFileMange = function NoFileMange() {
  return import('@/views/B2C/NoFileMange/index');
};
var NoFileClaim = function NoFileClaim() {
  return import('@/views/B2C/NoFileMange/NoFileClaim');
};
var ClaimManage = function ClaimManage() {
  return import('@/views/B2C/NoFileMange/ClaimManage');
};
var B2CShippingAbroad = function B2CShippingAbroad() {
  return import('@/views/B2C/shippingAbroad/index');
};
var B2CInventoryCheck = function B2CInventoryCheck() {
  return import('@/views/B2C/shippingAbroad/InventoryCheck');
};
var WholePackageDelivery = function WholePackageDelivery() {
  return import('@/views/B2C/shippingAbroad/wholepackageDelivery');
};
var GoodsDetailQuery = function GoodsDetailQuery() {
  return import('@/views/B2C/shippingAbroad/goodsdetailQuery');
};
var StatisticalManage = function StatisticalManage() {
  return import('@/views/B2C/StatisticalManage/index');
};
var CargoStatusQuery = function CargoStatusQuery() {
  return import('@/views/B2C/StatisticalManage/cargostatusQuery');
};
var CargoBatchQuery = function CargoBatchQuery() {
  return import('@/views/B2C/StatisticalManage/cargobatchQuery');
};
var RejectAnalysis = function RejectAnalysis() {
  return import('@/views/B2C/StatisticalManage/rejectAnalysis');
};
var WeightQuery = function WeightQuery() {
  return import('@/views/B2C/StatisticalManage/weightQuery');
};
var FinanceManageCopy = function FinanceManageCopy() {
  return import('@/views/B2C/FinanceManageCopy/index');
};
var FinanceResult = function FinanceResult() {
  return import('@/views/B2C/FinanceManageCopy/financeResult');
};
var AccountRecharge = function AccountRecharge() {
  return import('@/views/B2C/FinanceManageCopy/accountRecharge');
};
var RechargeRecordQuery = function RechargeRecordQuery() {
  return import('@/views/B2C/FinanceManageCopy/rechargeRecordQuery');
};
var AccountFlowInquiry = function AccountFlowInquiry() {
  return import('@/views/B2C/FinanceManageCopy/accountFlowInquiry');
};
var AuditFordz = function AuditFordz() {
  return import('@/views/B2C/B2Cmanage/AuditFordz');
};
var OrderReview = function OrderReview() {
  return import('@/views/B2C/B2Cmanage/OrderReview');
};
var DestinationArea = function DestinationArea() {
  return import('@/views/B2C/DestinationArea/index');
};
var DestinationAreaQuery = function DestinationAreaQuery() {
  return import('@/views/B2C/DestinationArea/destinationAreaQuery');
};
var MicroWarehouse = function MicroWarehouse() {
  return import('@/views/MicroWarehouse/orderManage/index');
};
var ProductInfoManage = function ProductInfoManage() {
  return import('@/views/MicroWarehouse/orderManage/ProductInfoManage');
};
var InboundTicket = function InboundTicket() {
  return import('@/views/MicroWarehouse/inManageForyc/InboundTicket');
};
var InboundOrderManage = function InboundOrderManage() {
  return import('@/views/MicroWarehouse/inManageForyc/InboundOrderManage');
};
var OutboundOrderEntry = function OutboundOrderEntry() {
  return import('@/views/MicroWarehouse/outManageForyc/OutboundOrderEntry');
};
var OutboundOrderManage = function OutboundOrderManage() {
  return import('@/views/MicroWarehouse/outManageForyc/OutboundOrderManage');
};
var WareInventoryCheck = function WareInventoryCheck() {
  return import('@/views/MicroWarehouse/InventoryCheckMg/InventoryCheck');
};
var WarehouseTransferOrder = function WarehouseTransferOrder() {
  return import('@/views/MicroWarehouse/WarehouseTransferOrderMg/WarehouseTransferOrder');
};
var ImeiQuery = function ImeiQuery() {
  return import('@/views/MicroWarehouse/imeiQueryMg/imeiQuery/index');
};
var ExportTaskMange = function ExportTaskMange() {
  return import('@/views/TaskManagement/exportTask/index');
};
var ExportTaskCheck = function ExportTaskCheck() {
  return import('@/views/TaskManagement/exportTask/exportTaskCheck');
};
var ImportTaskMange = function ImportTaskMange() {
  return import('@/views/TaskManagement/importTask/index');
};
var ImportTaskCheck = function ImportTaskCheck() {
  return import('@/views/TaskManagement/importTask/importTaskCheck');
};
var MaterialsManage = function MaterialsManage() {
  return import('@/views/BasicDatamanage/materialsManage/index');
};
var FaceListManage = function FaceListManage() {
  return import('@/views/BasicDatamanage/materialsManage/faceListManage');
};
var ExpressManage = function ExpressManage() {
  return import('@/views/BasicDatamanage/expressManage/index');
};
var ExpressQuery = function ExpressQuery() {
  return import('@/views/BasicDatamanage/expressManage/expressQuery');
};
var FinanceManage = function FinanceManage() {
  return import('@/views/BasicDatamanage/financeManage/index');
};
var FinancialSettle = function FinancialSettle() {
  return import('@/views/BasicDatamanage/financeManage/FinancialSettle');
};
var CusServiceManage = function CusServiceManage() {
  return import('@/views/BasicDatamanage/cusServiceManage/index');
};
var ComplainSuggest = function ComplainSuggest() {
  return import('@/views/BasicDatamanage/cusServiceManage/complainSuggest');
};
var PersonManage = function PersonManage() {
  return import('@/views/BasicDatamanage/personManage/index');
};
var DeclaredManage = function DeclaredManage() {
  return import('@/views/BasicDatamanage/DeclaredManage/index');
};
var SelfAddress = function SelfAddress() {
  return import('@/views/BasicDatamanage/personManage/selfAddress');
};
var DeclaredGoods = function DeclaredGoods() {
  return import('@/views/BasicDatamanage/DeclaredManage/DeclaredGoods');
};
var ReceivesAddress = function ReceivesAddress() {
  return import('@/views/BasicDatamanage/personManage/receivesAddress');
};
var PermissionMange = function PermissionMange() {
  return import('@/views/BasicDatamanage/roleManage/index');
};
var DashboardIndex = function DashboardIndex() {
  return import('@/views/dashboard/Clientindex');
};
var UserManage = function UserManage() {
  return import('@/views/BasicDatamanage/roleManage/userManageNew');
};
var I18nIndex = function I18nIndex() {
  return import('@/views/setting/i18n/index.vue');
};
var TableIndex = function TableIndex() {
  return import('@/views/setting/table/index.vue');
};
var TableAdd = function TableAdd() {
  return import('@/views/setting/table/add.vue');
};
var RouteIndex = function RouteIndex() {
  return import('@/views/setting/permission/route/index.vue');
};
var ButtonIndex = function ButtonIndex() {
  return import('@/views/setting/permission/button/index.vue');
};
var B2cButton = function B2cButton() {
  return import('@/views/setting/permission/button/index.vue');
};
var B2cButtonPrint = function B2cButtonPrint() {
  return import('@/views/setting/permission/button/index.vue');
};
var B2cButtonEdit = function B2cButtonEdit() {
  return import('@/views/setting/permission/button/index.vue');
};
var B2cButtonReturn = function B2cButtonReturn() {
  return import('@/views/setting/permission/button/index.vue');
};
var B2cButtonCancel = function B2cButtonCancel() {
  return import('@/views/setting/permission/button/index.vue');
};
var B2cExportCancel = function B2cExportCancel() {
  return import('@/views/setting/permission/button/index.vue');
};
var MenuIndex = function MenuIndex() {
  return import('@/views/setting/permission/menu/index.vue');
};
var ImportIndex = function ImportIndex() {
  return import('@/views/setting/import/index.vue');
};
var ImportAdd = function ImportAdd() {
  return import('@/views/setting/import/add/index.vue');
};
var permissionRule = function permissionRule() {
  return import('@/views/setting/permission/rule/index.vue');
};
var systemFilter = function systemFilter() {
  return import('@/views/setting/filter/index.vue');
};
var systemFilterAdd = function systemFilterAdd() {
  return import('@/views/setting/filter/add.vue');
};
var SystemSettingField = function SystemSettingField() {
  return import('@/views/setting/field/index.vue');
};
var SystemSettingFieldAdd = function SystemSettingFieldAdd() {
  return import('@/views/setting/field/add/index.vue');
};
var SystemSettingDesignForm = function SystemSettingDesignForm() {
  return import('@/views/setting/design/form/index.vue');
};
var PageSettingTable = function PageSettingTable() {
  return import('@/views/setting/queryPage/index.vue');
};
var PageSettingAdd = function PageSettingAdd() {
  return import('@/views/setting/queryPage/add.vue');
};
var UnPackBatchImport = function UnPackBatchImport() {
  return import('@/views/B2C/shippingAbroad/unPackImport/index.vue');
};
var CloudWarehouse = function CloudWarehouse() {
  return import('@/views/CloudWarehouse/index.vue');
};
var CloudWarehousePickOrder = function CloudWarehousePickOrder() {
  return import('@/views/CloudWarehouse/PickOrder/index.vue');
};
var CloudWarehouseOutOrder = function CloudWarehouseOutOrder() {
  return import('@/views/CloudWarehouse/OutOrder/index.vue');
};
var DynamicRoute = function DynamicRoute() {
  return import('@/views/query/index.vue');
};
var microWarehouseGoodsCreate = function microWarehouseGoodsCreate() {
  return import('@/views/MicroWarehouse/orderManage/GoodsCreate/index.vue');
};
var microWarehouseBatchForecast = function microWarehouseBatchForecast() {
  return import('@/views/MicroWarehouse/inManageForyc/WarehouseBatchForecast/index.vue');
};
var microWarehouseOutboundOrderImport = function microWarehouseOutboundOrderImport() {
  return import('@/views/MicroWarehouse/outManageForyc/OutboundOrderImport/index.vue');
};
var FBAManageIndex = function FBAManageIndex() {
  return import('@/views/FBA/FBAmanage/index');
};
var FBAEntry = function FBAEntry() {
  return import('@/views/FBA/FBAmanage/FBAorderEntry');
};
var FBAImport = function FBAImport() {
  return import('@/views/FBA/FBAmanage/FBAorderImport');
};
var FBAOrderMange = function FBAOrderMange() {
  return import('@/views/FBA/FBAmanage/FBAorderManage');
};
var SellerAssistantManageIndex = function SellerAssistantManageIndex() {
  return import('@/views/SellerAssistant/index');
};
var ShopList = function ShopList() {
  return import('@/views/SellerAssistant/ShopList');
};
var SellerOrderManage = function SellerOrderManage() {
  return import('@/views/SellerAssistant/SellerOrderManage');
};
var B2CSellerOrderManage = function B2CSellerOrderManage() {
  return import('@/views/SellerAssistant/B2CSellerOrderManage');
};
var NewSellerOrderManage = function NewSellerOrderManage() {
  return import('@/views/SellerAssistant/NewSellerOrderManage');
};
var NewSellerOrderEdit = function NewSellerOrderEdit() {
  return import('@/views/SellerAssistant/NewSellerOrderManage/editIndex');
};
var SellerProductManage = function SellerProductManage() {
  return import('@/views/SellerAssistant/SellerProductManage');
};
// const SkuManage = () => import('@/views/SellerAssistant/SkuManage')
var SkuMapping = function SkuMapping() {
  return import('@/views/SellerAssistant/SkuMapping');
};
var CloudWarehouseSkuMapping = function CloudWarehouseSkuMapping() {
  return import('@/views/SellerAssistant/CloudWarehouseSkuMapping');
};
var SellerOrderEntry = function SellerOrderEntry() {
  return import('@/views/SellerAssistant/SellerOrderEntry');
};
var SellerForecastOrder = function SellerForecastOrder() {
  return import('@/views/SellerAssistant/SellerForecastOrder');
};
var SellerOutboundOrder = function SellerOutboundOrder() {
  return import('@/views/SellerAssistant/SellerOutboundOrder');
};
var SellerOrderEdit = function SellerOrderEdit() {
  return import('@/views/SellerAssistant/SellerOrderEdit');
};
var BeginnersGuide = function BeginnersGuide() {
  return import('@/views/ServiceCenter/BeginnersGuide');
};
var OpenAccount = function OpenAccount() {
  return import('@/views/ServiceCenter/BeginnersGuide/OpenAccount/index');
};
var BasicSettings = function BasicSettings() {
  return import('@/views/ServiceCenter/BeginnersGuide/BasicSettings/index');
};
var PlaceOrder = function PlaceOrder() {
  return import('@/views/ServiceCenter/BeginnersGuide/PlaceOrder/index');
};
var NewcomerDelivery = function NewcomerDelivery() {
  return import('@/views/ServiceCenter/BeginnersGuide/NewcomerDelivery/index');
};
var CargoTracking = function CargoTracking() {
  return import('@/views/ServiceCenter/BeginnersGuide/CargoTracking/index');
};
var SettlementFee = function SettlementFee() {
  return import('@/views/ServiceCenter/BeginnersGuide/SettlementFee/index');
};
var ShippingInstructions = function ShippingInstructions() {
  return import('@/views/ServiceCenter/ShippingInstructions');
};
var MyKeyForOrder = function MyKeyForOrder() {
  return import('@/views/ServiceCenter/ShippingInstructions/MyKeyList');
};
var VipPlaceOrder = function VipPlaceOrder() {
  return import('@/views/ServiceCenter/ShippingInstructions/VipPlaceOrder');
};
// const VipPlaceOrder = () => import('@/views/ServiceCenter/ShippingInstructions/VipPlaceOrder/B2cPrecautions/index')
var B2cPrecautions = function B2cPrecautions() {
  return import('@/views/ServiceCenter/ShippingInstructions/VipPlaceOrder/B2cPrecautions/index');
};
var StockOrder = function StockOrder() {
  return import('@/views/ServiceCenter/ShippingInstructions/VipPlaceOrder/StockOrder/index');
};
var ApiDockingOrder = function ApiDockingOrder() {
  return import('@/views/ServiceCenter/ShippingInstructions/ApiDockingOrder/index');
};
var ErpDockingOrder = function ErpDockingOrder() {
  return import('@/views/ServiceCenter/ShippingInstructions/ErpDockingOrder/index');
};
var VipSystemOperation = function VipSystemOperation() {
  return import('@/views/ServiceCenter/VipSystemOperation');
};
var VsoCheckOrder = function VsoCheckOrder() {
  return import('@/views/ServiceCenter/VipSystemOperation/VsoCheckOrder/index');
};
var VsoPlaceOrder = function VsoPlaceOrder() {
  return import('@/views/ServiceCenter/VipSystemOperation/VsoPlaceOrder/index');
};
var VsoAbnormalOperation = function VsoAbnormalOperation() {
  return import('@/views/ServiceCenter/VipSystemOperation/VsoAbnormalOperation/index');
};
var B2cSmallPackageDescription = function B2cSmallPackageDescription() {
  return import('@/views/ServiceCenter/B2cSmallPackageDescription');
};
var SpBasicInstructions = function SpBasicInstructions() {
  return import('@/views/ServiceCenter/B2cSmallPackageDescription/SpBasicInstructions/index');
};
var SpClaimStandard = function SpClaimStandard() {
  return import('@/views/ServiceCenter/B2cSmallPackageDescription/SpClaimStandard/index');
};
var SpExportRestrictions = function SpExportRestrictions() {
  return import('@/views/ServiceCenter/B2cSmallPackageDescription/SpExportRestrictions/index');
};
var SpTimeLimit = function SpTimeLimit() {
  return import('@/views/ServiceCenter/B2cSmallPackageDescription/SpTimeLimit/index');
};
var SpBasicPrice = function SpBasicPrice() {
  return import('@/views/ServiceCenter/B2cSmallPackageDescription/SpBasicPrice/index');
};
var B2bBulkDescription = function B2bBulkDescription() {
  return import('@/views/ServiceCenter/B2bBulkDescription');
};
var BulkDescription = function BulkDescription() {
  return import('@/views/ServiceCenter/B2bBulkDescription/BulkDescription/index');
};
var WarehouseAddress = function WarehouseAddress() {
  return import('@/views/ServiceCenter/WarehouseAddress');
};
var DomesticWarehouse = function DomesticWarehouse() {
  return import('@/views/ServiceCenter/WarehouseAddress/DomesticWarehouse/index');
};
var OverseasWarehouse = function OverseasWarehouse() {
  return import('@/views/ServiceCenter/WarehouseAddress/OverseasWarehouse/index');
};
var CommonProblem = function CommonProblem() {
  return import('@/views/ServiceCenter/CommonProblem');
};
var CompanyService = function CompanyService() {
  return import('@/views/ServiceCenter/CommonProblem/CompanyService/index');
};
var systemSettingDesignFaceSheet = function systemSettingDesignFaceSheet() {
  return import('@/views/setting/design/faceSheet/index.vue');
};
var LowCodeIndex = function LowCodeIndex() {
  return import('@/views/setting/design/lowCode/index.vue');
};
var workOrderAccess = function workOrderAccess() {
  return import('@/views/workOrderManage/index.vue');
};
var claimWorkOrder = function claimWorkOrder() {
  return import('@/views/workOrderManage/claimWorkOrder.vue');
};
var inspectionWorkOrder = function inspectionWorkOrder() {
  return import('@/views/workOrderManage/inspectionWorkOrder.vue');
};
var otherWorkOrders = function otherWorkOrders() {
  return import('@/views/workOrderManage/otherWorkOrders.vue');
};
var returnManage = function returnManage() {
  return import('@/views/MicroWarehouse/returnManage/index');
};
var returnForecast = function returnForecast() {
  return import('@/views/MicroWarehouse/returnManage/returnForecast/index');
};
var batchForecast = function batchForecast() {
  return import('@/views/MicroWarehouse/returnManage/batchForecast/index');
};
var returnOrderManage = function returnOrderManage() {
  return import('@/views/MicroWarehouse/returnManage/returnOrderManage/index');
};
var headlessManage = function headlessManage() {
  return import('@/views/MicroWarehouse/returnManage/headlessManage/index');
};
var InterceptCreate = function InterceptCreate() {
  return import('@/views/B2C/B2Cmanage/InterceptCreate/index');
};
var InterceptManage = function InterceptManage() {
  return import('@/views/B2C/B2Cmanage/InterceptManage/index');
};
var AbnormalOrder = function AbnormalOrder() {
  return import('@/views/B2C/B2Cmanage/AbnormalOrder/index');
};
var B2COrderWeight = function B2COrderWeight() {
  return import('@/views/B2C/B2Cmanage/B2COrderWeight');
};
var goodManage = function goodManage() {
  return import('@/views/MicroWarehouse/goodManage/index.vue');
};
var CreateGoodsNew = function CreateGoodsNew() {
  return import('@/views/MicroWarehouse/goodManage/CreateGoodsNew');
};
var BulkCreate = function BulkCreate() {
  return import('@/views/MicroWarehouse/goodManage/BulkCreate');
};
var GoodsManagement = function GoodsManagement() {
  return import('@/views/MicroWarehouse/goodManage/GoodsManagement');
};
var GoodsEdit = function GoodsEdit() {
  return import('@/views/MicroWarehouse/goodManage/GoodsEdit');
};
var GoodsCopy = function GoodsCopy() {
  return import('@/views/MicroWarehouse/goodManage/GoodsCopy');
};
var GoodsDetail = function GoodsDetail() {
  return import('@/views/MicroWarehouse/goodManage/GoodsDetail');
};
var BulkPrint = function BulkPrint() {
  return import('@/views/MicroWarehouse/goodManage/BulkPrint');
};
var inManageForyc = function inManageForyc() {
  return import('@/views/MicroWarehouse/inManageForyc/index');
}; // 入库管理
var outManageForyc = function outManageForyc() {
  return import('@/views/MicroWarehouse/outManageForyc/index');
}; // 出库管理
var InventoryCheckMg = function InventoryCheckMg() {
  return import('@/views/MicroWarehouse/InventoryCheckMg/index');
}; // 库存查询
var WarehouseTransferOrderMg = function WarehouseTransferOrderMg() {
  return import('@/views/MicroWarehouse/WarehouseTransferOrderMg/index');
}; // 仓储转单管理
var imeiQueryMg = function imeiQueryMg() {
  return import('@/views/MicroWarehouse/imeiQueryMg/index');
}; // IMEI查询
var PdfUpload = function PdfUpload() {
  return import('@/views/MicroWarehouse/pdfUpload/index');
}; // PDF批量上传
var pdfUploadBybatch = function pdfUploadBybatch() {
  return import('@/views/MicroWarehouse/pdfUpload/pdfUploadBybatch/index');
}; // PDF批量上传
var routerMap = [{
  path: '',
  component: Layout,
  redirect: 'Dashboard',
  name: 'Home',
  alwaysShow: true,
  children: [{
    path: 'dashboard',
    component: DashboardIndex,
    name: 'Dashboard',
    meta: {
      title: '首页看板',
      icon: 'dashboard'
    }
  }]
}, {
  path: '/permission',
  name: 'Permission',
  component: Layout,
  redirect: '/permission/index'
}, {
  path: 'index',
  name: 'PermissionIndex',
  component: permissionIndex
}, {
  path: 'menu',
  name: 'PermissionMenu',
  component: permissionMenu,
  meta: {
    title: '权限菜单'
  }
}, {
  path: 'route',
  name: 'PermissionRoute',
  component: permissionRoute,
  meta: {
    title: '权限路由'
  }
}, {
  path: 'role',
  name: 'PermissionRole',
  component: permissionRole,
  meta: {
    title: '角色权限'
  }
}, {
  path: 'roleDetail',
  name: 'PermissionRoleDetail',
  component: permissionRoleDetail,
  hidden: true,
  meta: {
    title: '角色详情'
  }
}, {
  path: 'rolePersonal',
  name: 'PermissionRolePersonal',
  component: permissionRolePersonal,
  meta: {
    title: '个人权限'
  }
}, {
  path: 'roleChangePassword',
  name: 'PermissionChangePassword',
  component: permissionChangePassword,
  meta: {
    title: '修改密码'
  }
}, {
  path: '/login',
  name: 'login',
  component: loginIndex,
  hidden: true
}, {
  path: '/404',
  name: '404',
  component: notfoundIndex,
  hidden: true
}, {
  path: '/401',
  name: '401',
  component: errorIndex,
  hidden: true
}, {
  path: '/b2bOrder',
  component: Layout,
  redirect: '',
  name: 'B2BOrder',
  alwaysShow: true,
  meta: {
    title: 'B2B业务',
    icon: 'B2Border'
  }
}, {
  path: 'b2bManage',
  component: B2BManageIndex,
  redirect: '',
  name: 'B2BManage',
  alwaysShow: true,
  meta: {
    title: '单证管理',
    icon: 'B2Border'
  }
},
// {
//   path: 'StatisticalManage',
//   component: StatisticalManage,
//   redirect: '',
//   name: 'StatisticalManage',
//   alwaysShow: true,
//   meta: {
//     title: '统计管理',
//     icon: 'dataManage'
//   }
// },
// {
//   path: 'cargoStatusQuery',
//   component: CargoStatusQuery,
//   name: 'CargoStatusQuery',
//   meta: {
//     title: '货态查询'
//   }
// },
{
  path: 'FinanceManageCopy',
  component: FinanceManageCopy,
  redirect: '',
  name: 'FinanceManageCopy',
  alwaysShow: true,
  meta: {
    title: '财务管理',
    icon: 'financeManage'
  }
}, {
  path: 'financeResult',
  component: FinanceResult,
  name: 'FinanceResult',
  meta: {
    title: '财务结算'
  }
}, {
  path: 'accountRecharge',
  component: AccountRecharge,
  name: 'AccountRecharge',
  meta: {
    title: '账户充值'
  }
}, {
  path: 'rechargeRecordQuery',
  component: RechargeRecordQuery,
  name: 'RechargeRecordQuery',
  meta: {
    title: '充值记录查询'
  }
}, {
  path: 'accountFlowInquiry',
  component: AccountFlowInquiry,
  name: 'AccountFlowInquiry',
  meta: {
    title: '账户流水查询'
  }
},
// {
//   path: 'financeManageCopy',
//   component: FinanceManageCopy,
//   redirect: '',
//   name: 'financeManageCopy',
//   alwaysShow: true,
//   meta: {
//     title: '财务管理',
//     icon: 'financeManage'
//   }
// },
// {
//   path: 'FinanceResult',
//   component: FinanceResult,
//   name: 'FinanceResult',
//   meta: {
//     title: '财务结算'
//   }
// },
{
  path: 'b2borderEntry',
  component: B2BEntry,
  name: 'B2BOrderEntry',
  meta: {
    title: '订单录入'
  }
}, {
  path: 'b2borderImport',
  component: B2BImport,
  name: 'B2BOrderImport',
  meta: {
    title: '订单导入'
  }
}, {
  path: 'b2borderManage',
  component: B2BOrderMange,
  name: 'B2BOrderManage',
  meta: {
    title: '订单管理'
  }
}, {
  path: 'b2borderQuery',
  component: B2BOrderQuery,
  name: 'B2BOrderQuery',
  meta: {
    title: '订单查询'
  }
}, {
  path: 'b2borderEdit',
  component: B2BOrderEdit,
  name: 'B2BOrderEdit',
  meta: {
    title: '订单编辑'
  }
}, {
  path: 'shippingAbroad',
  component: shippingAbroad,
  redirect: '',
  name: 'ShippingAbroad',
  alwaysShow: true,
  meta: {
    title: '国外发货',
    icon: 'shopping'
  }
}, {
  path: 'InventoryCheck',
  component: InventoryCheck,
  name: 'InventoryCheck',
  meta: {
    title: '库存查询'
  }
}, {
  path: '/b2cOrder',
  component: Layout,
  name: 'B2COrder',
  alwaysShow: true,
  meta: {
    title: 'B2C业务',
    icon: 'B2C'
  }
}, {
  path: 'b2cManage',
  component: B2CMange,
  redirect: '',
  name: 'B2CManage',
  alwaysShow: true,
  meta: {
    title: '单证管理',
    icon: 'B2C'
  }
}, {
  path: 'b2COrderEntry',
  component: B2COrderEntry,
  name: 'B2COrderEntry',
  meta: {
    title: '订单录入'
  }
}, {
  path: 'b2COrderEntryUS',
  component: B2COrderEntryUS,
  name: 'B2COrderEntryUS',
  meta: {
    title: '订单录入US'
  }
}, {
  path: 'b2COrderImport',
  component: B2CImport,
  name: 'B2COrderImport',
  meta: {
    title: '订单导入'
  }
}, {
  path: 'b2COrderImportUS',
  component: B2CImportUS,
  name: 'B2COrderImportUS',
  meta: {
    title: '订单导入US'
  }
}, {
  path: 'b2COrderImportForreturn',
  component: b2COrderImportForreturn,
  name: 'b2COrderImportForreturn',
  meta: {
    title: '退货转运订单导入'
  }
}, {
  path: 'b2COrderForzy',
  component: b2COrderForzy,
  name: 'b2COrderForzy',
  meta: {
    title: '退货转预订单查询'
  }
}, {
  path: 'RTOrderEntry',
  component: RTOrderEntry,
  name: 'RTOrderEntry',
  meta: {
    title: '退货转运订单录入'
  }
}, {
  path: 'bulkPackageEntry',
  component: BulkPackageEntry,
  name: 'BulkPackageEntry',
  meta: {
    title: '整包批量录入'
  }
}, {
  path: 'b2cOrderEdit',
  component: B2COrderEdit,
  name: 'B2COrderEdit',
  hidden: true,
  meta: {
    title: '订单编辑'
  }
}, {
  path: 'b2cOrderEditUS',
  component: B2COrderEditUS,
  name: 'B2COrderEditUS',
  hidden: true,
  meta: {
    title: '订单编辑新'
  }
}, {
  path: 'b2COrderManage',
  component: B2COrderMange,
  name: 'B2COrderManage',
  meta: {
    title: '订单管理'
  }
}, {
  path: 'b2COrderWeight',
  component: B2COrderWeight,
  name: 'B2COrderWeight',
  meta: {
    title: '订单修改重量导入'
  }
}, {
  path: 'b2CorderForecast',
  component: B2CorderForecast,
  name: 'B2CorderForecast',
  meta: {
    title: '预报订单'
  }
}, {
  path: 'b2cOrderQuery',
  component: B2COrderQuery,
  name: 'B2COrderQuery',
  meta: {
    title: '订单查询'
  }
}, {
  path: 'b2cOrderReturn',
  component: B2COrderReturn,
  name: 'B2COrderReturn',
  meta: {
    title: '批量退件'
  }
}, {
  path: 'b2cUnusualCOD',
  component: B2CUnusualCOD,
  name: 'B2CUnusualCOD',
  meta: {
    title: 'COD异常查询'
  }
}, {
  path: 'b2COrderOvertax',
  component: B2COverTax,
  name: 'B2COverTax',
  meta: {
    title: '超税订单查询'
  }
}, {
  path: 'interceptCreate',
  component: InterceptCreate,
  name: 'InterceptCreate',
  meta: {
    title: '拦截件建立'
  }
}, {
  path: 'interceptManage',
  component: InterceptManage,
  name: 'InterceptManage',
  meta: {
    title: '拦截件管理'
  }
}, {
  path: 'auditFordz',
  component: AuditFordz,
  name: 'AuditFordz',
  meta: {
    title: '品名审核结果查询'
  }
}, {
  path: 'abnormalOrder',
  component: AbnormalOrder,
  name: 'AbnormalOrder',
  meta: {
    title: '异常订单'
  }
}, {
  path: 'orderReview',
  component: OrderReview,
  name: 'OrderReview',
  meta: {
    title: '订单审核结果查询'
  }
}, {
  path: 'B2CCollectMange',
  component: B2CCollectMange,
  redirect: '',
  name: 'B2CCollectMange',
  alwaysShow: true,
  meta: {
    title: '揽收管理',
    icon: 'collect'
  }
}, {
  path: 'B2CArrivalForecast',
  component: B2CArrivalForecast,
  name: 'B2CArrivalForecast',
  meta: {
    title: '到货预报'
  }
}, {
  path: 'B2CArrivalInquiry',
  component: B2CArrivalInquiry,
  name: 'B2CArrivalInquiry',
  meta: {
    title: '到货包裹查询'
  }
}, {
  path: 'B2CFastShipping',
  component: B2CFastShipping,
  name: 'B2CFastShipping',
  meta: {
    title: '快递寄件'
  }
}, {
  path: 'B2CFastShippingImport',
  component: B2CFastShippingImport,
  name: 'B2CFastShippingImport',
  hidden: true,
  meta: {
    title: '快递寄件导入'
  }
}, {
  path: 'B2CShippingQuery',
  component: B2CShippingQuery,
  name: 'B2CShippingQuery',
  meta: {
    title: '寄件查询'
  }
}, {
  path: 'NoFileMange',
  component: NoFileMange,
  redirect: '',
  name: 'NoFileMange',
  alwaysShow: true,
  meta: {
    title: '无主件管理',
    icon: 'materials'
  }
}, {
  path: 'NoFileClaim',
  component: NoFileClaim,
  name: 'NoFileClaim',
  meta: {
    title: '无主件认领'
  }
}, {
  path: 'ClaimManage',
  component: ClaimManage,
  name: 'ClaimManage',
  meta: {
    title: '已认领管理'
  }
}, {
  path: 'b2cShippingAbroad',
  component: B2CShippingAbroad,
  redirect: '',
  name: 'B2CShippingAbroad',
  alwaysShow: true,
  meta: {
    title: '国外发货',
    icon: 'shopping'
  }
}, {
  path: 'b2cInventoryCheck',
  component: B2CInventoryCheck,
  name: 'B2CInventoryCheck',
  meta: {
    title: '库存查询'
  }
}, {
  path: 'wholePackageDelivery',
  component: WholePackageDelivery,
  name: 'WholePackageDelivery',
  meta: {
    title: '整包批量导入'
  }
}, {
  path: 'unPackBatchImport',
  name: 'UnPackBatchImport',
  component: UnPackBatchImport,
  meta: {
    title: '拆包批量导入'
  }
}, {
  path: 'GoodsDetailQuery',
  component: GoodsDetailQuery,
  name: 'GoodsDetailQuery',
  meta: {
    title: '商品明细查询'
  }
}, {
  path: 'StatisticalManage',
  component: StatisticalManage,
  redirect: '',
  name: 'StatisticalManage',
  alwaysShow: true,
  meta: {
    title: '统计管理',
    icon: 'dataManage'
  }
}, {
  path: 'cargoStatusQuery',
  component: CargoStatusQuery,
  name: 'CargoStatusQuery',
  meta: {
    title: '货态查询'
  }
}, {
  path: 'CargoBatchQuery',
  component: CargoBatchQuery,
  name: 'CargoBatchQuery',
  meta: {
    title: '货物批次状态查询'
  }
}, {
  path: 'rejectAnalysis',
  component: RejectAnalysis,
  name: 'RejectAnalysis',
  meta: {
    title: '退件统计分析'
  }
}, {
  path: 'weightQuery',
  component: WeightQuery,
  name: 'WeightQuery',
  meta: {
    title: '重量查询'
  }
}, {
  path: '/microWare',
  component: Layout,
  name: 'MicroWare',
  alwaysShow: true,
  meta: {
    title: '微仓管理',
    icon: 'warehouse'
  }
}, {
  path: 'microOrderManage',
  component: MicroWarehouse,
  redirect: '',
  name: 'MicroOrderManage',
  alwaysShow: true,
  meta: {
    title: '单证管理',
    icon: 'manage'
  }
}, {
  path: 'productInfoMange',
  component: ProductInfoManage,
  name: 'ProductInfoManage',
  meta: {
    title: '商品资料管理'
  }
}, {
  path: 'inboundTicket',
  component: InboundTicket,
  name: 'InboundTicket',
  meta: {
    title: '入库单票预报'
  }
}, {
  path: 'inboundOrderManage',
  component: InboundOrderManage,
  name: 'InboundOrderManage',
  meta: {
    title: '入库订单管理'
  }
}, {
  path: 'outboundOrderEntry',
  component: OutboundOrderEntry,
  name: 'OutboundOrderEntry',
  meta: {
    title: '出库单录入'
  }
}, {
  path: 'outboundOrderManage',
  component: OutboundOrderManage,
  name: 'OutboundOrderManage',
  meta: {
    title: '出库单管理'
  }
}, {
  path: 'wareInventoryCheck',
  component: WareInventoryCheck,
  name: 'WareInventoryCheck',
  meta: {
    title: '库存查询'
  }
}, {
  path: 'warehouseTransferOrder',
  component: WarehouseTransferOrder,
  name: 'WarehouseTransferOrder',
  meta: {
    title: '仓储转单管理'
  }
}, {
  path: 'ImeiQuery',
  component: ImeiQuery,
  name: 'ImeiQuery',
  meta: {
    title: 'IMEI查询'
  }
}, {
  path: 'returnManage',
  component: returnManage,
  redirect: 'returnForecast',
  name: 'returnManage',
  alwaysShow: true,
  meta: {
    title: '退货管理',
    icon: 'th'
  }
}, {
  path: 'returnForecast',
  component: returnForecast,
  name: 'returnForecast',
  meta: {
    title: '退货单预报'
  }
}, {
  path: 'batchForecast',
  component: batchForecast,
  name: 'batchForecast',
  meta: {
    title: '退货单批量预报'
  }
}, {
  path: 'returnOrderManage',
  component: returnOrderManage,
  name: 'returnOrderManage',
  meta: {
    title: '退货单管理'
  }
}, {
  path: 'headlessManage',
  component: headlessManage,
  name: 'headlessManage',
  meta: {
    title: '退货无头件管理'
  }
}, {
  path: '/fbaOrder',
  component: Layout,
  name: 'FBAOrder',
  alwaysShow: true,
  meta: {
    title: 'FBA业务',
    icon: 'FBA'
  }
}, {
  path: 'fbaManage',
  component: FBAManageIndex,
  redirect: '',
  name: 'FBAManage',
  alwaysShow: true,
  meta: {
    title: '单证管理',
    icon: 'B2C'
  }
}, {
  path: 'fbaOrderEntry',
  component: FBAEntry,
  name: 'FBAOrderEntry',
  meta: {
    title: '订单录入'
  }
}, {
  path: 'fbaOrderImport',
  component: FBAImport,
  name: 'FBAOrderImport',
  meta: {
    title: '订单导入'
  }
}, {
  path: 'fbaOrderManage',
  component: FBAOrderMange,
  name: 'FBAOrderManage',
  meta: {
    title: '订单管理'
  }
}, {
  path: '/sellerAssistant',
  component: Layout,
  name: 'SellerAssistant',
  alwaysShow: true,
  meta: {
    title: '卖家助手'
  }
}, {
  path: 'sellerAssistantManage',
  component: SellerAssistantManageIndex,
  redirect: '',
  name: 'SellerAssistantManage',
  alwaysShow: true,
  meta: {
    title: '卖家助手',
    icon: 'manage'
  }
}, {
  path: 'newSellerOrderManageV2',
  component: SellerAssistantManageIndex,
  redirect: '',
  name: 'newSellerOrderManageV2',
  alwaysShow: true,
  meta: {
    title: '卖家助手V2',
    icon: 'manage'
  }
}, {
  path: 'shopList',
  component: ShopList,
  name: 'ShopList',
  meta: {
    title: '店铺列表'
  }
}, {
  path: 'sellerOrderManage',
  component: SellerOrderManage,
  name: 'SellerOrderManage',
  meta: {
    title: '出库订单管理'
  }
}, {
  path: 'b2cSellerOrderManage',
  component: B2CSellerOrderManage,
  name: 'B2CSellerOrderManage',
  meta: {
    title: 'B2C订单管理'
  }
}, {
  path: 'newSellerOrderManage',
  component: NewSellerOrderManage,
  name: 'NewSellerOrderManage',
  meta: {
    title: '订单管理'
  }
}, {
  path: 'NewSellerOrderEdit',
  component: NewSellerOrderEdit,
  name: 'NewSellerOrderEdit',
  hidden: true,
  meta: {
    title: '编辑'
  }
}, {
  path: 'sellerProductManage',
  component: SellerProductManage,
  name: 'SellerProductManage',
  meta: {
    title: '产品管理'
  }
},
// {
//   path: 'skuManage',
//   component: SkuManage,
//   name: 'SkuManage',
//   meta: {
//     title: 'SKU管理'
//   }
// },
{
  path: 'skuMapping',
  component: SkuMapping,
  name: 'SkuMapping',
  meta: {
    title: 'B2C-SKU映射'
  }
}, {
  path: 'cloudWarehouseSkuMapping',
  component: CloudWarehouseSkuMapping,
  name: 'CloudWarehouseSkuMapping',
  meta: {
    title: '云仓-SKU映射'
  }
}, {
  path: 'sellerOrderEntry',
  component: SellerOrderEntry,
  name: 'SellerOrderEntry',
  hidden: true,
  meta: {
    title: '新增订单'
  }
}, {
  path: 'sellerForecastOrder',
  component: SellerForecastOrder,
  name: 'SellerForecastOrder',
  hidden: true,
  meta: {
    title: '预报单'
  }
}, {
  path: 'sellerOutboundOrder',
  component: SellerOutboundOrder,
  name: 'SellerOutboundOrder',
  hidden: true,
  meta: {
    title: '出库单'
  }
}, {
  path: 'sellerOrderEdit',
  component: SellerOrderEdit,
  name: 'SellerOrderEdit',
  hidden: true,
  meta: {
    title: '编辑订单'
  }
},
// 服务中心
{
  path: '/serviceCenter',
  component: Layout,
  name: 'ServiceCenter',
  alwaysShow: true,
  meta: {
    title: '服务中心'
  }
}, {
  path: 'beginnersGuide',
  component: BeginnersGuide,
  redirect: '',
  name: 'BeginnersGuide',
  alwaysShow: true,
  meta: {
    title: '新手指南',
    icon: 'BeginnersGuide'
  }
}, {
  path: 'openAccount',
  component: OpenAccount,
  name: 'OpenAccount',
  meta: {
    title: '开户'
  }
}, {
  path: 'basicSettings',
  component: BasicSettings,
  name: 'BasicSettings',
  meta: {
    title: '基础设置'
  }
}, {
  path: 'placeOrder',
  component: PlaceOrder,
  name: 'PlaceOrder',
  meta: {
    title: '下单'
  }
}, {
  path: 'newcomerDelivery',
  component: NewcomerDelivery,
  name: 'NewcomerDelivery',
  meta: {
    title: '交货'
  }
}, {
  path: 'cargoTracking',
  component: CargoTracking,
  name: 'CargoTracking',
  meta: {
    title: '货物跟踪及异常处理'
  }
}, {
  path: 'settlementFee',
  component: SettlementFee,
  name: 'SettlementFee',
  meta: {
    title: '结算费用'
  }
}, {
  path: 'shippingInstructions',
  component: ShippingInstructions,
  redirect: '',
  name: 'ShippingInstructions',
  alwaysShow: true,
  meta: {
    title: '走货须知',
    icon: 'ShippingInstructions'
  }
}, {
  path: 'vipPlaceOrder',
  component: VipPlaceOrder,
  redirect: '',
  name: 'VipPlaceOrder',
  alwaysShow: true,
  meta: {
    title: 'VIP平台下单'
  }
}, {
  path: 'myKeyForOrder',
  component: MyKeyForOrder,
  redirect: '',
  name: 'MyKeyForOrder',
  alwaysShow: true,
  meta: {
    title: '我的秘钥'
  }
},
// {
//   path: 'vipPlaceOrder',
//   component: VipPlaceOrder,
//   name: 'VipPlaceOrder',
//   meta: {
//     title: 'VIP平台下单'
//   }
// },
{
  path: 'b2cPrecautions',
  component: B2cPrecautions,
  name: 'B2cPrecautions',
  meta: {
    title: 'B2C订单下单注意事项'
  }
}, {
  path: 'stockOrder',
  component: StockOrder,
  name: 'StockOrder',
  meta: {
    title: '备货订单'
  }
}, {
  path: 'apiDockingOrder',
  component: ApiDockingOrder,
  name: 'ApiDockingOrder',
  meta: {
    title: 'API对接下单'
  }
}, {
  path: 'erpDockingOrder',
  component: ErpDockingOrder,
  name: 'ErpDockingOrder',
  meta: {
    title: 'ERP对接下单'
  }
}, {
  path: 'vipSystemOperation',
  component: VipSystemOperation,
  redirect: '',
  name: 'VipSystemOperation',
  alwaysShow: true,
  meta: {
    title: 'VIP平台系统操作',
    icon: 'manage'
  }
}, {
  path: 'vsoPlaceOrder',
  component: VsoPlaceOrder,
  name: 'VsoPlaceOrder',
  meta: {
    title: '下单'
  }
}, {
  path: 'vsoCheckOrder',
  component: VsoCheckOrder,
  name: 'VsoCheckOrder',
  meta: {
    title: '查单'
  }
}, {
  path: 'vsoAbnormalOperation',
  component: VsoAbnormalOperation,
  name: 'VsoAbnormalOperation',
  meta: {
    title: '异常操作'
  }
}, {
  path: 'b2cSmallPackageDescription',
  component: B2cSmallPackageDescription,
  redirect: '',
  name: 'B2cSmallPackageDescription',
  alwaysShow: true,
  meta: {
    title: 'B2C小包产品说明',
    icon: 'description'
  }
}, {
  path: 'spBasicInstructions',
  component: SpBasicInstructions,
  name: 'SpBasicInstructions',
  meta: {
    title: '基本说明'
  }
}, {
  path: 'spBasicPrice',
  component: SpBasicPrice,
  name: 'SpBasicPrice',
  meta: {
    title: '基本报价'
  }
}, {
  path: 'spTimeLimit',
  component: SpTimeLimit,
  name: 'SpTimeLimit',
  meta: {
    title: '时效说明'
  }
}, {
  path: 'spExportRestrictions',
  component: SpExportRestrictions,
  name: 'SpExportRestrictions',
  meta: {
    title: '各国出口物品限制'
  }
}, {
  path: 'spClaimStandard',
  component: SpClaimStandard,
  name: 'SpClaimStandard',
  meta: {
    title: '理赔标准'
  }
}, {
  path: 'b2bBulkDescription',
  component: B2bBulkDescription,
  redirect: '',
  name: 'B2bBulkDescription',
  alwaysShow: true,
  meta: {
    title: 'B2B大货产品说明',
    icon: 'dahuo'
  }
}, {
  path: 'bulkDescription',
  component: BulkDescription,
  name: 'BulkDescription',
  meta: {
    title: '产品说明'
  }
}, {
  path: 'warehouseAddress',
  component: WarehouseAddress,
  redirect: '',
  name: 'WarehouseAddress',
  alwaysShow: true,
  meta: {
    title: '仓库收货地址信息',
    icon: 'address'
  }
}, {
  path: 'domesticWarehouse',
  component: DomesticWarehouse,
  name: 'DomesticWarehouse',
  meta: {
    title: '国内仓'
  }
}, {
  path: 'overseasWarehouse',
  component: OverseasWarehouse,
  name: 'OverseasWarehouse',
  meta: {
    title: '海外仓'
  }
}, {
  path: 'commonProblem',
  component: CommonProblem,
  redirect: '',
  name: 'CommonProblem',
  alwaysShow: true,
  meta: {
    title: '常见问题',
    icon: 'problem'
  }
}, {
  path: 'CompanyService',
  component: CompanyService,
  name: 'CompanyService',
  meta: {
    title: '公司提供的服务'
  }
}, {
  path: '/workOrderManage',
  component: Layout,
  name: 'WorkOrderManage',
  alwaysShow: true,
  meta: {
    title: '工单管理'
  }
}, {
  path: 'workOrderAccess',
  component: workOrderAccess,
  name: 'WorkOrderAccess',
  alwaysShow: true,
  meta: {
    title: '工单接入',
    icon: 'manage'
  }
}, {
  path: 'inspectionWorkOrder',
  component: inspectionWorkOrder,
  name: 'InspectionWorkOrder',
  alwaysShow: true,
  meta: {
    title: '查件工单',
    icon: 'inspectionWork'
  }
}, {
  path: 'claimWorkOrder',
  component: claimWorkOrder,
  name: 'ClaimWorkOrder',
  alwaysShow: true,
  meta: {
    title: '索赔工单',
    icon: 'claimWork'
  }
}, {
  path: 'otherWorkOrders',
  component: otherWorkOrders,
  name: 'OtherWorkOrders',
  alwaysShow: true,
  meta: {
    title: '其他工单',
    icon: 'otherWork'
  }
}, {
  path: '/taskManage',
  component: Layout,
  name: 'TaskManage',
  alwaysShow: true,
  meta: {
    title: '任务管理',
    icon: 'task'
  }
}, {
  path: 'exportTaskMange',
  component: ExportTaskMange,
  redirect: '',
  name: 'ExportTaskMange',
  alwaysShow: true,
  meta: {
    title: '导出任务管理',
    icon: 'export'
  }
}, {
  path: 'exportTaskCheck',
  component: ExportTaskCheck,
  name: 'ExportTaskCheck',
  meta: {
    title: '导出任务查询'
  }
}, {
  path: 'importTaskMange',
  component: ImportTaskMange,
  redirect: '',
  name: 'ImportTaskMange',
  alwaysShow: true,
  meta: {
    title: '导入任务管理',
    icon: 'export'
  }
}, {
  path: 'importTaskCheck',
  component: ImportTaskCheck,
  name: 'ImportTaskCheck',
  meta: {
    title: '导入任务查询'
  }
}, {
  path: '/baseData',
  component: Layout,
  name: 'BaseData',
  alwaysShow: true,
  meta: {
    title: '基础资料维护',
    icon: 'maintain'
  }
}, {
  path: 'materialsManage',
  component: MaterialsManage,
  redirect: '',
  name: 'MaterialsManage',
  alwaysShow: true,
  meta: {
    title: '物料管理',
    icon: 'materials'
  }
}, {
  path: 'faceListManage',
  component: FaceListManage,
  name: 'FaceListManage',
  meta: {
    title: '面单查询'
  }
}, {
  path: 'expressManage',
  component: ExpressManage,
  redirect: '',
  name: 'ExpressManage',
  alwaysShow: true,
  meta: {
    title: '跟踪管理',
    icon: 'track'
  }
}, {
  path: 'expressQuery',
  component: ExpressQuery,
  name: 'ExpressQuery',
  meta: {
    title: '跟踪查询'
  }
}, {
  path: 'financeManage',
  component: FinanceManage,
  redirect: '',
  name: 'FinanceManage',
  alwaysShow: true,
  meta: {
    title: '财务管理',
    icon: 'financeManage'
  }
}, {
  path: 'financialSettle',
  component: FinancialSettle,
  name: 'FinancialSettle',
  meta: {
    title: '财务结算'
  }
}, {
  path: 'DestinationArea',
  component: DestinationArea,
  redirect: '',
  name: 'DestinationArea',
  alwaysShow: true,
  meta: {
    title: '目的地区',
    icon: 'destinationArea'
  }
}, {
  path: 'destinationAreaQuery',
  component: DestinationAreaQuery,
  name: 'DestinationAreaQuery',
  meta: {
    title: '目的地查询'
  }
}, {
  path: 'cusServiceManage',
  component: CusServiceManage,
  redirect: '',
  name: 'CusServiceManage',
  alwaysShow: true,
  meta: {
    title: '客户服务管理',
    icon: 'custService'
  }
}, {
  path: 'complainSuggest',
  component: ComplainSuggest,
  name: 'ComplainSuggest',
  meta: {
    title: '投诉建议处理'
  }
}, {
  path: 'personManage',
  component: PersonManage,
  redirect: '',
  name: 'PersonManage',
  alwaysShow: true,
  meta: {
    title: '地址簿管理',
    icon: 'personal'
  }
}, {
  path: 'selfAddress',
  component: SelfAddress,
  name: 'SelfAddress',
  meta: {
    title: '寄件地址设置'
  }
}, {
  path: 'receivesAddress',
  component: ReceivesAddress,
  name: 'ReceivesAddress',
  meta: {
    title: '收件地址设置'
  }
}, {
  path: 'DeclaredManage',
  component: DeclaredManage,
  redirect: '',
  name: 'DeclaredManage',
  alwaysShow: true,
  meta: {
    title: '申报品管理',
    icon: 'personal'
  }
}, {
  path: 'DeclaredGoods',
  component: DeclaredGoods,
  name: 'DeclaredGoods',
  meta: {
    title: '申报品管理'
  }
}, {
  path: 'permissionMange',
  component: PermissionMange,
  redirect: '',
  name: 'PermissionMange',
  alwaysShow: true,
  meta: {
    title: '权限管理',
    icon: 'permission'
  }
}, {
  path: 'basicUserManage',
  component: UserManage,
  name: 'UserManage',
  meta: {
    title: '权限设置'
  }
}, {
  path: '/systemSetting',
  name: 'SystemSetting',
  component: Layout,
  redirect: '',
  alwaysShow: true,
  meta: {
    title: '系统配置',
    icon: 'permission'
  }
}, {
  path: 'i18n',
  component: I18nIndex,
  name: 'I18nTable',
  meta: {
    title: '国际化配置',
    icon: 'iconliebiaoguanli'
  }
}, {
  path: 'table',
  component: TableIndex,
  name: 'SystemSettingTable',
  meta: {
    title: '列表管理',
    icon: 'iconliebiaoguanli'
  }
}, {
  path: 'tableAdd',
  name: 'SystemSettingTableAdd',
  component: TableAdd,
  hidden: true,
  meta: {
    title: '编辑列表'
  }
}, {
  path: 'route',
  name: 'SystemSettingRoute',
  component: RouteIndex,
  meta: {
    title: '路由管理',
    icon: 'iconluyouguanli'
  }
}, {
  path: 'button',
  name: 'SystemSettingButton',
  component: ButtonIndex,
  meta: {
    title: '按钮管理',
    icon: 'iconguizeguanli'
  }
}, {
  path: 'button',
  name: 'B2cButton',
  component: B2cButton,
  meta: {
    title: 'B2C订单管理按钮管理'
  }
}, {
  path: 'button',
  name: 'B2cButtonPrint',
  component: B2cButtonPrint,
  meta: {
    title: 'B2C批量打印按钮'
  }
}, {
  path: 'button',
  name: 'B2cButtonEdit',
  component: B2cButtonEdit,
  meta: {
    title: 'B2C批量修改按钮'
  }
}, {
  path: 'button',
  name: 'B2cButtonReturn',
  component: B2cButtonReturn,
  meta: {
    title: 'B2C批量退件按钮'
  }
}, {
  path: 'button',
  name: 'B2cButtonCancel',
  component: B2cButtonCancel,
  meta: {
    title: 'B2C批量作废按钮'
  }
}, {
  path: 'button',
  name: 'B2cExportCancel',
  component: B2cExportCancel,
  meta: {
    title: 'B2C数据导出按钮'
  }
}, {
  path: 'menu',
  name: 'SystemSettingMenu',
  component: MenuIndex,
  meta: {
    title: '菜单管理',
    icon: 'iconcaidanguanli'
  }
}, {
  path: 'rule',
  name: 'PermissionRule',
  component: permissionRule,
  meta: {
    title: '规则管理',
    icon: 'iconguizeguanli'
  }
}, {
  path: 'import',
  name: 'SystemSettingImport',
  component: ImportIndex,
  meta: {
    title: '导入管理',
    icon: 'icondaoruguanli'
  }
}, {
  path: 'importAdd',
  name: 'SystemSettingImportAdd',
  component: ImportAdd,
  hidden: true,
  meta: {
    title: '编辑导入'
  }
}, {
  path: 'filter',
  name: 'SystemSettingFilter',
  component: systemFilter,
  meta: {
    title: '表单管理',
    icon: 'iconbiaodanguanli'
  }
}, {
  path: 'filterAdd',
  name: 'SystemSettingFilterAdd',
  component: systemFilterAdd,
  hidden: true,
  meta: {
    title: '编辑表单'
  }
}, {
  path: 'field',
  name: 'SystemSettingField',
  component: SystemSettingField,
  meta: {
    title: '字段管理',
    icon: 'iconziduanguanli'
  }
}, {
  path: 'fieldAdd',
  name: 'SystemSettingFieldAdd',
  component: SystemSettingFieldAdd,
  hidden: true,
  meta: {
    title: '编辑字段'
  }
}, {
  path: 'page',
  name: 'PageSettingTable',
  component: PageSettingTable,
  meta: {
    title: '界面配置管理',
    icon: 'iconliebiaoguanli'
  }
}, {
  path: 'pageAdd',
  name: 'PageSettingAdd',
  component: PageSettingAdd,
  hidden: true,
  meta: {
    title: '编辑界面',
    icon: 'iconliebiaoguanli'
  }
}, {
  path: '/cloudWarehouse',
  name: 'CloudWarehouse',
  alwaysShow: true,
  component: CloudWarehouse,
  meta: {
    title: '云仓'
  }
}, {
  path: 'cloudWarehousePickOrder',
  name: 'CloudWarehousePickOrder',
  component: CloudWarehousePickOrder,
  meta: {
    title: '拣货单打印'
  }
}, {
  path: 'cloudWarehouseOutOrder',
  name: 'CloudWarehouseOutOrder',
  component: CloudWarehouseOutOrder,
  meta: {
    title: '出库单打印'
  }
}, {
  path: 'dynamicRoute',
  name: 'DynamicRoute',
  component: DynamicRoute,
  meta: {
    title: '可配置查询界面'
  }
}, {
  path: 'microWarehouseGoodsCreate',
  name: 'MicroWarehouseGoodsCreate',
  component: microWarehouseGoodsCreate,
  meta: {
    title: '商品资料建立'
  }
}, {
  path: 'microWarehouseBatchForecast',
  name: 'MicroWarehouseBatchForecast',
  component: microWarehouseBatchForecast,
  meta: {
    title: '入库批量预报'
  }
}, {
  path: 'microWarehouseOutboundOrderImport',
  name: 'MicroWarehouseOutboundOrderImport',
  component: microWarehouseOutboundOrderImport,
  meta: {
    title: '出库单导入'
  }
}, {
  path: 'systemSettingDesignForm',
  name: 'SystemSettingDesignForm',
  component: SystemSettingDesignForm,
  hidden: true,
  meta: {
    title: '表单设计',
    icon: 'iconbiaodanguanli'
  }
}, {
  path: 'systemSettingDesignFaceSheet',
  name: 'systemSettingDesignFaceSheet',
  component: systemSettingDesignFaceSheet,
  meta: {
    title: '面单设计',
    icon: 'icon28fuwenbenkuang'
  }
}, {
  path: 'LowCodeIndex',
  name: 'LowCodeIndex',
  component: LowCodeIndex,
  meta: {
    title: '低代码平台',
    icon: 'el-icon-monitor'
  }
},
// 云仓菜单跳转  2023/1/10
{
  path: 'outManageForyc',
  component: outManageForyc,
  redirect: '',
  name: 'outManageForyc',
  alwaysShow: true,
  meta: {
    title: '出库管理',
    icon: 'outK'
  }
}, {
  path: 'goodManage',
  component: goodManage,
  redirect: '',
  name: 'goodManage',
  alwaysShow: true,
  meta: {
    title: '商品管理',
    icon: 'manage'
  }
}, {
  path: 'CreateGoodsNew',
  name: 'CreateGoodsNew',
  component: CreateGoodsNew,
  meta: {
    title: '创建商品'
  }
}, {
  path: 'inManageForyc',
  component: inManageForyc,
  redirect: '',
  name: 'inManageForyc',
  alwaysShow: true,
  meta: {
    title: '入库管理',
    icon: 'inK'
  }
}, {
  path: 'InventoryCheckMg',
  component: InventoryCheckMg,
  redirect: '',
  name: 'InventoryCheckMg',
  alwaysShow: true,
  meta: {
    title: '库存管理',
    icon: 'kc'
  }
}, {
  path: 'WarehouseTransferOrderMg',
  component: WarehouseTransferOrderMg,
  redirect: '',
  name: 'WarehouseTransferOrderMg',
  alwaysShow: true,
  meta: {
    title: '仓储转单管理',
    icon: 'zd'
  }
}, {
  path: 'imeiQueryMg',
  component: imeiQueryMg,
  redirect: '',
  name: 'imeiQueryMg',
  alwaysShow: true,
  meta: {
    title: 'IMEI查询',
    icon: 'imei'
  }
}, {
  path: 'pdfUpload',
  component: PdfUpload,
  redirect: 'pdfUploadBybatch',
  name: 'PdfUpload ',
  alwaysShow: true,
  meta: {
    title: '面单批量上传',
    icon: 'imei'
  }
}, {
  path: 'pdfUploadBybatch',
  component: pdfUploadBybatch,
  name: 'pdfUploadBybatch',
  meta: {
    title: '面单批量上传'
  }
}, {
  path: 'BulkCreate',
  name: 'BulkCreate',
  component: BulkCreate,
  meta: {
    title: '商品批量创建'
  }
}, {
  path: 'GoodsManagement',
  name: 'GoodsManagement',
  component: GoodsManagement,
  meta: {
    title: '商品资料管理'
  }
}, {
  path: 'GoodsEdit/:id',
  name: 'GoodsEdit',
  component: GoodsEdit,
  hidden: true,
  meta: {
    title: '编辑商品'
  }
}, {
  path: 'GoodsCopy/:id',
  name: 'GoodsCopy',
  component: GoodsCopy,
  hidden: true,
  meta: {
    title: '复制商品'
  }
}, {
  path: 'GoodsDetail/:id',
  name: 'GoodsDetail',
  component: GoodsDetail,
  hidden: true,
  meta: {
    title: '商品详情'
  }
}, {
  path: 'BulkPrint',
  name: 'BulkPrint',
  component: BulkPrint,
  meta: {
    title: '批量打印条码'
  }
}];

// const B2CMange = () => import('@/views/B2C/B2Cmanage/index')
// const returnManage = () => import('@/views/MicroWarehouse/returnManage/index')

var routerJsonMap = {};
// console.log(routerMap)
routerMap.map(function (item) {
  var newItem = _objectSpread({}, item);
  var meta = {
    meta: {
      title: '',
      icon: newItem.meta ? newItem.meta.icon || '' : ''
    }
  };
  routerJsonMap[newItem.name] = Object.assign(newItem, meta);
});
export { routerJsonMap, routerMap };