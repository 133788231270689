var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.data.field.visible,
          expression: "data.field.visible",
        },
      ],
      class: _vm.data.elementId,
      attrs: {
        label: _vm.$t(_vm.data.field.name),
        prop: _vm.data.field.paramsKey,
      },
    },
    [
      _c("el-time-picker", {
        class: _vm.data.field.props.className,
        attrs: {
          readonly: _vm.data.field.props.readonly,
          editable: _vm.data.field.props.editable,
          clearable: _vm.data.field.props.clearable,
          placeholder: _vm.$t(_vm.data.field.props.placeholder),
          "value-format": "h:m:s",
        },
        on: { change: _vm.onChange, focus: _vm.onFocus },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }