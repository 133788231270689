var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    {
      staticClass: "form-grid-warp",
      style: _vm.styles,
      attrs: {
        justify: _vm.data.field.props.justify,
        gutter: _vm.data.field.props.gutter,
        align: _vm.data.field.props.align,
        type: "flex",
      },
    },
    _vm._l(_vm.data.field.children, function (item) {
      return _c(
        "el-col",
        { key: item.elementId, attrs: { span: item.span } },
        _vm._l(item.children, function (child) {
          return _c(child.type + "Item", {
            key: child.elementId,
            ref: child.field.paramsKey,
            refInFor: true,
            tag: "set-component",
            attrs: { data: child },
            on: { input: _vm.onInput, "request-after": _vm.onRequestAfter },
          })
        }),
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }