export var BAIDU_TRACKED = function BAIDU_TRACKED(name, desc) {
  var mode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '_trackEvent';
  try {
    if (window.location.host !== 'vip.yl-scm.com') {
      // 如果不在生产环境则不进入埋点
      return;
    }
    _hmt.push([mode, name, desc]);
  } catch (e) {
    console.log(e);
  }
};