import fetch from '@/ylCommon/utils/request';
var form = {
  getFormItems: function getFormItems(params) {
    return fetch({
      url: '/setting/getFormItems.do',
      data: params
    });
  },
  saveFormItem: function saveFormItem(params) {
    return fetch({
      url: '/setting/saveFormItem.do',
      data: params
    });
  },
  updateFormItem: function updateFormItem(params) {
    return fetch({
      url: '/setting/updateFormItem.do',
      data: params
    });
  },
  deleteFormItem: function deleteFormItem(params) {
    return fetch({
      url: '/setting/deleteFormItem.do',
      data: params
    });
  },
  getForms: function getForms(params) {
    return fetch({
      url: '/setting/getFormSettings.do',
      data: params
    });
  },
  saveForm: function saveForm(params) {
    return fetch({
      url: '/setting/saveFormSetting.do',
      data: params
    });
  },
  updateForm: function updateForm(params) {
    return fetch({
      url: '/setting/updateFormSetting.do',
      data: params
    });
  },
  deleteForm: function deleteForm(params) {
    return fetch({
      url: '/setting/deleteFormSetting.do',
      data: params
    });
  },
  getFormItemRules: function getFormItemRules(params) {
    return fetch({
      url: '/setting/getAllRegular.do',
      data: params
    });
  }
};
export default form;