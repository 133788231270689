import "core-js/modules/es6.number.constructor";
//
//
//
//

export default {
  props: {
    elementId: {
      type: String,
      default: ''
    },
    borderWidth: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    getStyle: function getStyle() {
      var borderWidth = this.borderWidth;
      return {
        minWidth: '20px',
        minHeight: '20px',
        height: '100%',
        border: '1px solid #000',
        borderWidth: "".concat(borderWidth, "px")
      };
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      this.$emit('complete');
    }
  }
};