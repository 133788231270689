import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import Axios from 'axios';
import store from "./../store";
import fetch from '@/ylCommon/utils/request';
var configUrl = process.env.VUE_APP_BASE_API;
var axios = function axios(url, params, token) {
  params.token = !token ? store.getters.token : token;
  var path = url.includes('http') ? url : "".concat(configUrl).concat(url);
  return Axios.post(path, params);
};
var order = {
  sendOrder: function sendOrder(params) {
    return axios("".concat(configUrl, "/api/insertOrder.do"), params);
  },
  getTotalData: function getTotalData(params) {
    return fetch({
      url: '/api/getData.do',
      data: params
    });
  },
  getAddressOptions: function getAddressOptions() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var result = null;
    var addressData = sessionStorage.getItem('addressList');
    if (addressData) {
      result = new Promise(function (resolve, reject) {
        resolve(JSON.parse(addressData));
      });
    } else {
      result = fetch({
        url: '/api/getAddress.do',
        data: {
          parameter: params
        },
        loading: true
      });
    }
    return result;
  },
  addAddress: function addAddress(params) {
    return fetch({
      url: '/api/save.do',
      data: params
    });
  },
  queryAddress: function queryAddress(params) {
    return fetch({
      url: '/api/queryList.do',
      data: params
    });
  },
  getSendOrderList: function getSendOrderList(params) {
    return axios("".concat(configUrl, "/api/getSendOrders.do"), params);
  },
  exportOrderForBillCode: function exportOrderForBillCode(params) {
    return fetch({
      url: "/api/exportSendOrder.do",
      data: params
    });
  },
  printOrder: function printOrder(params) {
    var isE3 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    if (!isE3) {
      return fetch({
        url: '/api/printOrder.do',
        data: params
      });
    } else {
      return fetch({
        url: '/e3/print.do',
        data: params
      });
    }
  },
  getCommerceOrders: function getCommerceOrders(params) {
    return axios("".concat(configUrl, "/api/getCommerceOrders.do"), params);
  },
  getFreightDetail: function getFreightDetail(params) {
    return fetch({
      url: '/api/getFreight.do',
      data: params
    });
  },
  exportOrderForOrder: function exportOrderForOrder(params) {
    return fetch({
      url: '/api/exportOrders.do',
      data: params
    });
  },
  getStatusCountData: function getStatusCountData(obj) {
    var params = {
      parameter: obj
    };
    return fetch({
      url: '/api/getStatusCountData.do',
      data: params
    });
  },
  query: function query(obj) {
    var params = {
      parameter: obj
    };
    return fetch({
      url: '/api/getOrders.do',
      data: params
    });
  },
  reset: function reset(params) {
    return fetch({
      url: '/api/updateOrder.do',
      data: params
    });
  },
  edit: function edit(obj) {
    var params = {
      parameter: obj
    };
    return fetch({
      url: '/api/updateOrder.do',
      data: params
    });
  },
  cancel: function cancel(obj) {
    var params = {
      parameter: obj
    };
    return fetch({
      url: '/api/cancelOrder.do',
      data: params
    });
  },
  print: function print(obj) {
    var params = {
      parameter: obj
    };
    return fetch({
      url: '/api/printOrder.do',
      data: params
    });
  },
  upImport: function upImport(obj) {
    var params = {
      parameter: obj
    };
    return fetch({
      url: '/api/insertOrder.do',
      timeout: 120 * 1000,
      data: params
    });
  },
  getInsurance: function getInsurance(obj) {
    var params = {
      parameter: obj
    };
    return fetch({
      url: '/api/getInsuranceRate.do',
      data: params
    });
  },
  getFreight: function getFreight(params) {
    return fetch({
      url: '/api/getFee.do',
      data: params
    });
  },
  getDefenceFee: function getDefenceFee(params) {
    return fetch({
      url: '/api/getInsuranceRate.do',
      data: params
    });
  },
  getRulebasic: function getRulebasic() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return fetch({
      url: '/getRulebasic.do',
      data: {
        parameter: params
      }
    });
  },
  getCountry: function getCountry(params) {
    return fetch({
      url: '/getCountry.do',
      data: {
        parameter: params
      }
    });
  },
  getCurrencyData: function getCurrencyData(params) {
    return fetch({
      url: '/getCurrencyData.do',
      data: {
        parameter: params
      }
    });
  },
  getWarehouseInfo: function getWarehouseInfo(params) {
    return fetch({
      url: '/getWarehouseInfo.do',
      data: {
        parameter: params
      }
    });
  },
  // 出库单获取配置仓库信息
  getOutWarehouseInfo: function getOutWarehouseInfo(params) {
    return fetch({
      url: '/getOutWarehouseInfo.do',
      data: {
        parameter: params
      }
    });
  },
  getYCOrderStatus: function getYCOrderStatus() {
    // 获取云仓状态
    return fetch({
      url: '/getYCOrderStatus.do',
      data: {
        parameter: {}
      }
    });
  },
  getRoute: function getRoute(params) {
    return fetch({
      url: '/getRoute.do',
      data: {
        parameter: params
      }
    });
  },
  getRequireTaxNoRouteId: function getRequireTaxNoRouteId(obj) {
    var params = {
      parameter: obj
    };
    return fetch({
      url: '/getRequireTaxNoRouteId.do',
      data: params
    });
  },
  // 地址解析
  geocoding: function geocoding(params) {
    return fetch({
      url: '/geocoding.do',
      data: {
        parameter: params
      }
    });
  },
  getGoodsTypeDict: function getGoodsTypeDict(params) {
    return fetch({
      url: '/getGoodsTypeDict.do',
      data: {
        parameter: params
      }
    });
  },
  getPaymentType: function getPaymentType(params) {
    return fetch({
      url: '/getPaymentType.do',
      data: {
        parameter: params
      }
    });
  },
  getTransportMode: function getTransportMode(params) {
    return fetch({
      url: '/getTransportMode.do',
      data: {
        parameter: params
      }
    });
  },
  getCollectGoodsTypeData: function getCollectGoodsTypeData() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return fetch({
      url: '/getCollectGoodsTypeData.do',
      data: {
        parameter: params
      }
    });
  },
  getSsq: function getSsq(params) {
    return fetch({
      url: '/getSsq.do',
      loading: false,
      data: {
        parameter: params
      }
    });
  },
  // 11.30 收件人发件人地址维护，调整为邮编表
  getSsqYb: function getSsqYb(params) {
    return fetch({
      url: '/getSsqYb.do',
      loading: false,
      data: {
        parameter: params
      }
    });
  },
  getSsqByPCA: function getSsqByPCA(params) {
    return fetch({
      url: '/getSsqByPCA.do',
      loading: false,
      data: {
        parameter: params
      }
    });
  },
  getSsqByCpId: function getSsqByCpId(params) {
    return fetch({
      url: '/getSsqByCpId.do',
      loading: false,
      data: {
        parameter: params
      }
    });
  },
  getPostcodeBySsq: function getPostcodeBySsq(params) {
    return fetch({
      url: '/getPostcodeBySsq.do',
      loading: false,
      data: {
        parameter: params
      }
    });
  },
  getSkuDeclareNoPage: function getSkuDeclareNoPage(params) {
    return fetch({
      url: '/goods/getSkuDeclareNoPage.do',
      loading: false,
      data: {
        parameter: params
      }
    });
  },
  // 获取打包要求数据资料
  queryPackageData: function queryPackageData(params) {
    return fetch({
      url: '/getDictionaryData.do',
      loading: false,
      data: {
        parameter: params
      }
    });
  },
  dataReverseQuery: function dataReverseQuery(params) {
    return fetch({
      url: '/dataReverseQuery.do',
      data: {
        parameter: params
      }
    });
  },
  getGoodsCategory: function getGoodsCategory(params) {
    return fetch({
      url: '/getGoodsCategory.do',
      data: {
        parameter: params
      }
    });
  },
  getGoodsType: function getGoodsType(params) {
    return fetch({
      url: '/getGoodsType.do',
      data: {
        parameter: params
      }
    });
  },
  getAddressList: function getAddressList(params) {
    return fetch({
      url: '/common/queryList.do',
      data: {
        parameter: params
      }
    });
  },
  // 面单查询
  getNoodleSheet: function getNoodleSheet(params) {
    return fetch({
      url: '/common/getNoodleSheet.do',
      data: {
        parameter: params
      }
    });
  },
  deleteCgbatch: function deleteCgbatch(params) {
    return fetch({
      url: '/draftBox/deleteBatchOrderDraft.do',
      timeout: 5 * 60 * 1000,
      data: {
        parameter: params
      },
      loading: true
    });
  },
  // 草稿箱批量下单
  batchOrderForCg: function batchOrderForCg(params) {
    return fetch({
      url: '/draftBox/placeOrderDraft.do',
      timeout: 5 * 60 * 1000,
      data: {
        parameter: params
      },
      loading: true
    });
  },
  // b2c录入草稿箱下单
  submitOrderForcg: function submitOrderForcg(params) {
    return fetch({
      url: '/draftBox/saveOrderDraft.do',
      timeout: 5 * 60 * 1000,
      data: {
        parameter: params
      },
      loading: true
    });
  },
  // b2c录入(新)草稿箱下单
  submitOrderNewForcg: function submitOrderNewForcg(params) {
    return fetch({
      url: '/draftBox/saveOrderDraftNew.do',
      timeout: 5 * 60 * 1000,
      data: {
        parameter: params
      },
      loading: true
    });
  },
  // 下单
  submitOrder: function submitOrder(params) {
    return fetch({
      url: '/b2c/saveB2COrdersNew.do',
      timeout: 5 * 60 * 1000,
      data: {
        parameter: params,
        operFlag: params.operFlag === 0 ? 0 : ''
      },
      loading: true
    });
  },
  // 渠道升级 美国下单
  submitOrderUS: function submitOrderUS(params) {
    return fetch({
      url: '/b2c/saveB2COrder.do',
      timeout: 5 * 60 * 1000,
      data: {
        parameter: params
      },
      loading: true
    });
  },
  // 客户产品类型
  getCustomerProjects: function getCustomerProjects(params) {
    return fetch({
      url: '/getCustomerProjects.do',
      data: {
        parameter: params
      },
      loading: true
    });
  },
  // 客户产品名称
  getCustomerProjectTables: function getCustomerProjectTables(params) {
    return fetch({
      url: '/getCustomerProjectTables.do',
      data: {
        parameter: params
      },
      loading: true
    });
  },
  // 一票多件下单
  saveOneTicketMultipleOrders: function saveOneTicketMultipleOrders(params) {
    return fetch({
      url: '/b2c/saveOneTicketMultipleOrders.do',
      data: {
        parameter: params
      },
      loading: true
    });
  },
  test: function test() {
    return fetch({
      url: '/test',
      mock: true
    });
  },
  exportData: function exportData(tableKey, params) {
    // console.log('tableKey--', tableKey)
    // console.log('exportparams--', params)
    var url;
    if (tableKey === '财务月结列表') url = '/api/exportBills.do';else if (tableKey === '订单管理列表' || tableKey === '订单查询列表') {
      url = '/api/customExportOrders.do';
    } else if (tableKey === '财务月结账单表') url = '/api/exportBillDetails.do';else if (tableKey === '我的运单列表') url = '/api/customExportSendOrder.do';else if (tableKey === '我的寄件地址列表' || tableKey === '我的收件地址列表') {
      url = '/api/exportAddress.do';
    } else if (tableKey === 'COD') url = '/api/exportCodManagement.do';else url = '/api/exportOrders.do';
    return fetch({
      url: url,
      data: params
    });
  },
  export: function _export(params) {
    return fetch({
      url: '/export.do',
      data: {
        parameter: params
      }
    });
  },
  // b2c订单校验
  validateOrder: function validateOrder(params) {
    return fetch({
      url: '/b2c/validateOrder.do',
      timeout: 5 * 60 * 1000,
      data: params
    });
  },
  validateB2COrdersUS: function validateB2COrdersUS(params) {
    return fetch({
      url: '/b2c/validateImportB2COrders.do',
      timeout: 5 * 60 * 1000,
      data: params
    });
  },
  saveB2COrdersUS: function saveB2COrdersUS(params) {
    return fetch({
      url: '/b2c/importB2COrders.do',
      timeout: 5 * 60 * 1000,
      data: params
    });
  },
  // B2C转运订单效验
  validateB2CBackOrder: function validateB2CBackOrder(params) {
    return fetch({
      url: '/b2c/validateB2CBackOrder.do',
      timeout: 5 * 60 * 1000,
      data: params
    });
  },
  // B2C退货转运订单
  saveB2CBackOrders: function saveB2CBackOrders(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/b2c/saveB2CBackOrders.do',
      timeout: 5 * 60 * 1000,
      data: param
    });
  },
  // b2c保存订单导入
  orderImportTask: function orderImportTask(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/b2c/orderImportTask.do',
      data: param
    });
  },
  backOrderImportTask: function backOrderImportTask(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/b2c/backOrderImportTask.do',
      data: param
    });
  },
  // b2c整包订单校验
  validWholeOrder: function validWholeOrder(params) {
    return fetch({
      url: '/b2c/checkZBInventory.do',
      data: params
    });
  },
  // b2c拆包订单校验
  validSplitOrder: function validSplitOrder(params) {
    return fetch({
      url: '/b2c/checkCBInventory.do',
      data: params
    });
  },
  // b2c到货预报导入
  addGoodsForecast: function addGoodsForecast(params) {
    return fetch({
      url: '/collect/addGoodsForecast.do',
      data: params
    });
  },
  // b2c到货预报导入校验
  verifyGoodsForecast: function verifyGoodsForecast(params) {
    return fetch({
      url: '/collect/verifyGoodsForecast.do',
      data: params
    });
  },
  // 微仓商品资料校验
  validMicroGoods: function validMicroGoods(params) {
    return fetch({
      url: '/wms/validateSku.do',
      data: params
    });
  },
  // 新微仓商品资料校验
  validMicroGoodsNews: function validMicroGoodsNews(params) {
    return fetch({
      url: '/goods/validateSaveSku.do',
      data: params
    });
  },
  /**
   * @description: 校验商品批量打印条码
   * @param {*} params
   */
  validBulkPrint: function validBulkPrint(params) {
    return fetch({
      url: '/validateImportSkuPrint.do',
      data: params
    });
  },
  // 批量修改
  batchEditOrder: function batchEditOrder(params) {
    return fetch({
      url: '/b2c/batchEditOrder.do',
      data: {
        parameter: params
      }
    });
  },
  // 批量修改大产品
  batchUpdateB2COrder: function batchUpdateB2COrder(params) {
    return fetch({
      url: '/b2c/batchUpdateB2COrder.do',
      data: {
        parameter: params
      }
    });
  },
  // 修改大产品
  updateB2COrder: function updateB2COrder(params) {
    return fetch({
      url: '/b2c/updateB2COrder.do',
      data: {
        parameter: params
      }
    });
  },
  // 微仓 商品资料启用禁用
  disableEnableSku: function disableEnableSku(params) {
    return fetch({
      url: '/wms/disableEnableSku.do',
      data: {
        parameter: params
      }
    });
  },
  returnOrder: function returnOrder(params) {
    return fetch({
      url: '/b2c/bounceOrder.do',
      data: {
        parameter: params
      }
    });
  },
  exportSerial: function exportSerial(params) {
    return fetch({
      url: '/common/getExportResult.do',
      data: {
        parameter: params
      }
    });
  },
  // 获取模板
  getExportFieldTemplate: function getExportFieldTemplate(params) {
    return fetch({
      url: '/eft/getExportFieldTemplate.do',
      data: {
        parameter: params
      }
    });
  },
  // 保存模板
  saveExportFieldTemplate: function saveExportFieldTemplate(params) {
    return fetch({
      url: '/eft/saveExportFieldTemplate.do',
      data: {
        parameter: params
      }
    });
  },
  // 修改模板
  updateExportFieldTemplate: function updateExportFieldTemplate(params) {
    return fetch({
      url: '/eft/updateExportFieldTemplate.do',
      data: {
        parameter: params
      }
    });
  },
  tracking: function tracking(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/common/tracking.do',
      data: param
    });
  },
  // 查询某月的账单
  queryMonthBill: function queryMonthBill(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/baseinfo/monthlyBill/queryMonthBill.do',
      data: param
    });
  },
  // 查询有账单的月份
  queryMonthList: function queryMonthList(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/baseinfo/monthlyBill/queryMonthList.do',
      data: param
    });
  },
  // 查询账单明细
  queryBillDetail: function queryBillDetail(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/baseinfo/monthlyBill/queryBillDetail.do',
      data: param
    });
  },
  // 确认账单
  confirmBill: function confirmBill(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/baseinfo/monthlyBill/confirmBill.do',
      data: param
    });
  },
  // b2c作废
  b2cOrderCancel: function b2cOrderCancel(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/b2c/deleteOrder.do',
      data: param
    });
  },
  b2bOrderCancel: function b2bOrderCancel(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/b2b/deleteOrder.do',
      data: param
    });
  },
  reForecastOrder: function reForecastOrder(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/b2c/reForecastOrder.do',
      data: param
    });
  },
  // b2c订单打印新
  b2cOrderPrintNew: function b2cOrderPrintNew(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/printOrderNew.do',
      data: param
    });
  },
  // 打印商品2023/1/5
  b2cOrderPrintForGoods: function b2cOrderPrintForGoods(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/printSku.do',
      data: param
    });
  },
  // b2c订单打印
  b2cOrderPrint: function b2cOrderPrint(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/print.do',
      data: param
    });
  },
  // b2c订单打印 E邮宝
  b2cOrderPrintEUB: function b2cOrderPrintEUB(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/edawkPrint.do',
      data: param
    });
  },
  // b2c 安达顺打印
  b2cOrderPrintChannelAbbr: function b2cOrderPrintChannelAbbr(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/printByChannelAbbr.do',
      data: param
    });
  },
  // b2c打印面单（末端）
  b2cOrderPrintTailChannel: function b2cOrderPrintTailChannel(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/printByTailChannel.do',
      data: param
    });
  },
  // 面单打印-开平
  b2cOrderPrintForkp: function b2cOrderPrintForkp(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/printOrderOpen.do',
      data: param
    });
  },
  // b2c订单打印 聚能
  b2cJuNengPrint: function b2cJuNengPrint(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/juNengPrint.do',
      data: param
    });
  },
  // b2c下载发货证明
  getProofDelivery: function getProofDelivery(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/b2c/getProofDelivery.do',
      data: param
    });
  },
  // b2c签收证明
  getProofSign: function getProofSign(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/b2c/getProofSign.do',
      data: param
    });
  },
  // b2c批量拦截
  b2cOrderIntercept: function b2cOrderIntercept(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/b2c/interceptOrder.do',
      data: param
    });
  },
  // b2c统计草稿箱与订单数量
  getB2COrdersCount: function getB2COrdersCount(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/b2c/getB2COrdersCount.do',
      data: param
    });
  },
  // B2c 订单详情
  b2cGetorderDetai: function b2cGetorderDetai(params) {
    var param = {
      parameter: params
    };
    param.operFlag = params.operFlag;
    return fetch({
      url: '/b2c/queryOrderDetailsList.do',
      data: param
    });
  },
  // 草稿箱b2c订单编辑
  updateOrderForCg: function updateOrderForCg(params) {
    return fetch({
      url: '/draftBox/updateOrderDraft.do',
      data: {
        parameter: params
      },
      loading: true
    });
  },
  // 草稿箱b2c汇总统计接口
  getOrderDraftCount: function getOrderDraftCount(params) {
    return fetch({
      url: '/draftBox/getOrderDraftCount.do',
      data: {
        parameter: params
      },
      loading: true
    });
  },
  // 草稿箱b2c订单（新）编辑
  updateOrderForCgNew: function updateOrderForCgNew(params) {
    return fetch({
      url: '/draftBox/updateOrderDraftNew.do',
      data: {
        parameter: params
      },
      loading: true
    });
  },
  // 订单编辑
  updateOrder: function updateOrder(params) {
    return fetch({
      url: '/b2c/editOrder.do',
      data: {
        parameter: params
      },
      loading: true
    });
  },
  // 微仓管理
  updateSku: function updateSku(params) {
    return fetch({
      url: '/wms/updateSku.do',
      data: {
        parameter: params
      },
      loading: true
    });
  },
  addSku: function addSku(params) {
    return fetch({
      url: '/wms/addSku.do',
      data: params
    });
  },
  deleteSku: function deleteSku(params) {
    // 批量删除
    return fetch({
      url: '/wms/deleteSku.do',
      data: {
        parameter: params
      }
    });
  },
  getSkus: function getSkus(params) {
    return fetch({
      url: '/wms/getSkus.do',
      data: {
        parameter: params
      }
    });
  },
  calOutWeight: function calOutWeight(params) {
    return fetch({
      url: '/wms/calOutWeight.do',
      data: {
        parameter: params
      }
    });
  },
  saveOutOrder: function saveOutOrder(params) {
    return fetch({
      url: '/wms/saveOutOrders.do',
      timeout: 5 * 60 * 1000,
      data: params
    });
  },
  validateOutOrder: function validateOutOrder(params) {
    return fetch({
      url: '/wms/validateOutOrder.do',
      timeout: 5 * 60 * 1000,
      data: params
    });
  },
  validEntryOrder: function validEntryOrder(params) {
    return fetch({
      url: '/wms/validatePutOrderForecast.do',
      data: params
    });
  },
  validatePutOrder: function validatePutOrder(params) {
    return fetch({
      url: '/wms/validatePutOrder.do',
      data: {
        parameter: params
      },
      loading: true
    });
  },
  cancelOrder: function cancelOrder(params) {
    return fetch({
      url: '/wms/cancelOrder.do',
      data: {
        parameter: params
      },
      loading: true
    });
  },
  addPutOrder: function addPutOrder(params) {
    return fetch({
      url: '/wms/addPutOrder.do',
      data: params
    });
  },
  validB2bOrder: function validB2bOrder(params) {
    return fetch({
      url: '/b2b/validateOrders.do',
      data: params
    });
  },
  saveB2BOrders: function saveB2BOrders(params) {
    return fetch({
      url: '/b2b/saveB2BOrders.do',
      data: params
    });
  },
  // 整包导入
  saveZBOrders: function saveZBOrders(params) {
    return fetch({
      url: '/b2c/saveZBOrders.do',
      data: params
    });
  },
  // 拆包导入
  saveCBOrders: function saveCBOrders(params) {
    return fetch({
      url: '/b2c/saveCBOrders.do',
      data: params
    });
  },
  // b2b
  // 下单
  submitB2BOrder: function submitB2BOrder(params) {
    return fetch({
      url: '/b2b/saveB2BOrder.do',
      data: {
        parameter: params
      },
      loading: true
    });
  },
  // 微仓
  // 仓储转单 批量转单
  transfer: function transfer(params) {
    return fetch({
      url: '/wms/transfer.do',
      data: {
        parameter: params
      }
    });
  },
  // 微仓
  // 库存查询 批量销毁
  destory: function destory(params) {
    return fetch({
      url: '/wms/destory.do',
      data: {
        parameter: params
      }
    });
  },
  // 微仓 出库单管理 数据修改
  editOutOrder: function editOutOrder(params) {
    return fetch({
      url: '/wms/editOutOrder.do',
      data: {
        parameter: params
      }
    });
  },
  // 微仓 查询出库单管理查询明细
  queryOutOrderDetailsList: function queryOutOrderDetailsList(params) {
    return fetch({
      url: '/wms/queryOutOrderDetailsList.do',
      data: {
        parameter: params
      }
    });
  },
  // FBA下单
  fbaSubmitOrder: function fbaSubmitOrder(params) {
    return fetch({
      url: '/fba/save.do',
      data: {
        parameter: params
      },
      loading: true
    });
  },
  // FBA订单校验
  validFbaOrder: function validFbaOrder(params) {
    return fetch({
      url: '/fba/validateOrder.do',
      data: params
    });
  },
  // FBA订单管理编辑订单
  fbaUpdateOrder: function fbaUpdateOrder(params) {
    return fetch({
      url: '/fba/update.do',
      data: {
        parameter: params
      }
    });
  },
  // FBA订单管理取消订单
  fbaCancelOrder: function fbaCancelOrder(params) {
    return fetch({
      url: '/fba/cancel.do',
      data: {
        parameter: params
      }
    });
  },
  // 出库单录入 新增 末端物流渠道
  getTailChannel: function getTailChannel() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return fetch({
      url: '/getTailChannel.do',
      data: {
        parameter: params
      }
    });
  },
  // 退货单管理 上传处理意见
  handlSuggestion: function handlSuggestion() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return fetch({
      url: '/tabReturnNo/handlSuggestion.do',
      data: {
        parameter: params
      }
    });
  },
  // 退货无头件管理 退货无头件-货品查看
  getHeadlessGoodsInfos: function getHeadlessGoodsInfos() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return fetch({
      url: '/tabReturnHeadless/getHeadlessGoodsInfos.do',
      data: {
        parameter: params
      }
    });
  },
  // 退货无头件管理 退货无头件-取消认领
  cancelHeadlessConfirm: function cancelHeadlessConfirm() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return fetch({
      url: '/tabReturnHeadless/cancelHeadlessConfirm.do',
      data: {
        parameter: params
      }
    });
  },
  // 退货无头件管理 退货无头件-认领
  returnHeadlessConfirm: function returnHeadlessConfirm() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return fetch({
      url: '/tabReturnHeadless/returnHeadlessConfirm.do',
      data: {
        parameter: params
      }
    });
  },
  // 退货无头件管理 退货无头件-预报
  returnForecast: function returnForecast() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return fetch({
      url: '/tabReturnHeadless/returnForecast.do',
      data: {
        parameter: params
      }
    });
  },
  // 查询退货单的订单信息和商品信息
  getReturnOrderNoInfo: function getReturnOrderNoInfo(params) {
    return fetch({
      url: 'tabReturnNo/getReturnOrderNoInfo.do',
      data: {
        parameter: params
      },
      loading: true
    });
  },
  // 退货单预报保存
  saveReturnNos: function saveReturnNos(params) {
    return fetch({
      url: 'tabReturnNo/saveReturnNos.do',
      data: {
        parameter: params
      },
      loading: true
    });
  },
  // 退货单批量预报校验
  validateReturnOrderNo: function validateReturnOrderNo(params) {
    return fetch({
      url: 'tabReturnNo/validateReturnNos.do',
      data: params
    });
  },
  // 退货单批量预报保存
  batchSaveReturnOrderNo: function batchSaveReturnOrderNo(params) {
    return fetch({
      url: 'tabReturnNo/importSaveReturnNos.do',
      data: params
    });
  },
  // 运费试算新接口 2023/6/5
  trialFreight: function trialFreight(params) {
    return fetch({
      url: '/trialFreight.do',
      data: {
        parameter: params
      }
    });
  },
  // b2c订单查询
  b2cQueryOrderList: function b2cQueryOrderList(params) {
    return fetch({
      url: 'b2c/queryOrderList.do',
      data: {
        parameter: params
      }
    });
  },
  // b2c上传订单
  updateInvoiceUrl: function updateInvoiceUrl(params) {
    return fetch({
      url: 'b2c/updateInvoiceUrl.do',
      data: {
        parameter: params
      }
    });
  },
  // b2c快递寄件
  saveSCOrder: function saveSCOrder(params) {
    return fetch({
      url: 'scOrder/saveSCOrder.do',
      data: {
        parameter: params
      }
    });
  },
  // b2c快递寄件 重推订单
  orderRetweet: function orderRetweet(params) {
    return fetch({
      url: 'scOrder/orderRetweet.do',
      data: {
        parameter: params
      }
    });
  },
  // 数据字典
  getDictionaryData: function getDictionaryData(params) {
    return fetch({
      url: 'getDictionaryData.do',
      data: {
        parameter: params
      }
    });
  },
  // b2c快递寄件 取消订单
  cancelSCOrder: function cancelSCOrder(params) {
    return fetch({
      url: 'scOrder/cancelOrder.do',
      data: {
        parameter: params
      }
    });
  },
  // 修改出库单明细
  updateOutOrderDetail: function updateOutOrderDetail(params) {
    return fetch({
      url: 'wms/updateOutOrderDetail.do',
      data: {
        parameter: params
      }
    });
  },
  // 币别汇率转换
  getConvertPrice: function getConvertPrice(params) {
    return fetch({
      url: '/getConvertPrice.do',
      data: {
        parameter: params
      }
    });
  },
  // 出库单出库类型查询
  getOutboundType: function getOutboundType(params) {
    return fetch({
      url: '/getOutboundType.do',
      data: {
        parameter: params
      }
    });
  },
  // 出库单录入寄件人信息填入
  querySenderForck: function querySenderForck(params) {
    return fetch({
      url: '/baseinfo/address/querySenderList.do',
      data: {
        parameter: params
      }
    });
  },
  // 客户认领无头件
  claimByCustomer: function claimByCustomer(params) {
    return fetch({
      url: '/headlessExpress/claimByCustomer.do',
      data: {
        parameter: params
      }
    });
  },
  // 取消认领无头件
  cancelByCustomer: function cancelByCustomer(params) {
    return fetch({
      url: '/headlessExpress/cancelByCustomer.do',
      data: {
        parameter: params
      }
    });
  },
  // 处置无头件
  treatmentByCustomer: function treatmentByCustomer(params) {
    return fetch({
      url: '/headlessExpress/treatmentByCustomer.do',
      data: {
        parameter: params
      }
    });
  },
  // 云仓批量拦截
  interceptOrder: function interceptOrder(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/wms/interceptOrder.do',
      data: param
    });
  },
  // 拦截件校验
  createValidate: function createValidate(params) {
    return fetch({
      url: '/b2c/orderIntercept/createValidate.do',
      data: params
    });
  },
  orderInterceptBatch: function orderInterceptBatch(params) {
    return fetch({
      url: '/b2c/orderInterceptBatch.do',
      data: params
    });
  },
  getPutOrderItems: function getPutOrderItems(params) {
    return fetch({
      url: '/wms/getPutOrderItems.do',
      data: {
        parameter: params
      }
    });
  },
  deleteGoodsForecastItems: function deleteGoodsForecastItems(params) {
    return fetch({
      url: '/collect/deleteGoodsForecastItems.do',
      data: {
        parameter: params
      }
    });
  },
  // 美国二期-箱唛打印
  printUsPutOrder: function printUsPutOrder(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/printUsPutOrder.do',
      data: param
    });
  },
  queryB2cOrderByStatus: function queryB2cOrderByStatus(params) {
    return fetch({
      url: '/scOrder/queryB2cOrderByStatus.do',
      data: {
        parameter: params
      }
    });
  },
  // 快递寄件导入校验
  verifyOrderItems: function verifyOrderItems(params) {
    return fetch({
      url: '/scOrder/verifyOrderItems.do',
      data: params
    });
  },
  // 获取下单校验规则
  getOrderBasicRule: function getOrderBasicRule(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/getB2cOrderRulebasic.do',
      data: param
    });
  },
  // 账号线上充值
  onlinePayment: function onlinePayment(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/pay/onlinePayment.do',
      data: param
    });
  },
  // 账号支付宝，微信充值
  scanPay: function scanPay(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/pay/scanPay.do',
      data: param
    });
  },
  // 查询支付结果
  querScanPayStuts: function querScanPayStuts(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/pay/querScanPayStuts.do',
      data: param
    });
  },
  // 线下充值
  offlineRecharge: function offlineRecharge(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/pay/offlineRecharge.do',
      data: param
    });
  },
  // 线下充值-未处理作废
  deleteOfflineEcords: function deleteOfflineEcords(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/pay/deleteOfflineEcords.do',
      data: param
    });
  },
  // 财务-账户信息更新
  getPrepaymentsInfo: function getPrepaymentsInfo(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/pay/getPrepaymentsInfo.do',
      data: param
    });
  },
  // 财务-交易类型枚举
  queryTransactionType: function queryTransactionType(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/pay/queryTransactionType.do',
      data: param
    });
  },
  // B2C订单修改重量导入
  weightImport: function weightImport(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/b2c/orderUpdateWeightImportTask.do',
      data: param
    });
  },
  // B2C模块 审核结果查询列表信息补充
  orderBatchReplenish: function orderBatchReplenish(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/risk/orderBatchReplenish.do',
      data: param
    });
  },
  // B2C模块 品名审核结果查询列表 批量同意
  batchAgree: function batchAgree(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/risk/batchAgree.do',
      data: param
    });
  },
  // B2C模块 品名审核结果查询列表 批量不同意
  batchUnAgree: function batchUnAgree(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/risk/batchNoAgree.do',
      data: param
    });
  },
  // 申报品管理需求开始
  // 新增申报品
  saveOrderDeclareGoods: function saveOrderDeclareGoods(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/orderDeclareGoods/saveOrderDeclareGoods.do',
      data: param
    });
  },
  // 导入后批量创建申报品
  saveOrderDeclareBatch: function saveOrderDeclareBatch(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/orderDeclareGoods/saveOrderDeclareBatch.do',
      data: param
    });
  },
  // 编辑申报品
  updateOrderDeclareGoods: function updateOrderDeclareGoods(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/orderDeclareGoods/updateOrderDeclareGoods.do',
      data: param
    });
  },
  // 删除申报品
  del: function del(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/orderDeclareGoods/deleteBatchOrderDeclareGoods.do',
      data: param
    });
  },
  // 异常订单查询统计tab卡 2023/06/21
  getCountGoodsState: function getCountGoodsState(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/problemPiece/getProblemPieceCount.do',
      data: param
    });
  },
  getProblemPieceTypeNoPage: function getProblemPieceTypeNoPage(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/problemPieceType/getProblemPieceTypeNoPage.do',
      data: param
    });
  },
  /**
   * B2C订单末端单号回传
   * @param params
   * @returns {*}
   */
  callBackTracking: function callBackTracking(params) {
    var param = {
      parameter: params
    };
    return fetch({
      url: '/b2c/callBackTrackingNo.do',
      data: param
      // headers: {
      //   'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      // }
    });
  }
};

export default order;