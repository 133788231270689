//
//
//
//

import ylTable from '@/ylCommon/components/Table/index.vue';
export default {
  components: {
    ylTable: ylTable
  },
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {};
  }
};