import "core-js/modules/es6.array.find";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//

import draggable from 'vuedraggable';
import { mapGetters, mapMutations } from 'vuex';
import { formatFormItem, removeAllNonItem } from "../utils/global";
import _ from 'loadsh';
import { parseStyles } from "../utils/style";
export default {
  components: {
    draggable: draggable
  },
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['collection'])), {}, {
    dragOptions: function dragOptions() {
      return {
        animation: 0,
        group: 'component',
        handle: '.move-bar'
      };
    },
    styles: function styles() {
      var _this$data$field = this.data.field,
        styles = _this$data$field.styles,
        props = _this$data$field.props;
      return parseStyles(styles, props);
    },
    containerClass: function containerClass() {
      var _this$data$field2 = this.data.field,
        paramsKey = _this$data$field2.paramsKey,
        styles = _this$data$field2.styles,
        children = _this$data$field2.children;
      var result = [paramsKey];
      var newClass = children.length === 0 && Boolean(styles.backgroundImage) === false ? 'no-field' : '';
      newClass && result.push(newClass);
      return result;
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapMutations({
    addFormItemToForm: 'ADD_FORM_ITEM_TO_FORM'
  })), {}, {
    onAdd: function onAdd(data) {
      removeAllNonItem();
      var isSort = data.item.className.includes('form-drag-warp');
      if (isSort) return;
      var id = data.item.getAttribute('field-id');
      var parentId = data.to.getAttribute('data-parent');
      var field = formatFormItem(_.cloneDeep(this.collection.find(function (item) {
        return item.id === id;
      })));
      field.parentId = parentId;
      field.parentType = 'container';
      var obj = {
        parentId: parentId,
        field: field,
        index: data.newIndex
      };
      this.addFormItemToForm(obj);
    },
    handleFieldClick: function handleFieldClick() {}
  })
};