var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { attrs: { id: "app-container" } },
    [
      _c(
        "el-header",
        { staticClass: "app-header-warp", attrs: { height: "56px" } },
        [_c("header-nav")],
        1
      ),
      _vm._v(" "),
      _c(
        "el-container",
        [
          _c(
            "el-aside",
            {
              staticClass: "app-aside",
              style: _vm.getAsideStyle,
              attrs: { width: _vm.asideWidth },
            },
            [
              _c(
                "transition",
                { attrs: { name: "el-fade-in" } },
                [
                  _c("search-module", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchModuleVisible,
                        expression: "searchModuleVisible",
                      },
                    ],
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "el-fade-in" } },
                [_vm.asideMenuVisible ? _c("aside-menu") : _vm._e()],
                1
              ),
              _vm._v(" "),
              _c("collapse-btn", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.collapseBtnVisible,
                    expression: "collapseBtnVisible",
                  },
                ],
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-main",
            { staticClass: "app-main-warp" },
            [_c("tags-view"), _vm._v(" "), _c("app-main")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }