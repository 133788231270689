import "core-js/modules/es6.array.find";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//

import draggable from 'vuedraggable';
import { mapGetters, mapMutations } from 'vuex';
import { formatFormItem, removeAllNonItem } from "../utils/global";
import _ from 'loadsh';
import { parseStyles } from "../utils/style";
export default {
  components: {
    draggable: draggable
  },
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['collection'])), {}, {
    formClass: function formClass() {
      return this.data.field.children.length === 0 ? 'no-field' : '';
    },
    styles: function styles() {
      return parseStyles(this.data.field.styles, this.data.field.props);
    },
    dragOptions: function dragOptions() {
      return {
        group: {
          name: 'component'
        },
        animation: 250
      };
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapMutations({
    addFormItemToForm: 'ADD_FORM_ITEM_TO_FORM'
  })), {}, {
    onFormLayoutAdd: function onFormLayoutAdd(data) {
      removeAllNonItem();
      var isSort = data.item.className.includes('form-drag-warp');
      if (isSort) return;
      var id = data.item.getAttribute('field-id');
      var parentId = data.to.getAttribute('data-parent');
      var field = formatFormItem(_.cloneDeep(this.collection.find(function (item) {
        return item.id === id;
      })));
      field.parentId = parentId;
      field.parentType = 'form';
      var obj = {
        parentId: parentId,
        field: field,
        index: data.newIndex
      };
      this.addFormItemToForm(obj);
    },
    getColComponentData: function getColComponentData() {
      var _this$data$field$prop = this.data.field.props,
        labelPosition = _this$data$field$prop.labelPosition,
        labelWidth = _this$data$field$prop.labelWidth,
        size = _this$data$field$prop.size,
        statusIcon = _this$data$field$prop.statusIcon;
      var model = {};
      return {
        props: {
          labelPosition: labelPosition,
          labelWidth: labelWidth,
          size: size,
          statusIcon: statusIcon,
          model: model
        }
      };
    },
    handleFieldClick: function handleFieldClick() {}
  })
};