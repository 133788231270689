//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { parseStyles } from "../../../views/setting/design/form/utils/style";
import { transformFun, generateEvents } from "../../../views/setting/design/form/utils/event";
export default {
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      events: {}
    };
  },
  computed: {
    styles: function styles() {
      return parseStyles(this.data.field.styles, this.data.field.props);
    }
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      this.events = generateEvents(this.data.field.events);
    },
    handleClick: function handleClick() {
      var data = this.events.onClick;
      if (data) transformFun(data.event, data.eventName, null, this.context);
    }
  }
};