import Hint from '@/ylCommon/components/Hint/index.vue';
import ylTable from '@/ylCommon/components/Table/index.vue';
var components = [{
  install: function install(Vue) {
    Vue.component('y-hint', Hint);
  }
}, {
  install: function install(Vue) {
    Vue.component('ylTable', ylTable);
  }
}];
export default components;