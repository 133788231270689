import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//

import addressBookDialog from '@/public/yl-addressBook/Index.vue';
import { transformFun } from '@/views/setting/design/form/utils/event';
import { parseStyles } from '@/views/setting/design/form/utils/style';
export default {
  components: {
    addressBookDialog: addressBookDialog
  },
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    context: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      value: '',
      events: {
        onSelect: '',
        onBlur: '',
        onFocus: ''
      }
    };
  },
  computed: {
    styles: function styles() {
      return parseStyles(this.data.field.styles, this.data.field.props);
    }
  },
  created: function created() {
    this.init();
  },
  methods: {
    handleClick: function handleClick() {
      this.$refs['address-book'].init();
    },
    setValue: function setValue(value) {
      // doing something
    },
    init: function init() {},
    onInput: function onInput() {
      this.$emit('input', {
        key: this.data.field.paramsKey,
        value: this.value
      });
    },
    onSelectAddress: function onSelectAddress(row) {
      var _this = this;
      var selectEvent = this.data.field.select;
      this.value = "".concat(row.name);
      // this.value = `${row.name} ${row.phone} ${row.prov} ${row.city} ${row.area} ${row.address}`
      this.$nextTick(function () {
        _this.$refs['address-book'].hide();
        transformFun(selectEvent, 'onSelect', row, _this.context, function (result) {
          _this.$emit('input', {
            key: _this.data.field.paramsKey,
            value: _this.value
          });
        });
      });
    }
  }
};