//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { transformFun } from '@/views/setting/design/form/utils/event';
export default {
  components: {},
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      value: '',
      loading: true,
      events: {
        onChange: '',
        onBlur: '',
        onFocus: '',
        onClear: ''
      }
    };
  },
  computed: {},
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {},
    setValue: function setValue(value) {
      this.value = value;
      this.inputValue();
    },
    inputValue: function inputValue() {
      this.$emit('input', {
        key: this.data.field.paramsKey,
        value: this.value
      });
    },
    onChange: function onChange(value) {
      var _this = this;
      var onChange = this.data.field.change;
      if (onChange) {
        transformFun(onChange, 'onChange', value, this.context, function (result) {
          result && _this.$emit('input', {
            key: _this.data.field.paramsKey,
            value: result || _this.value
          });
        });
      } else this.inputValue();
    },
    onFocus: function onFocus() {
      var onFocus = this.data.field.focus;
      if (onFocus) {
        transformFun(onFocus, 'onFocus', null, this.context);
      }
    }
  }
};