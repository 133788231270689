//
//
//
//
//
//
//
//

export default {
  name: 'Index',
  props: {
    type: {
      type: String,
      default: 'primary'
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    theme: function theme() {
      var className = 'primary-hint';
      var type = this.type;
      if (type === 'primary') className = 'primary-hint';else if (type === 'danger') className = 'danger-hint';else if (type === 'warning') className = 'warning-hint';else if (type === 'success') className = 'success-hint';
      return className;
    }
  }
};