import TableGetter from '@/plugins/app/index.js';
import CountTo from 'vue-count-to';
export default {
  name: 'Cell',
  components: {
    CountTo: CountTo
  },
  props: {
    context: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    row: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    createElement: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
      default: ''
    }
  },
  render: function render(h) {
    var result = null;
    if (this.createElement) {
      result = TableGetter.renderFun(h, this.row, this.createElement, this.context.$parent || this.context);
    } else {
      result = h('span', {
        domProps: {
          // innerHTML: this.row[this.prop]
          innerHTML: this.$XSS.sanitize($ylI18n.lang(this.row[this.prop])) // 过滤风险数据防止XSS攻击
        }
      });
    }

    return result;
  }
};