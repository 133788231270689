//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { transformFun } from '@/views/setting/design/form/utils/event';
export default {
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      value: '',
      events: {
        onChange: '',
        onBlur: '',
        onFocus: ''
      }
    };
  },
  computed: {},
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {},
    onInput: function onInput() {
      this.$emit('input', {
        key: this.data.field.paramsKey,
        value: this.value
      });
    },
    inputValue: function inputValue(value) {
      this.$emit('input', {
        key: this.data.field.paramsKey,
        value: value || this.value
      });
    },
    onChange: function onChange(value) {
      var onChange = this.data.field.change;
      if (onChange) {
        transformFun(onChange, 'onChange', value, this.context);
      } else this.inputValue();
    },
    onFocus: function onFocus() {
      var onFocus = this.data.field.focus;
      if (onFocus) {
        transformFun(onFocus, 'onFocus', null, this.context);
      }
    }
  }
};