var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    _vm._b(
      {
        staticClass: "grid-warp",
        style: _vm.styles,
        attrs: {
          tag: "el-row",
          justify: _vm.data.field.props.justify,
          gutter: _vm.data.field.props.gutter,
          align: _vm.data.field.props.align,
          type: "flex",
        },
        nativeOn: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.onClick($event)
          },
        },
      },
      "el-row",
      _vm.dragOptions,
      false
    ),
    _vm._l(_vm.data.field.children, function (item, index) {
      return _c(
        "draggable",
        _vm._b(
          {
            key: index,
            staticClass: "filed-box",
            class: item.children.length === 0 ? "no-field" : "",
            attrs: {
              tag: "el-col",
              "component-data": _vm.getColComponentData(item),
              "data-parent": item.parentId,
              "data-id": item.elementId,
            },
            on: { add: _vm.onGridAddField },
            model: {
              value: item.children,
              callback: function ($$v) {
                _vm.$set(item, "children", $$v)
              },
              expression: "item.children",
            },
          },
          "draggable",
          _vm.dragOptions,
          false
        ),
        [
          item.children.length > 0
            ? _vm._l(item.children, function (tag) {
                return _c("drag-component", {
                  key: tag.elementId,
                  attrs: { field: tag, "element-id": item.elementId },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleFieldClick(tag)
                    },
                  },
                })
              })
            : _vm._e(),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }