var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "check-code-dialog",
      attrs: {
        title: _vm.$t("验证码"),
        "append-to-body": "",
        width: "420px",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            { attrs: { abel: _vm.$t("管理员验证码"), prop: "code" } },
            [
              _c("el-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.form.code,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "code",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.code",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "mini" },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("确定")))]
          ),
          _vm._v(" "),
          _c("el-button", { attrs: { size: "mini" } }, [
            _vm._v(_vm._s(_vm.$t("取消"))),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }