var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "login-repeat-dialog",
      attrs: {
        visible: _vm.dialogVisible,
        width: "560px",
        title: _vm.$t("登陆信息"),
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content-area" },
        [
          _c("y-hint", { attrs: { type: "danger" } }, [
            _c("div", { staticClass: "fs-12 mb-10" }, [
              _vm._v(
                "1." +
                  _vm._s(_vm.$t("此账号不允许多人登陆，可在个人信息中修改"))
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fs-12" }, [
              _vm._v("2." + _vm._s(_vm.$t("继续登陆将会强制下线已登陆账号"))),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            { attrs: { data: _vm.data } },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: _vm.$t("序号") },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "date", label: _vm.$t("时间") },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "ip", label: _vm.$t("IP地址") },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small" },
              on: { click: _vm.handleContinue },
            },
            [_vm._v(_vm._s(_vm.$t("继续登陆")))]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleCancel } },
            [_vm._v(_vm._s(_vm.$t("取消登陆")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }