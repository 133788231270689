import dragComponent from '@/views/setting/design/lowCode/components/drag.vue';
import gridItem from '@/ylCommon/components/GenerateForm/grid.vue';
import richTextItem from '@/ylCommon/components/LowCode/richText.vue';
import setComponent from '@/ylCommon/components/LowCode/setComponent';
import containerItem from '@/ylCommon/components/LowCode/container';
import buttonDrawerItem from '@/ylCommon/components/LowCode/buttonDrawer.vue';
var components = [{
  install: function install(Vue) {
    Vue.component('drag-component', dragComponent);
  }
}, {
  install: function install(Vue) {
    Vue.component('set-component', setComponent);
  }
}, {
  install: function install(Vue) {
    Vue.component('gridItem', gridItem);
  }
}, {
  install: function install(Vue) {
    Vue.component('richTextItem', richTextItem);
  }
}, {
  install: function install(Vue) {
    Vue.component('containerItem', containerItem);
  }
}, {
  install: function install(Vue) {
    Vue.component('buttonDrawerItem', buttonDrawerItem);
  }
}];
export default components;