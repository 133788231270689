//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { setVCode } from '@/ylCommon/utils/auth.js';
export default {
  data: function data() {
    return {
      dialogVisible: false,
      code: '',
      form: {
        code: ''
      },
      rules: {
        code: [{
          required: true,
          message: this.$t('请输入验证码'),
          trigger: 'change'
        }]
      }
    };
  },
  methods: {
    handleSubmit: function handleSubmit() {
      var _this = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this.dialogVisible = false;
          setVCode(_this.form.code);
          _this.$emit('success');
        }
      });
    },
    init: function init() {
      this.dialogVisible = true;
    }
  }
};