var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scm-nav-warp", style: _vm.styles },
    [
      _c(
        "draggable",
        _vm._b(
          {
            staticClass: "left-area",
            class: _vm.logoAreaClass,
            attrs: { "data-parent": _vm.data.elementId },
            on: { add: _vm.onAdd },
          },
          "draggable",
          _vm.dragOptions,
          false
        ),
        _vm._l(_vm.data.field.children, function (item) {
          return _c("drag-component", {
            key: item.elementId,
            attrs: { field: item, "element-id": item.elementId },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "right-area" }, [
        _c(
          "div",
          { staticClass: "menu-list" },
          _vm._l(_vm.data.field.options, function (item) {
            return _c(
              "div",
              {
                key: item.value,
                staticClass: "item",
                class: item.label === _vm.current ? "current" : "",
                on: {
                  click: function ($event) {
                    return _vm.handleClick(item.label)
                  },
                },
              },
              [
                _c("i", { staticClass: "menu-icon", class: item.value }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-name" }, [
                  _vm._v(_vm._s(item.label)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "line" }),
              ]
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }