var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.parentType !== "form"
    ? _c(
        "div",
        { staticClass: "native-field-warp", style: _vm.styles },
        [
          _c("div", { staticClass: "field-label" }, [
            _vm._v(_vm._s(_vm.data.field.name)),
          ]),
          _vm._v(" "),
          _c("el-input", {
            class: _vm.data.field.props.className,
            attrs: {
              type: "textarea",
              placeholder: _vm.data.field.props.placeholder,
              rows: _vm.data.field.props.rows,
              maxlength: _vm.data.field.props.maxlength,
              resize: _vm.data.field.props.resize,
              "show-word-limit": _vm.data.field.props.showWordLimit,
              readonly: _vm.data.field.props.readonly,
              autosize: _vm.data.field.props.autosize,
            },
            on: {
              input: _vm.onInput,
              change: _vm.onChange,
              focus: _vm.onFocus,
              blur: _vm.onBlur,
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      )
    : _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.data.field.visible,
              expression: "data.field.visible",
            },
          ],
          class: _vm.data.elementId,
          style: _vm.styles,
          attrs: { label: _vm.data.field.name, prop: _vm.data.field.paramsKey },
        },
        [
          _c("el-input", {
            class: _vm.data.field.props.className,
            attrs: {
              type: "textarea",
              placeholder: _vm.data.field.props.placeholder,
              rows: _vm.data.field.props.rows,
              maxlength: _vm.data.field.props.maxlength,
              resize: _vm.data.field.props.resize,
              "show-word-limit": _vm.data.field.props.showWordLimit,
              readonly: _vm.data.field.props.readonly,
              autosize: _vm.data.field.props.autosize,
            },
            on: {
              input: _vm.onInput,
              change: _vm.onChange,
              focus: _vm.onFocus,
              blur: _vm.onBlur,
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }