import fetch from '@/ylCommon/utils/request';
var address = {
  addRegion: function addRegion(options) {
    // 新增地址
    var params = {
      parameter: options
    };
    return fetch({
      url: '/common/insertAddr.do',
      data: params
    });
  },
  addKey: function addKey(options) {
    // 申请秘钥
    var params = {
      parameter: options
    };
    return fetch({
      url: '/common/applySecretKey.do',
      data: params
    });
  },
  search: function search(options) {
    var params = {
      /* 查询 */
      parameter: options
    };
    return fetch({
      url: '/api/queryList.do',
      data: params
    });
  },
  del: function del(options) {
    /* 删除 */
    var params = {
      parameter: options
    };
    return fetch({
      url: '/common/deleteAddr.do',
      data: params
    });
  },
  defaultAddress: function defaultAddress(options) {
    /* 设为默认地址 */
    var params = {
      parameter: options
    };
    return fetch({
      url: '/api/setDefaultAddress.do',
      data: params
    });
  },
  cancelDefault: function cancelDefault(options) {
    /* 取消默认地址 */
    var params = {
      parameter: options
    };
    return fetch({
      url: '/api/cancelDefaultAddress.do',
      data: params
    });
  },
  compile: function compile(options) {
    /* 编辑 地址*/
    var params = {
      parameter: options
    };
    return fetch({
      url: '/common/updateSenderAddr.do',
      data: params
    });
  },
  opation: function opation(options) {
    /* 地址三级联动数据 */
    var params = {
      parameter: options
    };
    return fetch({
      url: '/api/list.do',
      data: params
    });
  },
  instantly: function instantly(options) {
    /* 立即下单 */
    var params = {
      parameter: options
    };
    return fetch({
      url: '/api/quickOrder.do',
      data: params
    });
  },
  changePwd: function changePwd(options) {
    /* 修改密码 */
    var params = {
      parameter: options
    };
    return fetch({
      url: '/api/updatePassWord.do',
      data: params
    });
  },
  sendAddressnge: function sendAddressnge(options) {
    /* 修改地址 */
    var params = {
      parameter: options
    };
    return fetch({
      url: '/api/queryList.do',
      data: params
    });
  },
  changeInfo: function changeInfo(options) {
    /* 修改用户信息 */
    var params = {
      parameter: options
    };
    return fetch({
      url: '/api/updateUser.do',
      data: params
    });
  },
  getInfo: function getInfo(options) {
    /* 查询用户信息接口 */
    var params = {
      parameter: options
    };
    return fetch({
      url: '/api/userList.do',
      data: params
    });
  },
  advice: function advice(options) {
    /* 投诉建议接口 */
    var params = {
      parameter: options
    };
    return fetch({
      url: '/api/addComplaint.do',
      data: params
    });
  },
  expressQuery: function expressQuery(options) {
    /* 快件跟踪查询 */
    var params = {
      parameter: options
    };
    return fetch({
      url: '/api/trackingList.do',
      data: params
    });
  },
  prepare: function prepare(options) {
    /* cod查询 */
    var params = {
      parameter: options
    };
    return fetch({
      url: '/api/codManagement.do',
      data: params
    });
  },
  tag: function tag(options) {
    /* cod查询 */
    var params = {
      parameter: options
    };
    return fetch({
      url: '/api/codCount.do',
      data: params
    });
  },
  permissionsQuery: function permissionsQuery(options) {
    /* 权限管理查询 */
    var params = {
      parameter: options
    };
    return fetch({
      url: '/api/userList.do',
      data: params
    });
  },
  addUser: function addUser(options) {
    /* 权限管理新增用户 */
    var params = {
      parameter: options
    };
    return fetch({
      url: '/api/addUser.do',
      data: params
    });
  },
  updateUser: function updateUser(options) {
    /* 权限管理修改用户 */
    var params = {
      parameter: options
    };
    return fetch({
      url: '/api/updateUser.do',
      data: params
    });
  },
  deleteUser: function deleteUser(options) {
    /* 权限管理删除用户 */
    var params = {
      parameter: options
    };
    return fetch({
      url: '/api/deleteUser.do',
      data: params
    });
  },
  updatePassWord: function updatePassWord(options) {
    /* 权限管理删除用户 */
    var params = {
      parameter: options
    };
    return fetch({
      url: '/api/updatePassWord.do',
      data: params
    });
  },
  getSsq: function getSsq(options) {
    var params = {
      parameter: options
    };
    return fetch({
      url: '/getSsq.do',
      data: params
    });
  },
  getCountry: function getCountry(parame) {
    return fetch({
      url: '/getCountry.do',
      data: {
        parameter: parame || {}
      }
    });
  },
  getWarehouseInfo: function getWarehouseInfo() {
    return fetch({
      url: '/getWarehouseInfo.do',
      data: {
        parameter: {}
      }
    });
  },
  // 出库单获取配置仓库信息
  getOutWarehouseInfo: function getOutWarehouseInfo(params) {
    return fetch({
      url: '/getOutWarehouseInfo.do',
      data: {
        parameter: params
      }
    });
  },
  getGoodsType: function getGoodsType() {
    return fetch({
      url: '/getGoodsType.do',
      data: {
        parameter: {}
      }
    });
  },
  getTransportMode: function getTransportMode() {
    return fetch({
      url: '/getTransportMode.do',
      data: {
        parameter: {}
      }
    });
  },
  getOverseasWarehouse: function getOverseasWarehouse() {
    return fetch({
      url: '/getOverseasWarehouse.do',
      data: {
        parameter: {}
      }
    });
  },
  getOrderStatus: function getOrderStatus() {
    // 获取订单状态
    return fetch({
      url: '/getScanOrderyType.do',
      data: {
        parameter: {}
      }
    });
  },
  getProductLine: function getProductLine(options) {
    var params = {
      parameter: options
    };
    return fetch({
      url: '/getRoute.do',
      data: params
    });
  }
};
export default address;