import fetch from '@/ylCommon/utils/request';
var e3 = {
  getCountry: function getCountry(params) {
    return fetch({
      url: '/e3/getCountries.do',
      data: params
    });
  },
  getInvoiceTypes: function getInvoiceTypes(params) {
    return fetch({
      url: '/e3/getInvoiceTypes.do',
      data: params
    });
  },
  getOutboundOrders: function getOutboundOrders(params) {
    return fetch({
      url: '/e3/getOutboundOrders.do',
      data: params
    });
  },
  login: function login(params) {
    return fetch({
      url: '/e3/getToken.do',
      data: params
    });
  },
  e3Print: function e3Print(params) {
    return fetch({
      url: '/e3/print.do',
      data: params
    });
  }
};
export default e3;