import enterToNext from "./enterToNext";
import Vue from 'vue';
var install = function install(Vue) {
  Vue.directive('el-enterto-next', enterToNext);
};
if (window.Vue) {
  window['next'] = enterToNext;
}
Vue.use(install);
enterToNext.install = install;
export default enterToNext;