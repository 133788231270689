var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "scroll-warp",
      staticClass: "yl-scroll-pane-warp",
      style: _vm.getWarpStyle,
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.scrollable,
              expression: "scrollable",
            },
          ],
          staticClass: "left-control",
          on: { click: _vm.handlePrev },
        },
        [
          _vm._t("left-control", [
            _c("i", {
              class: _vm.isHorizontal
                ? "el-icon-caret-left"
                : "el-icon-caret-top",
            }),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "view", staticClass: "view-box", style: _vm.getViewStyle },
        [
          _c(
            "div",
            {
              ref: "scroll",
              staticClass: "scroll-box",
              style: _vm.getScrollStyle,
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.scrollable,
              expression: "scrollable",
            },
          ],
          staticClass: "right-control",
          on: { click: _vm.handleNext },
        },
        [
          _vm._t("right-control", [
            _c("i", {
              class: _vm.isHorizontal
                ? "el-icon-caret-right"
                : "el-icon-caret-bottom",
            }),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }