import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import _UxTableColumn2 from "umy-ui/lib/theme-chalk/ux-table-column.css";
import "umy-ui/lib/theme-chalk/base.css";
import _UxTableColumn from "umy-ui/lib/ux-table-column";
import _UxGrid2 from "umy-ui/lib/theme-chalk/ux-grid.css";
import "umy-ui/lib/theme-chalk/base.css";
import _UxGrid from "umy-ui/lib/ux-grid";
import _UTable2 from "umy-ui/lib/theme-chalk/u-table.css";
import "umy-ui/lib/theme-chalk/base.css";
import _UTable from "umy-ui/lib/u-table";
import _UTableColumn2 from "umy-ui/lib/theme-chalk/u-table-column.css";
import "umy-ui/lib/theme-chalk/base.css";
import _UTableColumn from "umy-ui/lib/u-table-column";
import "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/core-js/modules/es6.array.iterator.js";
import "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/core-js/modules/es6.promise.js";
import "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/core-js/modules/es6.object.assign.js";
import "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/core-js/modules/es7.promise.finally.js";
/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-08 10:59:09
 * @LastEditors: zengpan 563821157@qq.com
 * @LastEditTime: 2022-12-08 10:40:47
 * @FilePath: /yl-vip-customer-front/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue';
import 'normalize.css/normalize.css';
import Element from 'element-ui';
import message from '@/ylCommon/utils/resetMessage.js';
// import '@jt/yl-business/dist/es/style.css'
import "./ylCommon/styles/element-variables.scss";
import '@/ylCommon/styles/index.scss';
import '@/ylCommon/styles/animate.css';
import App from "./App";
import store from "./store";
import router from "./router";
import plugins from "./plugins";
import directives from "./ylCommon/directive";
// import Api from './api'
// import i18n from './lang'
import "./ylCommon/icons";
import "./permission";
import "./ylCommon/utils/errorLog";
import * as filters from "./ylCommon/filters";
import components from '@/plugins/component';
import faceSheetComponents from '@/views/setting/design/faceSheet/components/map';
import settings from '@/settings';
import request from '@/ylCommon/utils/request';
import lowCodeComponents from '@/plugins/component/lowCode';
import formComponents from '@/plugins/component/form';
import 'umy-ui/lib/theme-chalk/index.css'; // 引入样式
// 复制到粘贴板插件
import VueClipboard from 'vue-clipboard2';
import '@jt/yl-i18n';
// import ylBusiness from '@jt/yl-business'
import VueDragResize from 'vue-drag-resize'; // 引入拖拽组件

Vue.component('vue-drag-resize', VueDragResize);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(_UTableColumn);
Vue.use(_UTable);
Vue.use(_UxGrid);
Vue.use(_UxTableColumn);

// (function(w, d, n, a, j) {
//   w[n] = w[n] || function() {
//     (w[n].a = w[n].a || []).push(arguments)
//   }
//   j = d.createElement('script')
//   j.async = true
//   j.src = 'https://qiyukf.com/script/d4c9564e501e9f0f2f664dddb48b1b8d.js'
//   d.body.appendChild(j)
// })(window, document, 'ysf')
// import fishKefu from './ylCommon/utils/fishkefu' // 七鱼客服
// import basicQuery from '@/public/basicQuery/index.vue'
import xss from '@/plugins/xss';
Vue.use(xss);
$ylI18n.setLang(window.localStorage.getItem('lang') || 'CN');
// Vue.use(basicQuery)
Vue.use(Element, {
  size: 'small',
  i18n: function i18n(key, value) {
    return $ylI18n.vueI18n.t(key, value);
  }
});
// avue权限

directives.map(function (item) {
  Vue.use(item);
});
components.map(function (item) {
  return Vue.use(item);
});
lowCodeComponents.map(function (item) {
  return Vue.use(item);
});
formComponents.map(function (item) {
  return Vue.use(item);
});
faceSheetComponents.map(function (item) {
  return Vue.component(item.id, item.component);
});
plugins.map(function (item) {
  Vue.use(item);
});
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.prototype.$settings = settings;
Vue.prototype.$request = request;
Vue.prototype.$message = message;
Vue.prototype.$yl_lang = $ylI18n.lang;
Vue.prototype.$t = $ylI18n.lang;
Vue.config.productionTip = false;
// 七鱼客服
// Vue.prototype.fishkefu = fishKefu
// 公共bus
Vue.prototype.$bus = new Vue();
new Vue({
  router: router,
  store: store,
  i18n: $ylI18n.vueI18n,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');