import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import addressBookDialog from '@/public/yl-addressBook/Index.vue';
import { transformFun, generateEvents } from "../../../views/setting/design/lowCode/utils/event";
import { parseStyles } from "../../../views/setting/design/lowCode/utils/style";
export default {
  components: {
    addressBookDialog: addressBookDialog
  },
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    context: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      value: '',
      events: {
        onSelect: '',
        onBlur: '',
        onFocus: ''
      }
    };
  },
  computed: {
    styles: function styles() {
      return parseStyles(this.data.field.styles, this.data.field.props);
    }
  },
  created: function created() {
    this.init();
  },
  methods: {
    handleClick: function handleClick() {
      this.$refs['address-book'].init();
    },
    setValue: function setValue(value) {
      // doing something
    },
    init: function init() {
      this.events = generateEvents(this.data.field.events);
    },
    onInput: function onInput() {
      this.$emit('input', {
        key: this.data.field.paramsKey,
        value: this.value
      });
    },
    onBlur: function onBlur(value) {
      var blurEvent = this.events.onBlur;
      if (blurEvent) {
        transformFun(blurEvent.event, blurEvent.eventName, value, this.context);
      }
    },
    onFocus: function onFocus(value) {
      var onFocus = this.events.onFocus;
      if (onFocus) {
        transformFun(onFocus.event, onFocus.eventName, value, this.context);
      }
    },
    onSelectAddress: function onSelectAddress(row) {
      var _this = this;
      var selectEvent = this.events.onSelect;
      this.value = "".concat(row.name, " ").concat(row.phone, " ").concat(row.prov, " ").concat(row.city, " ").concat(row.area, " ").concat(row.address);
      this.$nextTick(function () {
        _this.$refs['address-book'].hide();
        transformFun(selectEvent.event, selectEvent.eventName, row, _this.context, function (result) {
          _this.$emit('input', {
            key: _this.data.field.paramsKey,
            value: _this.value
          });
        });
      });
    }
  }
};