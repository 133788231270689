var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    { attrs: { id: "theme-picker-wrap" } },
    [
      _c("div", { staticClass: "user-theme-btn" }, [
        _c("div", {
          staticClass: "theme-block",
          style: { backgroundColor: "" + _vm.theme },
        }),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.$t("theme")))]),
      ]),
      _vm._v(" "),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        _vm._l(_vm.themeList, function (item, index) {
          return _c(
            "el-dropdown-item",
            {
              key: index,
              nativeOn: {
                click: function ($event) {
                  return _vm.handleThemeItemClick(item)
                },
              },
            },
            [
              _c("div", { staticClass: "theme-item" }, [
                _c("div", {
                  staticClass: "theme-circle",
                  style: { backgroundColor: "" + item.color },
                }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(item.text))]),
              ]),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }