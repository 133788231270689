import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex';
import dateItem from "./date";
import inputItem from "./input";
import chooseItem from "./choose";
import selectItem from "./select";
import groupItem from "./group";
import radioGroupItem from "./radioGroup";
import checkboxItem from "./checkbox";
import timeItem from "./time";
import gridItem from "./grid";
import textareaItem from "./textarea";
import addressBookItem from "./addressBook";
import dateAreaItem from "./dateArea";
import _ from 'loadsh';
import { updateFormItemId } from '@/ylCommon/utils';
export default {
  components: {
    dateItem: dateItem,
    inputItem: inputItem,
    chooseItem: chooseItem,
    selectItem: selectItem,
    groupItem: groupItem,
    radioGroupItem: radioGroupItem,
    checkboxItem: checkboxItem,
    timeItem: timeItem,
    gridItem: gridItem,
    textareaItem: textareaItem,
    addressBookItem: addressBookItem,
    dateAreaItem: dateAreaItem
  },
  props: {
    elementId: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: true
    },
    field: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['currentFormItem', 'collection'])), {}, {
    warpClass: function warpClass() {
      var result = [];
      if (this.currentFormItem.elementId === this.field.elementId) result.push('active');
      if (this.field.field.visible === false) result.push('hide-drag');
      result.push("".concat(this.field.type));
      return result;
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapMutations({
    deleteFormItem: 'DELETE_FORM_ITEM',
    setCurrentFormItem: 'SET_CURRENT_FORM_ITEM',
    copyFormItem: 'COPY_FORM_ITEM'
  })), {}, {
    onClick: function onClick() {
      this.setCurrentFormItem(this.field);
    },
    handleDeleteField: function handleDeleteField() {
      this.setCurrentFormItem('');
      this.deleteFormItem(this.field.elementId);
    },
    handleCopyField: function handleCopyField() {
      var field = updateFormItemId(_.cloneDeep(this.field));
      var data = {
        elementId: this.field.elementId,
        field: field
      };
      this.copyFormItem(data);
    },
    init: function init() {
      this.$refs.item.init();
    },
    onAddGridField: function onAddGridField(id, elementId) {
      this.$emit('add-grid-field', id, elementId);
    }
  })
};