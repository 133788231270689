var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu",
    _vm._b(
      {
        staticClass: "app-menu-warp",
        style: _vm.getStyle,
        attrs: {
          "default-active": _vm.defaultActive,
          "collapse-transition": false,
        },
        on: { select: _vm.onSelect },
      },
      "el-menu",
      _vm.options,
      false
    ),
    [
      _vm._l(_vm.userMenu, function (menu) {
        return [
          menu.name === _vm.active
            ? [
                _vm._l(menu.children, function (item) {
                  return [
                    item.children && item.children.length > 0 && !item.hidden
                      ? _c(
                          "el-submenu",
                          { key: item.name, attrs: { index: item.name } },
                          [
                            _c(
                              "template",
                              { slot: "title" },
                              [
                                _c("svg-icon", {
                                  attrs: { "icon-class": item.meta.icon },
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: _vm.$t(
                                        item.meta.title + "_TITLE"
                                      ),
                                      placement: "right",
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "kzTitle" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(item.meta.title + "_TITLE")
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm._l(item.children, function (tag) {
                              return [
                                tag.children && tag.children.length > 0
                                  ? [
                                      tag.children &&
                                      tag.children.length > 0 &&
                                      !tag.hidden
                                        ? _c(
                                            "el-submenu",
                                            {
                                              key: tag.name,
                                              attrs: { index: tag.name },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        content: _vm.$t(
                                                          tag.meta.title +
                                                            "_TITLE"
                                                        ),
                                                        placement: "right",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "kzTitle",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                tag.meta.title +
                                                                  "_TITLE"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                tag.children,
                                                function (e) {
                                                  return [
                                                    tag.children &&
                                                    tag.children.length > 0
                                                      ? [
                                                          !e.hidden
                                                            ? _c(
                                                                "el-menu-item",
                                                                {
                                                                  key: e.name,
                                                                  staticStyle: {
                                                                    "padding-left":
                                                                      "55px",
                                                                  },
                                                                  attrs: {
                                                                    index:
                                                                      e.name,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-tooltip",
                                                                    {
                                                                      staticClass:
                                                                        "item",
                                                                      attrs: {
                                                                        effect:
                                                                          "dark",
                                                                        content:
                                                                          _vm.$t(
                                                                            e
                                                                              .meta
                                                                              .title +
                                                                              "_TITLE"
                                                                          ),
                                                                        placement:
                                                                          "right",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "kzTitle",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                e
                                                                                  .meta
                                                                                  .title +
                                                                                  "_TITLE"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      : [
                                                          !e.hidden
                                                            ? _c(
                                                                "el-menu-item",
                                                                {
                                                                  key: e.name,
                                                                  staticStyle: {
                                                                    "padding-left":
                                                                      "55px",
                                                                  },
                                                                  attrs: {
                                                                    index:
                                                                      e.name,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-tooltip",
                                                                    {
                                                                      staticClass:
                                                                        "item",
                                                                      attrs: {
                                                                        effect:
                                                                          "dark",
                                                                        content:
                                                                          _vm.$t(
                                                                            e
                                                                              .meta
                                                                              .title +
                                                                              "_TITLE"
                                                                          ),
                                                                        placement:
                                                                          "right",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "kzTitle",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                e
                                                                                  .meta
                                                                                  .title +
                                                                                  "_TITLE"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ]
                                  : [
                                      !tag.hidden
                                        ? _c(
                                            "el-menu-item",
                                            {
                                              key: tag.name,
                                              attrs: { index: tag.name },
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content: _vm.$t(
                                                      tag.meta.title + "_TITLE"
                                                    ),
                                                    placement: "right",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "kzTitle" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            tag.meta.title +
                                                              "_TITLE"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                              ]
                            }),
                          ],
                          2
                        )
                      : [
                          !item.hidden
                            ? _c(
                                "el-menu-item",
                                { key: item.name, attrs: { index: item.name } },
                                [
                                  item.meta.icon
                                    ? _c("i", {
                                        staticClass: "iconfont",
                                        class: item.meta.icon,
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: _vm.$t(
                                          item.meta.title + "_TITLE"
                                        ),
                                        placement: "right",
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "kzTitle" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(item.meta.title + "_TITLE")
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                  ]
                }),
              ]
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }