//
//
//
//
//
//
//
//

import dateItem from "./date";
import inputItem from "./input";
import chooseItem from "./choose";
import textareaItem from "./textarea";
import selectItem from "./select";
import groupItem from "./group";
import radioGroupItem from "./radioGroup";
import checkboxItem from "./checkbox";
import timeItem from "./time";
import addressBookItem from "./addressBook";
import dateAreaItem from "./dateArea";
export default {
  components: {
    dateAreaItem: dateAreaItem,
    textareaItem: textareaItem,
    addressBookItem: addressBookItem,
    timeItem: timeItem,
    groupItem: groupItem,
    radioGroupItem: radioGroupItem,
    selectItem: selectItem,
    checkboxItem: checkboxItem,
    inputItem: inputItem,
    chooseItem: chooseItem,
    dateItem: dateItem
  },
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      gridList: []
    };
  },
  computed: {},
  methods: {
    onInput: function onInput(update) {
      this.$emit('input', update);
    },
    onRequestAfter: function onRequestAfter(data) {
      this.$emit('request-after', data);
    }
  }
};