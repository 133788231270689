var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "draggable",
    _vm._b(
      {
        staticClass: "form-layout-warp",
        class: _vm.formClass,
        style: _vm.styles,
        attrs: {
          tag: "el-form",
          "data-parent": _vm.data.elementId,
          "component-data": _vm.getColComponentData(),
        },
        on: { add: _vm.onFormLayoutAdd },
        model: {
          value: _vm.data.field.children,
          callback: function ($$v) {
            _vm.$set(_vm.data.field, "children", $$v)
          },
          expression: "data.field.children",
        },
      },
      "draggable",
      _vm.dragOptions,
      false
    ),
    _vm._l(_vm.data.field.children, function (item) {
      return _c("drag-component", {
        key: item.elementId,
        attrs: { field: item, "element-id": item.elementId },
        nativeOn: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.handleFieldClick(item)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }