import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.to-string";
import _ from 'loadsh';
export function generateId() {
  return Number(Math.random().toString().substr(3, length) + Date.now()).toString(36);
}
var getFormItemEventsOptions = function getFormItemEventsOptions(type) {
  var result = {
    input: [{
      label: 'onChange',
      value: 'onChange'
    }, {
      label: 'onFocus',
      value: 'onFocus'
    }, {
      label: 'onBlur',
      value: 'onBlur'
    }, {
      label: 'onClear',
      value: 'onClear'
    }],
    button: [{
      label: 'onClick',
      value: 'onClick'
    }],
    select: [{
      label: 'onChange',
      value: 'onChange'
    }, {
      label: 'onBlur',
      value: 'onBlur'
    }, {
      label: 'onClear',
      value: 'onClear'
    }, {
      label: 'onFocus',
      value: 'onFocus'
    }, {
      label: 'onVisibleChange',
      value: 'onVisibleChange'
    }],
    radioGroup: [{
      label: 'onChange',
      value: 'onChange'
    }],
    checkbox: [{
      label: 'onChange',
      value: 'onChange'
    }],
    date: [{
      label: 'onChange',
      value: 'onChange'
    }, {
      label: 'onBlur',
      value: 'onBlur'
    }, {
      label: 'onFocus',
      value: 'onFocus'
    }],
    group: [{
      label: 'onChange',
      value: 'onChange'
    }],
    choose: [{
      label: 'onChange',
      value: 'onChange'
    }],
    textarea: [{
      label: 'onChange',
      value: 'onChange'
    }, {
      label: 'onBlur',
      value: 'onBlur'
    }, {
      label: 'onFocus',
      value: 'onFocus'
    }],
    addressBook: [{
      label: 'onSelect',
      value: 'onSelect'
    }, {
      label: 'onBlur',
      value: 'onBlur'
    }, {
      label: 'onFocus',
      value: 'onFocus'
    }],
    time: [{
      label: 'onChange',
      value: 'onChange'
    }, {
      label: 'onBlur',
      value: 'onBlur'
    }, {
      label: 'onFocus',
      value: 'onFocus'
    }]
  };
  return result[type] || [];
};
export function formatFormItem(field) {
  var newField = _.cloneDeep(field);
  var paramsKey = newField.field.paramsKey;
  var type = newField.field.type;
  newField.elementId = generateId();
  newField.field.props = {};
  newField.field.styles = {};
  newField.field.eventOptions = getFormItemEventsOptions(type);
  newField.field.events = [];
  newField.field.value = '';
  newField.field.arrValue = [];
  newField.field.parentId = '';
  newField.field.paramsKey = paramsKey || "".concat(newField.elementId, "_").concat(type);
  newField.field.rule = {
    attrName: '',
    desc: '',
    maxLimit: '',
    maxLimitMsg: '',
    minLimit: '',
    minLimitMsg: '',
    regular: '',
    regularMsg: '',
    required: '',
    requiredMsg: '',
    status: '',
    type: ''
  };
  newField.field.ruleName = '';
  newField.field.children = newField.type === 'grid' ? [{
    icon: 'iconfont icon42danlanbuju',
    label: '单栏',
    parentId: newField.elementId,
    span: 12,
    elementId: "".concat(generateId(), "1"),
    children: []
  }, {
    icon: 'iconfont icon42danlanbuju',
    label: '单栏',
    parentId: newField.elementId,
    span: 12,
    elementId: "".concat(generateId(), "2"),
    children: []
  }] : [];
  var propsKeyMap = [{
    key: 'className',
    value: ''
  }, {
    key: 'borderType',
    value: ''
  }, {
    key: 'boxShadowType',
    value: ''
  }, {
    key: 'editable',
    value: false
  }, {
    key: 'readonly',
    value: false
  }, {
    key: 'clearable',
    value: false
  }, {
    key: 'placeholder',
    value: ''
  }, {
    key: 'format',
    value: 'yyyy-MM-dd'
  }, {
    key: 'type',
    value: ''
  }, {
    key: 'showPassword',
    value: false
  }, {
    key: 'plain',
    value: false
  }, {
    key: 'moreVisible',
    value: false
  }, {
    key: 'resize',
    value: ''
  }, {
    key: 'showWordLimit',
    value: false
  }, {
    key: 'rows',
    value: 2
  }, {
    key: 'maxlength',
    value: 99999
  }, {
    key: 'autosize',
    value: false
  }, {
    key: 'gutter',
    value: 0
  }, {
    key: 'justify',
    value: 'start'
  }, {
    key: 'align',
    value: 'top'
  }, {
    key: 'startPlaceholder',
    value: ''
  }, {
    key: 'endPlaceholder',
    value: ''
  }, {
    key: 'rangeSeparator',
    value: '—'
  }, {
    key: 'unlinkPanels',
    value: false
  }, {
    key: 'disabled',
    value: false
  }, {
    key: 'size',
    value: 'small'
  }, {
    key: 'circle',
    value: false
  }, {
    key: 'round',
    value: false
  }, {
    key: 'tableKey',
    value: ''
  }, {
    key: 'quickLocal',
    value: ''
  }, {
    key: 'backgroundType',
    value: ''
  }, {
    key: 'borderRadiusType',
    value: ''
  }, {
    key: 'boxShadowType',
    value: ''
  }, {
    key: 'labelPosition',
    value: ''
  }, {
    key: 'labelWidth',
    value: ''
  }, {
    key: 'statusIcon',
    value: false
  }, {
    key: 'css',
    value: ''
  }, {
    key: 'autoplay',
    value: true
  }, {
    key: 'loop',
    value: true
  }, {
    key: 'interval',
    value: 3000
  }, {
    key: 'direction',
    value: 'horizontal'
  }, {
    key: 'indicatorPosition',
    value: 'none'
  }, {
    key: 'height',
    value: ''
  }, {
    key: 'slidesPerView',
    value: ''
  }, {
    key: 'spaceBetween',
    value: 20
  }];
  var stylesKeyMap = [];
  propsKeyMap.map(function (item) {
    newField.field.props[item.key] = item.value;
  });
  stylesKeyMap.map(function (item) {
    newField.field.styles[item.key] = item.value;
  });
  return newField;
}
export function removeAllNonItem() {
  var $list = document.querySelectorAll('.middle-form-content .none-item');
  $list.forEach(function (item) {
    item.remove();
  });
}