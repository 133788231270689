export default {
  bind: function bind(el, binding, vnode) {
    var _this = this;
    var inputs = el.querySelectorAll('input');
    // console.log(inputs);
    // 绑定回写事件
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].setAttribute('keyFocusIndex', i);
      inputs[i].addEventListener('keyup', function (ev) {
        if (ev.keyCode === 13) {
          var targetTo = ev.srcElement.getAttribute('keyFocusTo');
          if (targetTo) {
            _this.$refs[targetTo].$el.focus();
          } else {
            var attrIndex = ev.srcElement.getAttribute('keyFocusIndex');
            var ctlI = parseInt(attrIndex);
            if (ctlI < inputs.length - 1) {
              inputs[ctlI + 1].focus();
            }
          }
        }
      });
    }
  }
};