var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      staticClass: "barcode-menu-warp",
      attrs: { model: _vm.form, "label-position": "top" },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "内容", name: "content" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "条形码格式" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-100",
                      attrs: { size: "small", filterable: "" },
                      on: { change: _vm.debounceUpdate },
                      model: {
                        value: _vm.currentComponent.props.format,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentComponent.props, "format", $$v)
                        },
                        expression: "currentComponent.props.format",
                      },
                    },
                    _vm._l(_vm.formatOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { value: item.value, label: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否显示值" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-100",
                      attrs: { size: "small", filterable: "" },
                      on: { change: _vm.debounceUpdate },
                      model: {
                        value: _vm.currentComponent.props.displayValue,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.currentComponent.props,
                            "displayValue",
                            $$v
                          )
                        },
                        expression: "currentComponent.props.displayValue",
                      },
                    },
                    _vm._l(_vm.selectOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { value: item.value, label: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "样式", name: "style" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "线宽" } },
                [
                  _c("el-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      min: 1,
                      max: 10,
                      label: "描述文字",
                      size: "small",
                    },
                    on: { change: _vm.debounceUpdate },
                    model: {
                      value: _vm.currentComponent.props.lineWidth,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentComponent.props, "lineWidth", $$v)
                      },
                      expression: "currentComponent.props.lineWidth",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "字体大小" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-100",
                      attrs: { size: "small" },
                      on: { change: _vm.debounceUpdate },
                      model: {
                        value: _vm.currentComponent.props.fontSize,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentComponent.props, "fontSize", $$v)
                        },
                        expression: "currentComponent.props.fontSize",
                      },
                    },
                    _vm._l(_vm.fontSizeOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "条形码高度" } },
                [
                  _c("el-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      min: 1,
                      max: 100,
                      label: "描述文字",
                      size: "small",
                    },
                    on: { change: _vm.debounceUpdate },
                    model: {
                      value: _vm.currentComponent.props.bodyHeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.currentComponent.props, "bodyHeight", $$v)
                      },
                      expression: "currentComponent.props.bodyHeight",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }