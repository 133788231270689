import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.constructor";
import _typeof from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
import "core-js/modules/es6.regexp.to-string";
/**
 * Created by jiachenpan on 16/11/18.
 */

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
export function validUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}
export function validURL(url) {
  var reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}
export function validLowerCase(str) {
  var reg = /^[a-z]+$/;
  return reg.test(str);
}
export function validUpperCase(str) {
  var reg = /^[A-Z]+$/;
  return reg.test(str);
}
export function validAlphabets(str) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(str);
}
export function validEmail(email) {
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }
  return false;
}
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
}
export function isJSON(str) {
  if (typeof str === 'string') {
    try {
      var obj = JSON.parse(str);
      if (_typeof(obj) === 'object' && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log('error：' + str + '!!!' + e);
      return false;
    }
  }
}
export var getRules = function getRules(ruleData) {
  var isObject = _typeof(ruleData) === 'object';
  var Verify = isObject ? ruleData : JSON.parse(localStorage.getItem('Verify'));
  var rules = {};
  var _loop = function _loop(i) {
    var rule = [];
    var e = Verify[i];
    if (e.attrName === 'sSsq' || e.attrName === 'rSsq') {
      var ruleItem = {
        type: 'array'
      };
      rule.push(ruleItem);
    }
    if (e.required === '1') {
      var _ruleItem = {};
      _ruleItem.required = true;
      _ruleItem.message = $ylI18n.lang(e.requiredMsg);
      _ruleItem.trigger = 'change';
      rule.push(_ruleItem);
    }
    if (e.regular) {
      var _ruleItem2 = {};
      var validator = function validator(rule, value, callback) {
        if (value && !new RegExp(e.regular).test(value)) {
          callback(new Error($ylI18n.lang(e.regularMsg)));
        } else callback();
      };
      _ruleItem2.validator = validator;
      _ruleItem2.trigger = 'change';
      rule.push(_ruleItem2);
    }
    if (e.minLimit) {
      var _ruleItem3 = {};
      if (e.type === 'String') {
        _ruleItem3.min = Number(e.minLimit);
        _ruleItem3.message = e.minLimitMsg;
      } else {
        var _validator = function _validator(rule, value, callback) {
          if (Number(value) < Number(e.minLimit)) {
            callback(new Error($ylI18n.lang(e.minLimitMsg)));
          } else callback();
        };
        _ruleItem3.validator = _validator;
      }
      _ruleItem3.trigger = 'change';
      rule.push(_ruleItem3);
    }
    if (e.maxLimit) {
      var _ruleItem4 = {};
      if (e.type === 'String') {
        _ruleItem4.max = Number(e.maxLimit);
        _ruleItem4.message = e.maxLimitMsg;
      } else {
        var _validator2 = function _validator2(rule, value, callback) {
          if (Number(value) > Number(e.maxLimit)) {
            callback(new Error($ylI18n.lang(e.maxLimitMsg)));
          } else callback();
        };
        _ruleItem4.validator = _validator2;
      }
      _ruleItem4.trigger = 'change';
      rule.push(_ruleItem4);
    }
    rules[isObject ? e.paramsKey : e.attrName] = rule;
  };
  for (var i = 0; i < Verify.length; i++) {
    _loop(i);
  }
  return rules;
};
export function formatStr() {
  var ary = [];
  for (var i = 1; i < arguments.length; i++) {
    ary.push(arguments[i]);
  }
  return arguments[0].replace(/\{(\d+)\}/g, function (m, i) {
    return ary[i];
  });
}