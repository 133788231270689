import "core-js/modules/es6.array.find";
import "core-js/modules/es6.function.name";
import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Logo from "../../../assets/images/logo.png";
import ylUser from "../user.vue";
import { mapState } from 'vuex';
export default {
  name: 'HeaderNav',
  components: {
    ylUser: ylUser
  },
  data: function data() {
    return {
      logoSrc: Logo,
      hoverTab: '',
      mouseIndx: -2,
      oneIndex: -1,
      activeIndex: -1
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    userMenu: function userMenu(state) {
      return state.user.userMenu;
    },
    activeModule: function activeModule(state) {
      return state.user.activeModule;
    },
    collapse: function collapse(state) {
      return state.settings.collapse;
    }
  })), {}, {
    lang: function lang() {
      return localStorage.getItem('lang');
    },
    active: function active() {
      return this.activeModule ? this.activeModule.name : '';
    }
  }),
  created: function created() {},
  methods: {
    goRouter: function goRouter(path, index, ind) {
      this.oneIndex = ind;
      this.activeIndex = index;
      this.hoverTab = '';
      this.$router.push({
        name: "".concat(path)
      });
    },
    mouseover: function mouseover(item, i) {
      this.mouseIndx = i;
      this.hoverTab = item.name;
    },
    mouseout: function mouseout() {
      this.hoverTab = '';
      if (this.mouseIndx !== this.oneIndex) {
        this.oneIndex = -1;
        this.activeIndex = -1;
      }
    },
    handleClickMenu: function handleClickMenu(menu) {
      this.hoverTab = '';
      var isHome = menu.path === '';
      if (isHome) this.$router.push({
        path: '/'
      });
      this.$store.dispatch('user/setActiveModule', this.userMenu.find(function (item) {
        return item.name === menu.name;
      }));
      this.$store.dispatch('settings/changeSetting', {
        key: 'collapse',
        value: isHome || !this.collapse
      });
    }
  }
};