export default {
  bind: function bind(el, binding, vnode) {
    var toggleFlag = false;
    var $title = el.querySelector('.el-dialog__title');
    var $toggle = document.createElement('div');
    $toggle.classList.add('dialog-full-btn');
    $toggle.innerHTML = "<i class=\"iconfont icon-fangda\"></i>";
    $title && $title.insertAdjacentElement('afterend', $toggle);
    var $icon = $toggle.querySelector('.iconfont');
    var outTransition = function outTransition() {
      el.firstChild.classList.add('transition-3');
      setTimeout(function () {
        el.firstChild.classList.remove('transition-3');
      }, 300);
    };
    $toggle.addEventListener('click', function () {
      var className = toggleFlag ? 'iconfont icon-fangda' : 'iconfont icon-suoxiaotuichuquanpingshouhui';
      if (!toggleFlag) {
        el.firstChild.classList.add('el-full-dialog');
        $icon.setAttribute('class', className);
      } else {
        outTransition();
        el.firstChild.classList.remove('el-full-dialog');
        $icon.setAttribute('class', className);
      }
      toggleFlag = !toggleFlag;
    });
  }
};