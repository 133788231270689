import "core-js/modules/es6.number.constructor";
import skeletonRow from "./row.vue";
export default {
  components: {
    skeletonRow: skeletonRow
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    rows: {
      type: [Number, String],
      default: 3
    },
    active: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '32px'
    }
  },
  data: function data() {
    return {
      rowCount: []
    };
  },
  computed: {
    countRows: function countRows() {
      var res = 3;
      var windowHeight = window.innerHeight;
      if (typeof this.rows === 'string') {
        var startIndex = this.rows.lastIndexOf(' ');
        var lastIndex = this.rows.lastIndexOf('p');
        var differHeight = this.rows.substring(startIndex + 1, lastIndex);
        var height = windowHeight - parseInt(differHeight);
        res = parseFloat((height / 32).toFixed(0));
      } else res = this.rows;
      return res;
    }
  },
  mounted: function mounted() {},
  render: function render() {
    var _this = this;
    var h = arguments[0];
    var that = this;
    var $row = function $row(rowLength) {
      var res = [];
      for (var key = 0; key < rowLength; key++) {
        res.push(h("skeleton-row", {
          "attrs": {
            "active": _this.active,
            "height": _this.height
          }
        }));
      }
      return res;
    };
    var $children = this.loading ? $row(that.countRows) : this.$slots.default;
    return h("div", {
      "attrs": {
        "className": 'skeleton-wrap'
      }
    }, [$children]);
  }
};