var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.parentType !== "form"
    ? _c(
        "div",
        { staticClass: "native-field-warp", style: _vm.styles },
        [
          _c(
            "div",
            {
              staticClass: "field-label",
              class: _vm.data.field.props.className,
            },
            [_vm._v(_vm._s(_vm.data.field.name))]
          ),
          _vm._v(" "),
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.data.field.arrValue,
                callback: function ($$v) {
                  _vm.$set(_vm.data.field, "arrValue", $$v)
                },
                expression: "data.field.arrValue",
              },
            },
            _vm._l(_vm.data.field.options, function (item) {
              return _c("el-checkbox", {
                key: item.label,
                attrs: { label: item.label },
              })
            }),
            1
          ),
        ],
        1
      )
    : _c(
        "el-form-item",
        {
          class: _vm.data.field.props.className,
          style: _vm.styles,
          attrs: { label: _vm.data.field.name },
        },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.data.field.arrValue,
                callback: function ($$v) {
                  _vm.$set(_vm.data.field, "arrValue", $$v)
                },
                expression: "data.field.arrValue",
              },
            },
            _vm._l(_vm.data.field.options, function (item) {
              return _c("el-checkbox", {
                key: item.label,
                attrs: { label: item.label },
              })
            }),
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }