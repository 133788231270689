//
//
//
//
//
//

export default {
  props: {
    elementId: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    fontFamily: {
      type: String,
      default: ''
    },
    fontSize: {
      type: String,
      default: ''
    },
    lineHeight: {
      type: String,
      default: ''
    },
    isBold: {
      type: Boolean,
      default: false
    },
    hasBorder: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    getTextStyle: function getTextStyle() {
      var fontFamily = this.fontFamily,
        fontSize = this.fontSize,
        lineHeight = this.lineHeight,
        isBold = this.isBold,
        hasBorder = this.hasBorder;
      var style = {};
      style = {
        fontFamily: fontFamily,
        fontSize: fontSize,
        lineHeight: lineHeight,
        fontWeight: isBold ? 'bold' : '400',
        border: hasBorder ? '1px solid #000' : '1px solid transparent'
      };
      return style;
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      this.$emit('complete');
    }
  }
};