import "core-js/modules/es6.array.find";
import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
export default {
  props: {
    component: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      tab: '标题',
      form: {
        content: ''
      },
      alignOptions: [{
        label: '左对齐',
        value: 'left'
      }, {
        label: '右对齐',
        value: 'right'
      }, {
        label: '居中',
        value: 'center'
      }],
      fontFamilyOptions: [{
        label: 'Al Bayan',
        value: 'Al Bayan'
      }, {
        label: 'monospace',
        value: 'monospace'
      }, {
        label: 'sans-serif',
        value: 'sans-serif'
      }],
      fontSizeOptions: [{
        label: '12px',
        value: '12px'
      }, {
        label: '14px',
        value: '14px'
      }, {
        label: '16px',
        value: '16px'
      }, {
        label: '18px',
        value: '18px'
      }, {
        label: '24px',
        value: '24px'
      }, {
        label: '30px',
        value: '30px'
      }, {
        label: '36px',
        value: '36px'
      }, {
        label: '48px',
        value: '48px'
      }, {
        label: '60px',
        value: '60px'
      }, {
        label: '72px',
        value: '72px'
      }],
      lineHeightOptions: [{
        label: '1倍',
        value: '1'
      }, {
        label: '1.5倍',
        value: '1.5'
      }, {
        label: '2倍',
        value: '2'
      }, {
        label: '2.5倍',
        value: '2.5'
      }, {
        label: '3倍',
        value: '3'
      }]
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['activeComponent', 'storeList'])), {}, {
    currentComponent: function currentComponent() {
      var _this$activeComponent = this.activeComponent.id,
        id = _this$activeComponent === void 0 ? '' : _this$activeComponent;
      return this.storeList.find(function (item) {
        return item.id === id;
      });
    }
  }),
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      this.tab = 'content';
    }
  }
};