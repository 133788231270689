import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import _toConsumableArray from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { on, off } from '@/ylCommon/utils/dom.js';
import { debounce } from 'throttle-debounce';
import { addClass, removeClass } from '@/ylCommon/utils/index';
export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: [Array, String, Number],
      default: function _default() {}
    },
    type: {
      type: Number,
      default: 0
    },
    country: {
      type: String,
      default: 'CHN'
    },
    isCrossBorder: {
      type: Boolean,
      default: false
    },
    isreadonly: {
      type: Boolean,
      default: true
    },
    isdisabled: {
      type: Boolean,
      default: false
    },
    // 是否监听元素变化自动定位弹出框
    autoPosition: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      isFirst: true,
      visible: false,
      searchKey: '',
      current: 'prov',
      loading: false,
      list: {
        prov: [],
        city: [],
        area: []
      },
      original: {
        prov: [],
        city: [],
        area: []
      },
      selectionOptions: {
        prov: '',
        city: '',
        area: ''
      },
      address: '',
      debouncedGetData: '',
      width: '300px',
      isFocus: false,
      triggerLock: {},
      popoverOptions: {},
      offsetTr: 30,
      currentLine: 0
    };
  },
  computed: {
    searchPlaceholder: function searchPlaceholder() {
      var key = '';
      if (this.current === 'prov') key = '省份';else if (this.current === 'city') key = '城市';else if (this.current === 'area') key = '区域';
      return this.$t("\u8BF7\u8F93\u5165".concat(key));
    }
  },
  watch: {
    value: {
      handler: function handler(n, o) {
        var _this = this;
        if (typeof n === 'string' || typeof n === 'undefined') return;
        if (this.isreadonly) {
          var address = '';
          n.forEach(function (e) {
            address += "".concat(e.name, "/");
          });
          this.address = address.slice(0, address.length - 1);
        } else {
          if (n.length === 3) {
            setTimeout(function () {
              var data = n;
              var address = '';
              data.forEach(function (e) {
                address += "".concat(e.name, "/");
              });
              _this.address = address.slice(0, address.length - 1);
            }, 200);
          }
        }
        !n.length && (this.address = '');
      },
      immediate: true
    },
    address: function address(n) {
      if (!this.isreadonly) {
        if (!n) {
          this.address = '';
          this.onEmit('');
        } else {
          this.onEmit(this.address);
        }
      }
    }
  },
  mounted: function mounted() {
    this.init();
    this.autoPosition && this.handleAddInputObserver();
  },
  destroyed: function destroyed() {
    var that = this;
    off(window, 'resize', that.debouncedResize);
  },
  methods: {
    onHide: function onHide() {
      if (this.value.length < 3 || typeof this.value[1] === 'undefined') {
        this.reset();
      }
    },
    onLeave: function onLeave() {
      var $trigger = document.querySelectorAll('.trigger-area');
      for (var i = 0; i < $trigger.length; i++) {
        sessionStorage.setItem("lock".concat(i), 'false');
      }
    },
    reset: function reset() {
      this.onEmit([]);
      this.address = '';
      this.current = 'prov';
      this.list.city = [];
      this.list.area = [];
    },
    onShow: function onShow() {
      var _this2 = this;
      if (this.isdisabled) {
        return;
      }
      this.searchKey = '';
      this.resizePopover();
      this.visible = true;
      this.$nextTick(function () {
        this.$refs.searchInput.$el.getElementsByClassName('el-input__inner')[0].focus();
      });
      this.$nextTick(function () {
        _this2.current = 'prov';
        _this2.list.city = [];
        _this2.list.area = [];
        _this2.getSsq('prov');
      });
    },
    mouseMove: function mouseMove(event) {
      var wrap = document.querySelectorAll('.list-area');
      var allList = document.querySelectorAll('.address-item');
      var index;
      if (this.current === 'prov') index = 0;else if (this.current === 'city') index = 1;else if (this.current === 'area') index = 2;
      var scrollWrap = wrap[index].querySelector('.el-scrollbar__wrap');
      // const scrollBody = wrap.querySelector('.el-scrollbar__view')
      var listItem = wrap[index].querySelectorAll('.address-item');
      if (event.keyCode === 38) {
        this.offsetTr = 0;
        this.currentLine--;
        this.changeItem(listItem, scrollWrap);
      } else if (event.keyCode === 40) {
        this.offsetTr = 24;
        this.currentLine++;
        this.changeItem(listItem, scrollWrap);
      } else if (event.keyCode === 13 && this.currentLine > -1) {
        if (this.current === 'prov') {
          this.handleSelectProv(this.list.prov[this.currentLine - 1]);
        } else if (this.current === 'city') {
          this.handleSelectCity(this.list.city[this.currentLine - 1]);
        } else if (this.current === 'area') {
          this.handleSelectArea(this.list.area[this.currentLine - 1]);
        }
        this.currentLine = 0;
        this.changeBackground(allList);
      }
    },
    changeItem: function changeItem(listItem, scrollWrap) {
      this.changeBackground(listItem);
      if (this.currentLine < 1) this.currentLine = listItem.length;
      if (this.currentLine > listItem.length) this.currentLine = 1;
      // removeClass(listItem[this.currentLine - 1], 'noselectColor')
      addClass(listItem[this.currentLine - 1], 'selectColor');
      scrollWrap.scrollTop = listItem[this.currentLine - 1].offsetTop - this.offsetTr;
    },
    changeBackground: function changeBackground(listItem) {
      for (var i = 0; i < listItem.length; i++) {
        removeClass(listItem[i], 'selectColor');
        // addClass(listItem[i], 'noselectColor')
      }
    },
    onFocus: function onFocus() {
      // 如果禁用则不弹出
      if (this.isdisabled) {
        return;
      }
      this.isFocus = true;
    },
    onBlur: function onBlur() {
      var _this3 = this;
      setTimeout(function () {
        _this3.isFocus = false;
      }, 100);
    },
    init: function init() {
      var that = this;
      this.debouncedGetData = debounce(100, this.handleSearch);
      this.debouncedResize = debounce(200, this.resizePopover);
      that.debouncedResize();
      on(window, 'resize', that.debouncedResize);
    },
    onSearch: function onSearch() {
      this.debouncedGetData();
    },
    handleClick: function handleClick(tab, event) {
      // console.log(this.searchKey)
    },
    resizePopover: function resizePopover() {
      var result = '';
      var $input = this.$refs['address-ipt'];
      result = $input ? $input.$el.offsetWidth : 300;
      this.width = result;
    },
    handleSelectProv: function handleSelectProv(item) {
      var _this4 = this;
      this.selectionOptions.prov = item;
      this.onEmit([item]);
      this.address = "".concat(this.selectionOptions.prov.name);
      this.current = 'city';
      this.searchKey = '';
      this.$nextTick(function () {
        _this4.getSsq('city');
      });
    },
    handleSelectCity: function handleSelectCity(item) {
      var _this5 = this;
      this.selectionOptions.city = item;
      this.onEmit([this.selectionOptions.prov, item]);
      this.address = "".concat(this.selectionOptions.prov.name, "/").concat(this.selectionOptions.city.name);
      this.current = 'area';
      this.searchKey = '';
      this.$nextTick(function () {
        _this5.getSsq('area');
      });
    },
    handleSelectArea: function handleSelectArea(item) {
      this.selectionOptions.area = item;
      this.onEmit([].concat(_toConsumableArray(this.value), [item]));
      this.address = "".concat(this.selectionOptions.prov.name, "/").concat(this.selectionOptions.city.name, "/").concat(this.selectionOptions.area.name);
      this.handleCancelPopover();
      this.searchKey = '';
    },
    handleCancelPopover: function handleCancelPopover() {
      this.visible = false;
    },
    handleSearch: function handleSearch() {
      var _this6 = this;
      var key = this.searchKey;
      var type = this.current;
      this.querySearch(key, function (res) {
        _this6.list[type] = res;
      });
    },
    querySearch: function querySearch() {
      var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var cb = arguments.length > 1 ? arguments[1] : undefined;
      var original = this.original[this.current];
      var results = key ? original.filter(this.createFilter(key)) : original;
      cb && cb(results);
    },
    createFilter: function createFilter(queryString) {
      return function (_ref) {
        var name = _ref.name;
        return name.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    handleAddInputObserver: function handleAddInputObserver() {
      // const that = this
      var $element = document.querySelectorAll('.el-dialog');
      var $popper = document.querySelectorAll('.address-select-popover');
      var $trigger = document.querySelectorAll('.trigger-area');
      if (!$trigger || !$element) return;
      for (var i = 0; i < $trigger.length; i++) {
        sessionStorage.setItem("lock".concat(i), 'false');
      }
      var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
      var observer = new MutationObserver(function (mutations) {
        var fixPosition = function fixPosition() {
          for (var _i = 0; _i < $popper.length; _i++) {
            if (!$popper[_i].style.display && sessionStorage.getItem("lock".concat(_i)) === 'false') {
              $trigger[_i].click();
              sessionStorage.setItem("lock".concat(_i), 'true');
            }
          }
        };
        fixPosition();
      });
      var parentObserver = new MutationObserver(function (mutations) {
        for (var _i2 = 0; _i2 < $element.length; _i2++) {
          if ($element[_i2] && $element[_i2].parentElement.style.display === 'none') {
            // 存在多个dialog的时候 出现自动关闭的bug
            // that.handleCancelPopover()
          }
        }
      });
      for (var _i3 = 0; _i3 < $element.length; _i3++) {
        if ($element[_i3].parentElement.style.display !== 'none') {
          $element && observer.observe($element[_i3], {
            attributes: true
          });
          $element && parentObserver.observe($element[_i3].parentElement, {
            attributes: true
          });
        }
      }
    },
    onEmit: function onEmit(data) {
      var isCrossBorder = this.isCrossBorder;
      if (Array.isArray(data)) {
        data = data.map(function (item) {
          item = Object.assign({}, item);
          if (isCrossBorder && item.hasOwnProperty('id')) delete item.id;
          return item;
        });
      }
      this.$emit('input', data);
    },
    getSsq: function getSsq(queryType) {
      var _this7 = this;
      var obj = {};
      var isCrossBorder = this.isCrossBorder;
      var key = isCrossBorder ? 'id' : 'name';
      if (isCrossBorder) {
        obj.chnDataType = 1;
      }
      if (queryType === 'city') {
        obj.prov = this.selectionOptions.prov[key];
        obj.queryType = 'city';
      } else if (queryType === 'area') {
        obj.prov = this.selectionOptions.prov[key];
        obj.city = this.selectionOptions.city[key];
      }
      var params = _objectSpread(_objectSpread({
        country: this.country,
        queryType: queryType
      }, obj), {}, {
        chnDataType: queryType === 'area' ? 1 : ''
      });
      if (queryType !== 'area') delete params.chnDataType;
      if (this.type === 1) {
        this.$Api.order.getSsqYb(params).then(function (res) {
          var dataList = (res.data || []).map(function (item) {
            if (isCrossBorder) {
              item.id = item.name;
              item.name = item.code;
            }
            return item;
          });
          _this7.list[_this7.current] = dataList;
          _this7.original[_this7.current] = dataList;
        });
      } else {
        this.$Api.order.getSsq(_objectSpread({
          country: this.country,
          queryType: queryType
        }, obj)).then(function (res) {
          var dataList = (res.data || []).map(function (item) {
            if (isCrossBorder) {
              item.id = item.name;
              item.name = item.code;
            }
            return item;
          });
          _this7.list[_this7.current] = dataList;
          _this7.original[_this7.current] = dataList;
        });
      }
    }
  }
};