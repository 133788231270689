import "core-js/modules/web.dom.iterable";
// 界面多个message提示框的时候，只提示一个，拦截其他，避免一个界面多个弹框体验感差
import { Message } from 'element-ui';
var messageInstance = null;
var resetMessage = function resetMessage(options) {
  if (messageInstance) {
    messageInstance.close();
  }
  messageInstance = Message(options);
};
['error', 'success', 'info', 'warning'].forEach(function (type) {
  resetMessage[type] = function (options) {
    if (typeof options === 'string') {
      options = {
        message: options
      };
    }
    options.type = type;
    return resetMessage(options);
  };
});
var message = resetMessage;
export default message;