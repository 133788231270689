var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-button",
    {
      style: _vm.styles,
      attrs: {
        size: _vm.data.field.props.size,
        type: _vm.data.field.props.type,
        circle: _vm.data.field.props.circle,
        plain: _vm.data.field.props.plain,
        round: _vm.data.field.props.round,
        disabled: _vm.data.field.props.disabled,
      },
    },
    [_vm._v(_vm._s(_vm.data.field.name))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }