export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '32px'
    }
  },
  data: function data() {
    return {};
  },
  render: function render() {
    var h = arguments[0];
    var className = this.active ? 'skeleton-row active' : 'skeleton-row';
    return h("div", {
      "class": className,
      "style": {
        height: this.height
      }
    });
  }
};