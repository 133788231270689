import "core-js/modules/es6.number.constructor";
//
//
//
//

export default {
  props: {
    elementId: {
      type: String,
      default: ''
    },
    height: {
      type: [String, Number],
      default: 1
    },
    width: {
      type: [String, Number],
      default: 100
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    getStyle: function getStyle() {
      var height = this.height,
        width = this.width;
      return {
        backgroundColor: '#000',
        height: "".concat(height, "px"),
        minWidth: "".concat(width, "px")
      };
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      this.$emit('complete');
    }
  }
};