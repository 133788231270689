import "core-js/modules/es6.function.name";
import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
export default {
  data: function data() {
    return {
      options: {
        backgroundColor: '#2568d6',
        textColor: '#fff',
        activeTextColor: '#2568d6',
        uniqueOpened: true
      }
    };
  },
  computed: _objectSpread(_objectSpread({
    getStyle: function getStyle() {
      return {
        display: this.collapse ? 'none' : 'block'
      };
    }
  }, mapState({
    userMenu: function userMenu(state) {
      return state.user.userMenu;
    },
    activeModule: function activeModule(state) {
      return state.user.activeModule;
    },
    collapse: function collapse(state) {
      return state.settings.collapse;
    }
  })), {}, {
    defaultActive: function defaultActive() {
      return this.$route.name;
    },
    active: function active() {
      return this.activeModule ? this.activeModule.name : '';
    }
  }),
  created: function created() {
    // const arr = this.userMenu.filter(item => item.name === this.active)[0].children
    // console.log(arr[1])
  },
  methods: {
    onSelect: function onSelect(routeName, indexPath) {
      var router = routeName;
      var isLowCode = routeName === 'LowCodeIndex';
      if (isLowCode) router = 'Design';
      this.$router.push({
        name: router
      });
    }
  }
};