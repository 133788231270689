import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import addressBookDialog from '@/public/yl-addressBook/Index.vue';
import { parseStyles } from "../utils/style";
import { transformFun } from "../utils/event";
export default {
  components: {
    addressBookDialog: addressBookDialog
  },
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    context: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      value: ''
    };
  },
  computed: {
    styles: function styles() {
      return parseStyles(this.data.field.styles, this.data.field.props);
    }
  },
  methods: {
    handleClick: function handleClick() {
      this.$refs['address-book'].init();
    },
    setValue: function setValue(value) {
      // doing something
    },
    onSelectAddress: function onSelectAddress(row) {
      var _this = this;
      this.value = "".concat(row.name, " ").concat(row.phone, " ").concat(row.prov, " ").concat(row.city, " ").concat(row.area, " ").concat(row.address);
      this.$emit('input', this.value);
      this.$refs['address-book'].hide();
      this.$nextTick(function () {
        _this.$refs['address-book'].hide();
        transformFun(_this.data.select, 'onSelect', row, _this.context, function (result) {
          result && _this.$emit('input', result);
        });
      });
    }
  }
};