var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-header-warp" }, [
    _c("div", { staticClass: "left-box" }, [
      _c("div", { staticClass: "header-logo-area" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("极兔国际VIP平台")) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "header-menu-area" },
        _vm._l(_vm.userMenu, function (item, i) {
          return _c(
            "div",
            {
              key: i,
              class: item.name === _vm.active ? "current item" : "item",
              on: {
                mouseenter: function ($event) {
                  return _vm.mouseover(item)
                },
                mouseleave: _vm.mouseout,
                click: function ($event) {
                  return _vm.handleClickMenu(item)
                },
              },
            },
            [
              _c("span", { staticClass: "menu-title" }, [
                _vm._v(_vm._s(_vm.$t(item.meta.title + "_TITLE"))),
              ]),
              _vm._v(" "),
              _vm.hoverTab === item.name
                ? _c("div", { staticClass: "header-dropdown_menu" }, [
                    _c(
                      "div",
                      { staticClass: "menu-item" },
                      _vm._l(item.children, function (it, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            class: ["items", _vm.lang === "EN" ? "itemEN" : ""],
                          },
                          [
                            _c("span", { staticClass: "ul-title" }, [
                              _vm._v(_vm._s(_vm.$t(it.meta.title + "_TITLE"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              { staticClass: "ul-zi" },
                              [
                                _vm._l(it.children, function (zi, ind) {
                                  return [
                                    !zi.hidden
                                      ? _c(
                                          "li",
                                          {
                                            key: ind,
                                            class: [
                                              _vm.activeIndex === ind &&
                                              index === _vm.oneIndex
                                                ? "activeTwo"
                                                : "",
                                            ],
                                            on: {
                                              click: function ($event) {
                                                return _vm.goRouter(
                                                  zi.name,
                                                  ind,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    zi.meta.title + "_TITLE"
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "right-box" },
      [_c("yl-user", { staticClass: "user-set" })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }