var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "resize-area",
      class: _vm.resizeClass,
      style: _vm.resizeStyle,
    },
    [
      !_vm.isLine
        ? _c("i", {
            staticClass: "iconfont icontuozhuaidaxiao",
            style: _vm.iconStyle,
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
              mousedown: _vm.handleResizeDown,
            },
          })
        : _c("div", {
            staticClass: "resize-bar",
            style: _vm.barStyle,
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
              mousedown: _vm.handleResizeDown,
            },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }