//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import setComponent from "./setComponent";
export default {
  components: {
    setComponent: setComponent
  },
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      drawerVisible: false
    };
  },
  computed: {
    iconClass: function iconClass() {
      return this.drawerVisible ? 'active' : '';
    },
    dragOptions: function dragOptions() {
      return {
        animation: 200,
        group: 'component',
        handle: '.move-bar'
      };
    }
  },
  methods: {
    handleToggleDrawer: function handleToggleDrawer() {
      this.drawerVisible = !this.drawerVisible;
    }
  }
};