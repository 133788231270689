/* import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
} */

// import Cookies from 'js-cookie'

var TokenKey = 'Admin-Token';
var crypto = require('crypto');
export function getToken() {
  return localStorage.getItem(TokenKey);
}
export function setToken(token) {
  localStorage.setItem(TokenKey, token);
}
export function removeToken() {
  return localStorage.removeItem(TokenKey);
}
var SidKey = 'VIP-Admin-Sid';
export function getSid() {
  return localStorage.getItem(SidKey);
}
export function setSid(sid) {
  localStorage.setItem(SidKey, sid);
}
export function setVCode(code) {
  sessionStorage.setItem('VCode', code);
}
export function getVCode() {
  return sessionStorage.getItem('VCode') || '';
}
export function removeVCode() {
  return sessionStorage.removeItem('VCode');
}
export function removeSid() {
  return localStorage.removeItem(SidKey);
}
export function removeUserKey() {
  return localStorage.removeItem('userKey');
}
export function setUserKey(key) {
  return localStorage.setItem('userKey', key);
}
export function setE3Flag() {
  return localStorage.setItem('isE3', '1');
}
export function clearE3Flag() {
  return localStorage.removeItem('isE3');
}
export function sign(opt) {
  if (opt) {
    var sid = getSid();
    opt.t = new Date().getTime();
    var param = opt.parameter ? JSON.stringify(opt.parameter) : '';
    var s = param + opt.t + (sid || '') + getVCode();
    opt.s = crypto.createHash('md5').update(s, 'utf-8').digest('hex');
    // console.log(s + ' 签名值： ' + opt.s)
  }

  return opt;
}