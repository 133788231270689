var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar" }, [
    _c(
      "div",
      { staticClass: "left-tools" },
      [
        _c("hamburger", {
          staticClass: "hamburger-container",
          attrs: { "is-active": _vm.sidebar.opened },
          on: { toggleClick: _vm.toggleSideBar },
        }),
        _vm._v(" "),
        _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "right-menu" },
      [_vm.device !== "mobile" ? [_c("user-set")] : _vm._e()],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }