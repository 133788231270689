import Vue from 'vue';
import Vuex from 'vuex';
import app from "./modules/app";
import errorLog from "./modules/errorLog";
import permission from "./modules/permission";
import tagsView from "./modules/tagsView";
import settings from "./modules/settings";
import user from "./modules/user";
import components from "./modules/components";
import validate from "./modules/validate";
import lowCode from "../views/setting/design/lowCode/store/modules/lowCode";
import form from "../views/setting/design/form/store/modules/form";
import getters from "./getters";
import mutations from "../views/setting/design/form/store/mutations";
import country from '@/store/modules/country';
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    app: app,
    errorLog: errorLog,
    permission: permission,
    tagsView: tagsView,
    settings: settings,
    user: user,
    lowCode: lowCode,
    form: form,
    validate: validate,
    components: components,
    country: country
  },
  getters: getters,
  mutations: mutations
});
export default store;