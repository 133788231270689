var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "swiper",
    {
      ref: "slide",
      staticClass: "slide-display-warp",
      attrs: { options: _vm.swiperOption },
    },
    [
      _vm._l(_vm.data.field.children, function (item) {
        return _c(
          "swiper-slide",
          { key: item.elementId },
          [
            _c("set-component", {
              key: item.elementId,
              attrs: { data: item, "element-id": item.elementId },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "swiper-pagination",
        attrs: { slot: "pagination" },
        slot: "pagination",
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "swiper-button-prev",
        attrs: { slot: "button-prev" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.handlePrev($event)
          },
        },
        slot: "button-prev",
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "swiper-button-next",
        attrs: { slot: "button-next" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.handleNext($event)
          },
        },
        slot: "button-next",
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }