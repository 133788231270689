import request from '@/ylCommon/utils/request';
export function login(options) {
  var data = {
    parameter: options,
    token: ''
  };
  return request({
    url: '/login.do',
    data: data,
    method: 'post'
  });
}
export function loginToE3(options) {
  var data = {
    parameter: options,
    token: ''
  };
  return request({
    url: '/e3/getToken.do',
    data: data,
    method: 'post'
  });
}
export function getInfo(token) {
  var data = {
    parameter: {},
    token: token
  };
  return request({
    url: '/getUserInfo.do',
    data: data,
    method: 'post'
  });
}
export function logout(token) {
  var data = {
    parameter: {},
    token: token
  };
  return request({
    url: '/logout.do',
    data: data
  });
}
export function getNotice() {
  return request({
    url: '/open/getNotices.do',
    data: {
      parameter: {}
    }
  });
}
export function qyOrderInfo(params) {
  return request({
    url: '/open/qyOrderInfo.do',
    data: {
      parameter: params
    }
  });
}
export function qyUserInfo(params) {
  return request({
    url: '/open/qyUserInfo.do',
    data: {
      parameter: params
    }
  });
}
export function loginUnique(params) {
  return request({
    url: '/loginUnique.do',
    data: params
  });
}