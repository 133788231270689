exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".tool-menu .freight-calculation{z-index:1002;cursor:pointer;color:#ffffff;font-size:12px;text-align:center;width:60px;height:60px;background:#e60113;border-radius:14px}.tool-menu .freight-calculation .icon-div .jisuanqi-img{margin-top:4px;width:32px;height:32px}.tool-menu .online-service{display:inline-block;width:60px;height:60px;margin-top:8px;color:#ffffff;background:#e60113;text-align:center;border-radius:14px;font-size:0;cursor:pointer}.tool-menu .online-service img{width:32px;height:32px;margin-top:4px}.tool-menu .online-service p{margin:0;font-size:12px}.tool-menu:hover .icon-hide,.tool-menu:hover .icon-show{opacity:1;cursor:pointer}.tool-menu .icon-show{color:#e60113;position:relative;top:0;right:-40px;font-size:40px;-webkit-transition:0.3s;transition:0.3s}.tool-menu .icon-hide{color:#e60113;position:relative;top:0;right:-40px;font-size:20px;opacity:0;-webkit-transition:0.3s;transition:0.3s}@media screen and (min-width: 1200px){.freight-calculation{right:14%}}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#E60000",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#fff",
	"menuHover": "#2d325f",
	"subMenuBg": "#222750",
	"subMenuHover": "#1b1e3e",
	"sideBarWidth": "240px"
};