var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-module-warp" },
    [
      _c(
        "el-select",
        {
          staticClass: "search-box",
          attrs: {
            "popper-class": "search-module-popper",
            placeholder: _vm.$t("搜索模块"),
            filterable: "",
            clearable: "",
          },
          on: { change: _vm.onChange },
          model: {
            value: _vm.searchKey,
            callback: function ($$v) {
              _vm.searchKey = $$v
            },
            expression: "searchKey",
          },
        },
        [
          _vm._l(_vm.menuOptions, function (item) {
            return _c("el-option", {
              key: item.value,
              attrs: { value: item.value, label: _vm.$t(item.label) },
            })
          }),
          _vm._v(" "),
          _c("i", {
            staticClass: "el-icon-search",
            attrs: { slot: "prefix" },
            slot: "prefix",
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }