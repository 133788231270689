var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-carousel",
    {
      attrs: {
        height: _vm.data.field.props.height,
        type: _vm.data.field.props.type,
        interval: _vm.data.field.props.interval,
        autoplay: _vm.data.field.props.autoplay,
        "indicator-position": _vm.data.field.props.indicatorPosition,
        loop: _vm.data.field.props.loop,
        direction: _vm.data.field.props.direction,
      },
    },
    _vm._l(_vm.data.field.options, function (item) {
      return _c(
        "el-carousel-item",
        { key: item.value },
        [
          _c("el-image", {
            attrs: { fit: "contain", src: item.value, alt: item.label },
          }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }