import _toConsumableArray from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.set";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.to-string";
import _typeof from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
/**
 * Created by jiachenpan on 16/11/18.
 */
import CryptoJS from 'crypto-js';
import moment from 'moment';
import _ from 'loadsh';
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

// 格式化时间
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf('?') + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {Sting} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xDC00 && code <= 0xDFFF) i--;
  }
  return s;
}
export function cleanArray(actual) {
  var newArray = [];
  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}
export function param(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return '';
    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
  })).join('&');
}
export function param2Obj(url) {
  var search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}
export function html2Text(val) {
  var div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}
export function objectMerge(target, source) {
  /* Merges two  objects,
     giving the last one precedence */

  if (_typeof(target) !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];
    if (_typeof(sourceProperty) === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}
export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  var classString = element.className;
  var nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}
export var pickerOptions = [{
  text: '今天',
  onClick: function onClick(picker) {
    var end = new Date();
    var start = new Date(new Date().toDateString());
    end.setTime(start.getTime());
    picker.$emit('pick', [start, end]);
  }
}, {
  text: '三天内',
  onClick: function onClick(picker) {
    var end = new Date(new Date().toDateString());
    var start = new Date();
    start.setTime(end.getTime() - 3600 * 1000 * 24 * 3);
    picker.$emit('pick', [start, end]);
  }
}, {
  text: '最近一周',
  onClick: function onClick(picker) {
    var end = new Date(new Date().toDateString());
    var start = new Date();
    start.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
    picker.$emit('pick', [start, end]);
  }
}, {
  text: '最近一个月',
  onClick: function onClick(picker) {
    var end = new Date(new Date().toDateString());
    var start = new Date(moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'));
    // start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
    console.log(start);
    picker.$emit('pick', [start, end]);
  }
}
// {
//   text: '最近三个月',
//   onClick(picker) {
//     const end = new Date(new Date().toDateString())
//     const start = new Date()
//     start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
//     picker.$emit('pick', [start, end])
//   }
// }
];

export var pickerOption = {
  disabledDate: function disabledDate(time) {
    return time.getTime() > Date.now();
  },
  shortcuts: [{
    text: '今天',
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date(new Date().toDateString());
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '三天内',
    onClick: function onClick(picker) {
      var end = new Date(new Date().toDateString());
      var start = new Date();
      start.setTime(end.getTime() - 3600 * 1000 * 24 * 2);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '本周内',
    onClick: function onClick(picker) {
      var weekOfday = parseInt(moment().format('d'));
      var start = moment().subtract(weekOfday, 'days').format('YYYY-MM-DD');
      var startData = new Date(start);
      var end = new Date(new Date().toDateString());
      picker.$emit('pick', [startData, end]);
    }
  }, {
    text: '本月内',
    onClick: function onClick(picker) {
      var end = new Date(new Date().toDateString());
      var start = new Date(moment().add('month', 0).format('YYYY-MM') + '-01');
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '三个月内',
    onClick: function onClick(picker) {
      var end = new Date(new Date().toDateString());
      var day = new Date().getDate();
      if (day > 10) {
        day = '-' + day;
      } else {
        day = '-0' + day;
      }
      var start = new Date(moment().add('month', -3).format('YYYY-MM') + day);
      picker.$emit('pick', [start, end]);
    }
  }]
};
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}
export function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;
  var later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}
export function throttle(func, wait) {
  var timeout;
  return function () {
    var _this = this;
    var args = arguments;
    if (!timeout) {
      timeout = setTimeout(function () {
        timeout = null;
        func.apply(_this, args);
      }, wait);
    }
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 */
export function deepClone(source) {
  if (!source && _typeof(source) !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && _typeof(source[keys]) === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}
export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}
export function createUniqueString() {
  var timestamp = +new Date() + '';
  var randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}
export function formatMoney(s) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  if (/[^0-9\.]/.test(s)) {
    return '0';
  }
  if (s === 0 || s === '0') {
    return '0';
  }
  if ((s === null || s === '') && s !== 0) {
    return '';
  }
  s = s.toString().replace(/^(\d*)$/, '$1.');
  s = (s + '00').replace(/(\d*\.\d\d)\d*/, '$1');
  s = s.replace('.', ',');
  var re = /(\d)(\d{3},)/;
  while (re.test(s)) {
    s = s.replace(re, '$1,$2');
  }
  s = s.replace(/,(\d\d)$/, '.$1');
  if (type === 0) {
    // 不带小数位(默认是有小数位)
    if (s.charAt(0) === '0') {
      s = s.slice(1);
    }
    var a = s.split('.');
    if (a[1] === '00') {
      s = a[0];
    }
  }
  return s;
}
// 三位小数
export function formatNumThree(inputvalue) {
  var targetValue;
  var newValue = inputvalue.match(/^\d*(\.?\d{0,3})/g) ? inputvalue.match(/^\d*(\.?\d{0,3})/g)[0] : '';
  if (inputvalue !== '') {
    targetValue = newValue.indexOf('.') === 0 ? '' : newValue;
  } else {
    targetValue = '';
  }
  return targetValue;
}
// 两位小数
export function formatNum(inputvalue) {
  var targetValue;
  var newValue = inputvalue.match(/^\d*(\.?\d{0,2})/g) ? inputvalue.match(/^\d*(\.?\d{0,2})/g)[0] : '';
  if (inputvalue !== '') {
    targetValue = newValue.indexOf('.') === 0 ? '' : newValue;
  } else {
    targetValue = '';
  }
  return targetValue;
}
// 整数
export function formatGoodsNum(inputvalue) {
  // let targetValue
  var newValue = inputvalue.match(/^[1-9]\d*/g) ? inputvalue.match(/^[1-9]\d*/g)[0] : '';
  return newValue;
}
// 数字+字母
export function formatRpostcode(inputvalue) {
  var newValue = inputvalue.match(/^[A-Za-z0-9]+$/) ? inputvalue.match(/^[A-Za-z0-9]+$/)[0] : '';
  return newValue;
}
// 数字+字母+空格
export function formatRpostcodeSpace(inputvalue) {
  var newValue = inputvalue.match(/^[A-Za-z0-9\s]+$/) ? inputvalue.match(/^[A-Za-z0-9\s]+$/)[0] : '';
  return newValue;
}
// 数字+字母+空格
export function formatRpostcodeWordSpace(inputvalue) {
  var newValue = inputvalue.match(/^[A-Za-z\s]+$/) ? inputvalue.match(/^[A-Za-z\s]+$/)[0] : '';
  return newValue;
}
// 电话
export function formatPhoneNum(inputvalue) {
  // let targetValue
  var newValue = inputvalue.match(/^[0-9]\d*/g) ? inputvalue.match(/^[0-9]\d*/g)[0] : '';
  return newValue;
}
// 安达顺 英文数字符号包括+-/\()空格
export function formatPhoneSymbolSpace(inputvalue) {
  var newValue = inputvalue.match(/^[A-Za-z0-9\+\-\/\\\(\)\s]+$/) ? inputvalue.match(/^[A-Za-z0-9\+\-\/\\\(\)\s]+$/)[0] : '';
  return newValue;
}
// 商品资料 英文数字符号包括@/#/%/&/*/( )/|/-/_/+/./空格 包含/
export function formatGoodsSymbolSpace(inputvalue) {
  var newValue = inputvalue.match(/^[A-Za-z0-9\@\#\%\&\*\(\)\|\+\-\.\/\_\s\u4e00-\u9fa5]+$/) ? inputvalue.match(/^[A-Za-z0-9\@\#\%\&\*\(\)\|\+\-\.\/\_\s\u4e00-\u9fa5]+$/)[0] : '';
  return newValue;
}
// 德国特殊路线 不限制特殊字符及空格
export function formatPhoneNumSymbolSpace(inputvalue) {
  var newValue = inputvalue.match(/^[0-9\+\-\/\\\(\)\,\.\*\;\:\|\s]+$/) ? inputvalue.match(/^[0-9\+\-\/\\\(\)\,\.\*\;\:\|\s]+$/)[0] : '';
  return newValue;
}
// 美国路线 不限制特殊字符及空格
export function formatPhoneNumSymbolUS(inputvalue) {
  var newValue = inputvalue.match(/^[0-9\+\-\/\\\(\)\,\.\*\;\:\|\，\。\、\；\‘\’\“\”\：\{\}\【\】\——\=\~\!\@\#\$\%\^\&\s]+$/) ? inputvalue.match(/^[0-9\+\-\/\\\(\)\,\.\*\;\:\|\，\。\、\；\‘\’\“\”\：\{\}\【\】\——\=\~\!\@\#\$\%\^\&\s]+$/)[0] : '';
  return newValue;
}
// 美国路线 不限制特殊字符及空格
export function formatPhoneNumSymbolAE(inputvalue) {
  var newValue = inputvalue.match(/^[0-9()-/\s]+$/) ? inputvalue.match(/^[0-9()-/\s]+$/)[0] : '';
  return newValue;
}
// 法国 英文数字符号包括+
export function formatPhoneSymbol(inputvalue) {
  var newValue = inputvalue.match(/^[A-Za-z0-9\+\s]+$/) ? inputvalue.match(/^[A-Za-z0-9\+\s]+$/)[0] : '';
  return newValue;
}
// DES加密 Pkcs7填充方式
export function encryptByDES(message, key) {
  var keyHex = CryptoJS.enc.Utf8.parse(key);
  var encrypted = CryptoJS.DES.encrypt(message, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}
// DES解密
export function decryptByDES(ciphertext, key) {
  var keyHex = CryptoJS.enc.Utf8.parse(key);
  // direct decrypt ciphertext
  var decrypted = CryptoJS.DES.decrypt({
    ciphertext: CryptoJS.enc.Base64.parse(ciphertext)
  }, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

// 生成唯一的id
export function generateId() {
  return Number(Math.random().toString().substr(3, length) + Date.now()).toString(36);
}
export function updateFormItemId(updateData) {
  var isLayout = function isLayout(type) {
    return ['grid', 'buttonDrawer'].includes(type);
  };
  var data = _.cloneDeep(updateData);
  data.elementId = generateId();
  var set = function set(children) {
    children.map(function (item, index) {
      item.elementId = "".concat(item.elementId).concat(index);
    });
  };
  if (isLayout(data.type)) {
    data.field.children.map(function (item, index) {
      item.elementId = "".concat(item.elementId).concat(index);
      item.parentId = "".concat(data.elementId);
      if (item.children && item.children.length > 0) {
        set(item.children);
      }
    });
  }
  return data;
}
export function removeAllNonItem() {
  var $list = document.querySelectorAll('.middle-form-content .none-item');
  $list.forEach(function (item) {
    item.remove();
  });
}

/*
 *@Description: 数组对象去重
*/
export var removeRepeat = function removeRepeat(arr) {
  if (_typeof(arr[0]) === 'object') {
    for (var i = 0; i < arr.length; i++) {
      arr[i] = JSON.stringify(arr[i]);
    }
    arr = _toConsumableArray(new Set(arr));
    for (var _i = 0; _i < arr.length; _i++) {
      arr[_i] = JSON.parse(arr[_i]);
    }
  } else {
    arr = _toConsumableArray(new Set(arr));
  }
  return arr;
};
/*
 *@Description: 复制文本到剪切板
*/
export var copyText = function copyText(txt) {
  if (typeof txt !== 'string' || !txt.length) {
    return;
  }
  var txtEl = document.createElement('input');
  txtEl.setAttribute('type', 'text');
  txtEl.setAttribute('value', txt);
  txtEl.style.position = 'fixed';
  txtEl.style.left = '-999999px';
  txtEl.style.top = '-9999px';
  document.body.appendChild(txtEl);
  txtEl.select();
  document.execCommand('copy');
  document.body.removeChild(txtEl);
  txtEl = null;
};
export var cCountryMap = []; // 国家
cCountryMap['IDN'] = '印尼';
cCountryMap['VNM'] = '越南';
cCountryMap['MYS'] = '马来西亚';
cCountryMap['PHL'] = '菲律宾';
cCountryMap['THA'] = '泰国';
cCountryMap['KHM'] = '柬埔寨';
cCountryMap['SGP'] = '新加坡';
cCountryMap['US'] = '美国';
cCountryMap['IN'] = '印度';
cCountryMap['UK'] = '英国';
cCountryMap['CHN'] = '中国';
cCountryMap['NL'] = '荷兰';
export var orderMap = []; // 订单类型
orderMap[1] = '普通订单';
orderMap[2] = '转运订单';
orderMap[3] = '退货订单';
orderMap[4] = '派送订单';
orderMap[5] = '拆包订单';
orderMap[6] = '头程订单';
orderMap[7] = '出库订单';
orderMap[8] = '备货订单';
orderMap[9] = '销毁订单';
orderMap[10] = '仓储订单';
orderMap[11] = '仅出库单';
orderMap[12] = '平台转运单';
orderMap[13] = '欧美出库订单';
orderMap[14] = '退仓出库订单';
orderMap[15] = '海外仓直发订单';
orderMap[16] = '自提订单';
orderMap[17] = '次品订单';
export var outTypeMap = []; // 出库类型
outTypeMap['C1'] = '备货出库';
outTypeMap['C4'] = '仓储出库';
outTypeMap['C5'] = '仅出库';
outTypeMap['XHCK'] = '销毁出库';
outTypeMap['14'] = '退仓出库';
outTypeMap['15'] = '自提订单';
export var orderStatusMap = []; // 揽收订单状态
orderStatusMap[0] = '待推送';
orderStatusMap[1] = '下单成功';
orderStatusMap[2] = '下单失败';
orderStatusMap[3] = '已调派业务员';
orderStatusMap[4] = '已揽收';
orderStatusMap[5] = '已取件';
orderStatusMap[6] = '已取消';
export var trackStatusMap = []; // 揽收轨迹状态
trackStatusMap[1] = '待取件';
trackStatusMap[2] = '已取件';
trackStatusMap[3] = '派送中';
trackStatusMap[4] = '已签收';
trackStatusMap[5] = '快递柜签收';

/*
 *@Description: 数组对象去重,数组的成员是对象, prop是比较的字段
*/
export var removeRepeatWZ = function removeRepeatWZ(arr, prop) {
  for (var i = 0; i < arr.length; i++) {
    for (var j = i + 1; j < arr.length; j++) {
      if (arr[i][prop] == arr[j][prop]) {
        arr.splice(j, 1);
        j--;
      }
    }
  }
  return arr;
};