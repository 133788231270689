import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";
import _ from 'loadsh';
import { getTransformFun } from "../utils/event";
import Vue from 'vue';
var hasChild = function hasChild(data) {
  return data.field ? data.field.children.length > 0 : data.children.length > 0;
};
export default {
  ADD_FORM_ITEM: function ADD_FORM_ITEM(state, _ref) {
    var field = _ref.field,
      index = _ref.index;
    state.form.form.splice(index, 0, field);
  },
  INIT_FORM: function INIT_FORM(state, _ref2) {
    var items = _ref2.items,
      options = _ref2.options;
    state.form.form = items;
    state.form.options = options;
  },
  SET_CURRENT_FORM_ITEM: function SET_CURRENT_FORM_ITEM(state, data) {
    state.form.current = data;
  },
  SET_FORM_ITEM_OPTION: function SET_FORM_ITEM_OPTION(state, update) {
    var current = state.form.current;
    var _update$id = update.id,
      id = _update$id === void 0 ? current.elementId : _update$id,
      data = update.data;
    var set = function set(children) {
      children.map(function (item) {
        if (item.elementId === id) {
          item.field.options = data;
        } else if (hasChild(item)) {
          set(item.field ? item.field.children : item.children);
        }
      });
    };
    state.form.form.map(function (item) {
      if (item.elementId === id) {
        item.field.options = data;
      } else if (item.field.children.length > 0) {
        set(item.field.children);
      }
    });
  },
  ADD_FORM_ITEM_OPTION: function ADD_FORM_ITEM_OPTION(state, data) {
    var current = state.form.current;
    var add = function add(children) {
      children.map(function (item) {
        if (item.elementId === current.elementId) {
          item.field.options.push(data);
        } else if (hasChild(item)) {
          add(item.field ? item.field.children : item.children);
        }
      });
    };
    state.form.form.map(function (item) {
      if (item.elementId === current.elementId) {
        item.field.options.push(data);
      } else if (item.field.children.length > 0) {
        add(item.field.children);
      }
    });
  },
  DELETE_FORM_ITEM_OPTION: function DELETE_FORM_ITEM_OPTION(state, data) {
    var current = state.form.current;
    var remove = function remove(children) {
      children.map(function (item) {
        if (item.elementId === current.elementId) {
          item.field.options.map(function (tag, tagIndex) {
            if (tag.label === data.label) {
              item.field.options.splice(tagIndex, 1);
            }
          });
        } else if (hasChild(item)) {
          remove(item.field ? item.field.children : item.children);
        }
      });
    };
    state.form.form.map(function (item) {
      if (item.elementId === current.elementId) {
        item.field.options.map(function (tag, tagIndex) {
          if (tag.label === data.label) {
            item.field.options.splice(tagIndex, 1);
          }
        });
      } else if (item.field.children.length > 0) {
        remove(item.field.children);
      }
    });
  },
  ADD_FORM_ITEM_TO_GRID: function ADD_FORM_ITEM_TO_GRID(state, _ref3) {
    var parentId = _ref3.parentId,
      gridId = _ref3.gridId,
      field = _ref3.field,
      index = _ref3.index;
    var set = function set(children) {
      children.map(function (item) {
        if (item.elementId === parentId) {
          item.field.children.map(function (child) {
            if (child.elementId === gridId) {
              child.children.splice(index, 0, field);
            }
          });
        } else if (hasChild(item)) {
          set(item.field ? item.field.children : item.children);
        }
      });
    };
    state.form.form.map(function (item) {
      if (item.elementId === parentId) {
        item.field.children.map(function (tag) {
          if (tag.elementId === gridId) {
            tag.children.splice(index, 0, field);
          } else if (hasChild(item)) {
            set(item.field ? item.field.children : item.children);
          }
        });
      } else if (hasChild(item)) {
        set(item.field.children);
      }
    });
  },
  ADD_FORM_ITEM_TO_FORM: function ADD_FORM_ITEM_TO_FORM(state, _ref4) {
    var parentId = _ref4.parentId,
      field = _ref4.field,
      index = _ref4.index;
    var addField = function addField(list) {
      list.map(function (item) {
        if (item.elementId === parentId) {
          item.field.children.splice(index, 0, field);
        } else if (hasChild(item)) {
          addField(item.field ? item.field.children : item.children);
        }
      });
    };
    state.form.form.map(function (item) {
      if (item.elementId === parentId) {
        item.field.children.splice(index, 0, field);
      } else if (hasChild(item)) {
        addField(item.field ? item.field.children : item.children);
      }
    });
  },
  DELETE_FORM_ITEM: function DELETE_FORM_ITEM(state, elementId) {
    var remove = function remove(list) {
      list.map(function (item, index) {
        if (item.elementId === elementId) list.splice(index, 1);else if (hasChild(item)) {
          remove(item.field ? item.field.children : item.children);
        }
      });
    };
    state.form.form.map(function (item, index) {
      if (item.elementId === elementId) state.form.form.splice(index, 1);else if (hasChild(item)) {
        remove(item.field.children);
      }
    });
  },
  ADD_FORM_ITEM_TO_CHILDREN: function ADD_FORM_ITEM_TO_CHILDREN(state, formItem) {
    var newFormItem = _.cloneDeep(formItem);
    var parentId = formItem.parentId;
    var add = function add(children) {
      children.map(function (item) {
        if (item.elementId === parentId) {
          item.field.children.push(newFormItem);
        } else if (hasChild(item)) {
          add(item.field ? item.field.children : item.children);
        }
      });
    };
    state.form.form.map(function (item) {
      if (item.elementId === parentId) {
        item.field.children.push(newFormItem);
      } else if (hasChild(item)) {
        add(item.field ? item.field.children : item.children);
      }
    });
  },
  DELETE_FORM_ITEM_TO_CHILDREN: function DELETE_FORM_ITEM_TO_CHILDREN(state, _ref5) {
    var parentId = _ref5.parentId,
      elementId = _ref5.elementId;
    var deleteFormItem = function deleteFormItem(children) {
      children.map(function (item) {
        if (item.elementId === parentId) {
          item.field.children.map(function (child, childIndex) {
            if (child.elementId === elementId) item.field.children.splice(childIndex, 1);
          });
        } else if (hasChild(item)) {
          deleteFormItem(item.field ? item.field.children : item.children);
        }
      });
    };
    deleteFormItem(state.form.form);
  },
  CLEAR_FORM: function CLEAR_FORM(state) {
    state.form.form = [];
    state.form.current = {};
  },
  SET_FORM_ITEM_RULE: function SET_FORM_ITEM_RULE(state, id) {
    var rule = _.cloneDeep(state.form.rules.find(function (item) {
      return item.attrName === id;
    })) || {
      attrName: '',
      desc: '',
      maxLimit: '',
      maxLimitMsg: '',
      minLimit: '',
      minLimitMsg: '',
      regular: '',
      regularMsg: '',
      required: '',
      requiredMsg: '',
      status: '',
      type: ''
    };
    var current = state.form.current;
    var setRule = function setRule(children) {
      children.map(function (item) {
        var hasChildren = item.children && item.children.length > 0;
        var isRight = item.elementId === current.elementId;
        if (isRight) {
          item.field.rule = rule;
        } else if (hasChildren) setRule(item.children);
      });
    };
    state.form.form.map(function (item) {
      var isRight = item.elementId === current.elementId;
      var hasChildren = item.field.children && item.field.children.length > 0;
      if (isRight) {
        item.field.rule = rule;
      } else if (hasChildren) {
        setRule(item.field.children);
      }
    });
  },
  COPY_FORM_ITEM: function COPY_FORM_ITEM(state, _ref6) {
    var elementId = _ref6.elementId,
      field = _ref6.field;
    var currentIndex;
    var copy = function copy(children) {
      children.map(function (item, index) {
        var hasChildren = item.children && item.children.length > 0;
        var isRight = item.elementId === elementId;
        if (isRight) {
          currentIndex = index + 1;
          children.splice(currentIndex, 0, field);
        } else if (hasChildren) copy(item.children);
      });
    };
    state.form.form.map(function (item, index) {
      var isRight = item.elementId === elementId;
      if (isRight) {
        currentIndex = index + 1;
        state.form.form.splice(currentIndex, 0, field);
      } else if (hasChild(item)) {
        copy(item.field ? item.field.children : item.children);
      }
    });
  },
  ADD_DRAWER_FORM_ITEM: function ADD_DRAWER_FORM_ITEM(state, _ref7) {
    var parentId = _ref7.parentId,
      field = _ref7.field,
      index = _ref7.index;
    var set = function set(list) {
      list.map(function (item) {
        var isRight = item.elementId === parentId;
        if (isRight) {
          item.field.children.splice(index, 0, field);
        } else if (hasChild(item)) {
          set(item.field ? item.field.children : item.children);
        }
      });
    };
    state.form.form.map(function (item) {
      var isRight = item.elementId === parentId;
      if (isRight) {
        item.field.children.splice(index, 0, field);
      } else if (hasChild(item)) {
        set(item.field ? item.field.children : item.children);
      }
    });
  },
  ADD_EVENT_TO_FORM_ITEM: function ADD_EVENT_TO_FORM_ITEM(state, _ref8) {
    var current = _ref8.current,
      eventName = _ref8.eventName,
      prefix = _ref8.prefix,
      trigger = _ref8.trigger,
      funcBody = _ref8.funcBody;
    var id = current.elementId;
    var eventText = "function ".concat(eventName, "() {}");
    var event = {
      eventName: eventName,
      event: funcBody || eventText,
      prefix: prefix,
      trigger: trigger
    };
    var set = function set(list) {
      list.map(function (item) {
        if (id === item.elementId) {
          var hasEvent = item.field.events.some(function (e) {
            return e.trigger === event.trigger;
          });
          if (!hasEvent) item.field.events.push(event);
        } else if (hasChild(item)) {
          set(item.field ? item.field.children : item.children);
        }
      });
    };
    state.form.form.map(function (item) {
      var isRight = id === item.elementId;
      if (isRight) {
        var hasEvent = item.field.events.some(function (e) {
          return e.trigger === event.trigger;
        });
        if (!hasEvent) {
          item.field.events.push(event);
        } else {
          item.field.events.map(function (tag, index) {
            if (tag.trigger === event.trigger) {
              item.field.events.splice(index, 1, event);
            }
          });
        }
      } else if (hasChild(item)) {
        set(item.field ? item.field.children : item.children);
      }
    });
  },
  SET_PAGE_QUICK_LOCAL: function SET_PAGE_QUICK_LOCAL(state, local) {
    var x = local.keyArr[0];
    var y = local.keyArr[1];
    var xValue = local.valueArr[0];
    var yValue = local.valueArr[1];
    state.form.page.styles.top = '';
    state.form.page.styles.left = '';
    state.form.page.styles.bottom = '';
    state.form.page.styles.right = '';
    state.form.page.styles[x] = xValue;
    state.form.page.styles[y] = yValue;
  },
  SET_PAGE_STYLES: function SET_PAGE_STYLES(state, _ref9) {
    var key = _ref9.key,
      value = _ref9.value;
    if (!state.form.page.styles.hasOwnProperty(key)) {
      Vue.set(state.form.page.styles, key, value);
    } else {
      state.form.page.styles[key] = value;
    }
  },
  SET_PAGE_PROPS: function SET_PAGE_PROPS(state, _ref10) {
    var key = _ref10.key,
      value = _ref10.value;
    if (!state.form.page.props.hasOwnProperty(key)) {
      Vue.set(state.form.page.props, key, value);
    } else {
      state.form.page.props[key] = value;
    }
  },
  SET_FORM_ITEM_STYLES: function SET_FORM_ITEM_STYLES(state, _ref11) {
    var id = _ref11.id,
      object = _ref11.object;
    var set = function set(list) {
      list.map(function (item) {
        if (id === item.elementId) {
          for (var key in object) {
            if (object.hasOwnProperty(key)) {
              var nonKey = !item.field.styles.hasOwnProperty(key);
              if (nonKey) Vue.set(item.field.styles, key, object[key]);else {
                item.field.styles[key] = object[key];
              }
            }
          }
        } else if (hasChild(item)) {
          set(item.field ? item.field.children : item.children);
        }
      });
    };
    state.form.form.map(function (item) {
      var isRight = id === item.elementId;
      if (isRight) {
        for (var key in object) {
          if (object.hasOwnProperty(key)) {
            var nonKey = !item.field.styles.hasOwnProperty(key);
            if (nonKey) Vue.set(item.field.styles, key, object[key]);else {
              item.field.styles[key] = object[key];
            }
          }
        }
      } else if (hasChild(item)) {
        set(item.field ? item.field.children : item.children);
      }
    });
  },
  SET_FORM_ITEM_PROPS: function SET_FORM_ITEM_PROPS(state, _ref12) {
    var id = _ref12.id,
      object = _ref12.object;
    var setProps = function setProps(list) {
      list.map(function (item) {
        if (id === item.elementId) {
          for (var key in object) {
            if (object.hasOwnProperty(key)) {
              var nonKey = !item.field.props.hasOwnProperty(key);
              if (nonKey) Vue.set(item.field.props, key, object[key]);else {
                item.field.props[key] = object[key];
              }
            }
          }
        } else if (hasChild(item)) {
          setProps(item.field ? item.field.children : item.children);
        }
      });
    };
    state.form.form.map(function (item) {
      var isRight = id === item.elementId;
      if (isRight) {
        for (var key in object) {
          if (object.hasOwnProperty(key)) {
            var nonKey = !item.field.props.hasOwnProperty(key);
            if (nonKey) Vue.set(item.field.props, key, object[key]);else {
              item.field.props[key] = object[key];
            }
          }
        }
      } else if (hasChild(item)) {
        setProps(item.field ? item.field.children : item.children);
      }
    });
  },
  SET_GLOBAL_METHODS: function SET_GLOBAL_METHODS(state, methods) {
    var func = {};
    methods.map(function (item) {
      func[item.funcName] = getTransformFun(item.funcBody, item.funcName);
    });
    state.form.page.methods = func;
    state.form.methodsList = methods;
  },
  ADD_SOURCE_ITEM: function ADD_SOURCE_ITEM(state, source) {
    state.form.sourceList.push(source);
  },
  ADD_FETCH_ITEM: function ADD_FETCH_ITEM(state, fetch) {
    state.form.fetchList.push(fetch);
  },
  DELETE_FETCH_ITEM: function DELETE_FETCH_ITEM(state, fetch) {
    state.form.fetchList.map(function (item, index) {
      if (item.name === fetch.name) state.form.fetchList.splice(index, 1);
    });
  },
  DELETE_SOURCE_ITEM: function DELETE_SOURCE_ITEM(state, source) {
    state.form.sourceList.map(function (item, index) {
      if (item.name === source.name) state.form.sourceList.splice(index, 1);
    });
  }
};