exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "#app{overflow:auto hidden}#app::-webkit-scrollbar-track-piece{background:#efefef}#app::-webkit-scrollbar{width:8px;height:8px}#app::-webkit-scrollbar-thumb{background:#999;border-radius:5px}.window-href{cursor:pointer}#YSF-BTN-HOLDER{bottom:66px !important}.el-button:hover{color:#e60000 !important}.el-button:focus{color:#e60000 !important;border:1px solid #eeeeee !important}.el-button--text{color:#e60000 !important}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#E60000",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#fff",
	"menuHover": "#2d325f",
	"subMenuBg": "#222750",
	"subMenuHover": "#1b1e3e",
	"sideBarWidth": "240px"
};