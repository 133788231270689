var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.data.field.visible,
          expression: "data.field.visible",
        },
      ],
      class: _vm.data.elementId,
      attrs: {
        label: _vm.$t(_vm.data.field.name),
        prop: _vm.data.field.paramsKey,
      },
    },
    [
      _vm.loading
        ? _c("yl-skeleton", { attrs: { rows: 1, height: "32px", active: "" } })
        : _c("yl-choose", {
            ref: "choose",
            attrs: {
              options: _vm.options,
              plain: _vm.fieldProps.plain,
              "more-visible": _vm.fieldProps.moreVisible,
              "more-slice-number": _vm.fieldProps.moreSliceNumber,
            },
            on: { change: _vm.onChange },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }