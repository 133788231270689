import "core-js/modules/es6.array.find";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { parseStyles } from "../../../utils/style";
import draggable from 'vuedraggable';
import { formatFormItem, removeAllNonItem } from "../../../utils/global";
import _ from 'loadsh';
import { mapGetters, mapMutations } from 'vuex';
export default {
  components: {
    draggable: draggable
  },
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      current: ''
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['collection'])), {}, {
    styles: function styles() {
      return parseStyles(this.data.field.styles, this.data.field.props);
    },
    logoAreaClass: function logoAreaClass() {
      return this.data.field.children.length === 0 ? 'no-field' : '';
    },
    dragOptions: function dragOptions() {
      return {
        animation: 0,
        group: 'component',
        handle: '.move-bar'
      };
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapMutations({
    addFormItemToForm: 'ADD_FORM_ITEM_TO_FORM'
  })), {}, {
    handleClick: function handleClick(item) {
      this.current = item;
    },
    onAdd: function onAdd(data) {
      removeAllNonItem();
      var isSort = data.item.className.includes('container-drag-warp');
      if (isSort) return;
      var id = data.item.getAttribute('field-id');
      var parentId = data.to.getAttribute('data-parent');
      var field = formatFormItem(_.cloneDeep(this.collection.find(function (item) {
        return item.id === id;
      })));
      field.parentId = parentId;
      field.parentType = 'container';
      var obj = {
        parentId: parentId,
        field: field,
        index: data.newIndex
      };
      console.log(obj);
      this.addFormItemToForm(obj);
    }
  })
};