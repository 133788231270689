var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.parentType !== "form"
    ? _c(
        "div",
        { staticClass: "native-field-warp", class: _vm.fieldProps.className },
        [
          _c("div", { staticClass: "field-label" }, [
            _vm._v(_vm._s(_vm.data.field.name)),
          ]),
          _vm._v(" "),
          _c("yl-choose", {
            attrs: {
              options: _vm.data.field.options,
              plain: _vm.fieldProps.plain,
              "more-visible": _vm.fieldProps.moreVisible,
              "more-slice-number": _vm.fieldProps.moreSliceNumber,
            },
          }),
        ],
        1
      )
    : _c(
        "el-form-item",
        { style: _vm.styles, attrs: { label: _vm.data.field.name } },
        [
          _c("yl-choose", {
            attrs: {
              options: _vm.data.field.options,
              plain: _vm.fieldProps.plain,
              "more-visible": _vm.fieldProps.moreVisible,
              "more-slice-number": _vm.fieldProps.moreSliceNumber,
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }