import "core-js/modules/es6.array.find";
import _objectSpread from "/home/jenkins/workspace/uat-front-jti-crsw-yl-vip-customer-front/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
export default {
  props: {
    componentObject: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread(_objectSpread({
    resizeStyle: function resizeStyle() {
      var style = {};
      var isYLine = this.isYLine,
        currentComponent = this.currentComponent;
      if (isYLine) {
        style.width = "".concat(currentComponent.props.height, "px");
      }
      return style;
    }
  }, mapGetters(['activeComponent', 'storeList'])), {}, {
    currentComponent: function currentComponent() {
      var _this$activeComponent = this.activeComponent.id,
        id = _this$activeComponent === void 0 ? '' : _this$activeComponent;
      return this.storeList.find(function (item) {
        return item.id === id;
      });
    },
    cursorType: function cursorType() {
      var type = {
        'XLineUi': 'col-resize',
        'YLineUi': 'row-resize'
      };
      return type[this.componentObject.type];
    },
    isLine: function isLine() {
      return this.componentObject.type === 'XLineUi' || this.componentObject.type === 'YLineUi';
    },
    isYLine: function isYLine() {
      return this.componentObject.type === 'YLineUi';
    },
    iconStyle: function iconStyle() {
      var isLine = this.isLine;
      return {
        cursor: isLine ? this.cursorType : 'se-resize'
      };
    },
    barStyle: function barStyle() {
      var isLine = this.isLine,
        isYLine = this.isYLine,
        currentComponent = this.currentComponent;
      var style = {
        cursor: isLine ? this.cursorType : 'se-resize',
        width: this.resizeDisabledX ? '100%' : '16px',
        height: this.resizeDisabledY ? '100%' : '16px'
      };
      if (this.resizeDisabledX) {
        style.bottom = 0;
        style.left = 0;
      }
      if (this.resizeDisabledY) {
        style.top = 0;
        style.right = 0;
      }
      if (isYLine) {
        style.width = "".concat(currentComponent.props.width, "px");
      }
      return style;
    },
    resizeDisabledY: function resizeDisabledY() {
      return this.componentObject.type === 'XLineUi';
    },
    resizeDisabledX: function resizeDisabledX() {
      return this.componentObject.type === 'YLineUi';
    },
    resizeClass: function resizeClass() {
      return this.isLine ? '' : 'resize-btn';
    }
  }),
  methods: {
    handleResizeDown: function handleResizeDown(e) {
      this.$emit('resize-down', e);
    }
  }
};