var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.parentType !== "form"
    ? _c(
        "div",
        { staticClass: "native-field-warp", style: _vm.styles },
        [
          _c("div", { staticClass: "field-label" }, [
            _vm._v(_vm._s(_vm.$t(_vm.data.field.name))),
          ]),
          _vm._v(" "),
          _c("el-date-picker", {
            class: _vm.data.field.props.className,
            attrs: {
              placeholder: _vm.data.field.props.placeholder,
              "picker-options": _vm.pickerOptions,
              readonly: _vm.data.field.props.readonly,
              editable: _vm.data.field.props.editable,
              clearable: _vm.data.field.props.clearable,
              "value-format": "yyyy-MM-dd",
            },
            on: { change: _vm.onChange, blur: _vm.onBlur, focus: _vm.onFocus },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      )
    : _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.data.field.visible,
              expression: "data.field.visible",
            },
          ],
          class: _vm.data.elementId,
          style: _vm.styles,
          attrs: {
            label: _vm.$t(_vm.data.field.name),
            prop: _vm.data.field.paramsKey,
          },
        },
        [
          _c("el-date-picker", {
            class: _vm.data.field.props.className,
            attrs: {
              placeholder: _vm.data.field.props.placeholder,
              "picker-options": _vm.pickerOptions,
              readonly: _vm.data.field.props.readonly,
              editable: _vm.data.field.props.editable,
              clearable: _vm.data.field.props.clearable,
              "value-format": "yyyy-MM-dd",
            },
            on: { change: _vm.onChange, blur: _vm.onBlur, focus: _vm.onFocus },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }