/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-12 11:02:59
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-07-12 11:10:21
 * @FilePath: /yl-vip-customer-front/src/ylCommon/directive/adaptive.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { addResizeListener, removeResizeListener } from 'element-ui/src/utils/resize-event';
import debounce from 'lodash/debounce';

/**
 * How to use
 * <el-table height="100px" v-height-adaptive="{bottomOffset: 30}">...</el-table>
 * el-table height is must be set
 * bottomOffset: 30(default)   // The height of the table from the bottom of the page.
 */

var doResize = function doResize(el, binding, vnode) {
  var $table = vnode.componentInstance;
  var value = binding.value;
  if (!$table.height) {
    throw new Error("el-$table must set the height. Such as height='100px'");
  }
  var bottomOffset = value && value.bottomOffset || 30;
  if (!$table) return;
  console.log('$table---------', $table);
  var height = window.innerHeight - el.getBoundingClientRect().top - bottomOffset;
  $table.setHeight(height);
  $table.doLayout();
};
var adaptive = {
  bind: function bind(el, binding, vnode) {
    el.resizeListener = debounce(function () {
      doResize(el, binding, vnode);
    }, 100);
    // parameter 1 is must be "Element" type
    addResizeListener(window.document.body, el.resizeListener);
  },
  inserted: function inserted(el, binding, vnode) {
    doResize(el, binding, vnode);
  },
  unbind: function unbind(el) {
    removeResizeListener(window.document.body, el.resizeListener);
  }
};
export default {
  install: function install(Vue) {
    Vue.directive('height-adaptive', adaptive);
  } // (Vue) => {
  // }
};