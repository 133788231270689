var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "drag",
      staticClass: "drag-warp",
      class: _vm.activeClass,
      style: _vm.dragStyle,
      attrs: { id: _vm.aimId },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.handleSetCurrent($event)
        },
        mousedown: _vm.handleMouseDown,
        contextmenu: _vm.handleContextMenu,
      },
    },
    [
      _c(
        _vm.componentObject.type,
        _vm._b(
          {
            tag: "component",
            attrs: {
              "element-id": _vm.componentObject.id,
              "update-id": _vm.componentObject.updateId,
            },
            on: { complete: _vm.init },
          },
          "component",
          _vm.componentObject.props,
          false
        )
      ),
      _vm._v(" "),
      _vm.resizeVisible
        ? _c("drag-resize", {
            attrs: { "component-object": _vm.componentObject },
            on: { "resize-down": _vm.handleResizeDown },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }